import { AxiosError } from "axios";
import { Formik, FormikHelpers, FormikProps } from "formik";
import { useState } from "react";
import { Button, Form, Modal, Spinner, Alert } from "react-bootstrap";
import { toast, ToastOptions, TypeOptions } from "react-toastify";
import axiosInstance from "../../../../helpers/axios/axios.config";
import { IActivateAccountRequest } from "../../../../interface/activateAccount/activateAccount.interface";
import { ActivateAccountFormValidation } from "../../../../validation/activateForm/activateForm.validation";

export interface IActivateAccountModalProps {
  showAccountModal: boolean;
  handleAccountModalClose: () => void;
}

interface IActivateAccountForm {
  name: string;
  email: string;
}

export function ActivateAccountModal(props: IActivateAccountModalProps) {
  const [formError, setFormError] = useState<string | undefined>(undefined);
  const handleSubmitForm = async (
    values: IActivateAccountForm,
    formikHelpers: FormikHelpers<IActivateAccountForm>
  ) => {
    try {
      setFormError(undefined);
      await axiosInstance.post("/activate_accounts", {
        activate_account: {
          name: values.name,
          email: values.email,
        },
      } as IActivateAccountRequest);
      toast("Account activation request submitted successfully.", {
        type: "success" as TypeOptions,
      } as ToastOptions);
      formikHelpers.resetForm();
      formikHelpers.setFieldError("email", '')
      props.handleAccountModalClose();
    } catch (error) {
      // if (
      //   error instanceof AxiosError &&
      //   ((error as AxiosError)?.response?.data as any)?.email
      // ) 
      // {
      //   const emailError = ((error as AxiosError)?.response?.data as any)?.email;
      //   setFormError(" It seems like the email address you provided is invalid. If you continue to experience issues, feel free to reach out to our support team for assistance. We're here to help you get started with activating your account!");
      // } 
      // else {
      //   toast("Something went wrong, Please try again later.", {
      //     type: "error" as TypeOptions,
      //   } as ToastOptions);
      // }
      if(
          error instanceof AxiosError &&  error.response?.status === 404
        )
        {
          setFormError(((error as AxiosError)?.response?.data as any)?.error[0]);
        }
        else if(
          ((error as AxiosError)?.response?.data as any)?.email
        ){
          setFormError(((error as AxiosError)?.response?.data as any)?.email);
        }
        else{
          toast("Something went wrong, Please try again later.", {
            type: "error" as TypeOptions,
          } as ToastOptions);
        }
    }
  };

  const initialValues: IActivateAccountForm = {
    name: "",
    email: "",
  };

  return (
    <Modal
      className="common-modal"
      centered
      show={props.showAccountModal}
      onHide={props.handleAccountModalClose}
    >
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmitForm}
        validationSchema={ActivateAccountFormValidation}
        validateOnChange={false}
        validateOnBlur={false}
      >
        {({
          values,
          errors,
          touched,
          isSubmitting,
          handleSubmit,
          handleChange,
          handleBlur,
        }: FormikProps<IActivateAccountForm>) => {
          return (
            <>
              <Modal.Header closeButton>
                <Modal.Title>Activate account</Modal.Title>
              </Modal.Header>
              <Modal.Body>
              {formError && (
                  <Alert variant="danger" className="mb-4">
                    {formError}
                  </Alert>
                )}
                <Form>
                  <Form.Group
                    className="mb-4 position-relative"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                      type="text"
                      name="name"
                      placeholder="John Doe"
                      value={values.name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className={`form-icon-control ${
                        errors.name ? "error" : ""
                      }`}
                    />
                    <span className="icon-profile field-icon"></span>
                    {errors.name && (
                      <Form.Control.Feedback type="invalid" className="d-block">
                        {errors.name}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>

                  <Form.Group
                    className="mb-4 position-relative"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      type="email"
                      name="email"
                      placeholder="John@business.pass"
                      value={values.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className={`form-icon-control ${
                        errors.email ? "error" : ""
                      }`}
                    />
                    <span className="icon-main field-icon"></span>

                    {errors.email && (
                      <Form.Control.Feedback type="invalid" className="d-block">
                        {errors.email}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>
                </Form>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant="primary"
                  className="w-100"
                  onClick={() => handleSubmit()}
                  disabled={isSubmitting}
                >
                  {isSubmitting ? (
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  ) : (
                    "Activate Account"
                  )}
                </Button>
                {/* {formError && (
                  <Form.Group>
                    <Form.Control.Feedback type="invalid" className="d-block">
                      {formError}
                    </Form.Control.Feedback>
                  </Form.Group>
                )} */}
              </Modal.Footer>
            </>
          );
        }}
      </Formik>
    </Modal>
  );
}
