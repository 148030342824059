import { Axios, AxiosError, AxiosResponse } from "axios";
import { clearLocalStorage, getUserToken } from "../../localStorage.helper";
import { IApiErrorSchema } from "../interface/axios.interface";

const unAuthorisedUrls: string[] = [
  "/users/login",
  "/users/password",
  "/verify_user",
  "/send_invitations",
  "/countries",
  "/team_ranges",
];

const onRequest = (config: any): any => {
  const url: string = config.url || "";
  let isUrlNeedsAuthentication: boolean = true;
  unAuthorisedUrls.forEach((unauthUrls) => {
    if (url.includes(unauthUrls)) {
      isUrlNeedsAuthentication = false;
    }
  });
  if (isUrlNeedsAuthentication)
    config = {
      ...config,
      headers: {
        Authorization: getUserToken(),
      },
    };
  console.info(`[config] after [${JSON.stringify(config)}]`);
  return config;
};

const onRequestError = (error: AxiosError): Promise<AxiosError> => {
  console.error(`[request error] [${JSON.stringify(error)}]`);
  return Promise.reject(error);
};

const onResponse = (response: AxiosResponse): AxiosResponse => {
  console.info(`[response] [${JSON.stringify(response)}]`);
  return response;
};

const onResponseError = (error: AxiosError): Promise<IApiErrorSchema> => {
  if (error.response && error.response.status === 200) {
    clearLocalStorage();
    window.location.replace("/");
  }
  return Promise.reject(error);
};

export const parseApiError = (error: any): IApiErrorSchema => {
  const { config, response } = error;
  const defaultError: IApiErrorSchema = {
    config,
    response: null,
    message: null,
  };
  if (response) {
    defaultError.response = response;
  } else {
    defaultError.response = { data: { message: "Something went wrong!!!" } };
  }
  return defaultError;
};

// Intercept https request here
export const defaultRequestInterceptor = ({
  axiosInstance,
}: {
  axiosInstance: Axios;
}) => axiosInstance.interceptors.request.use(onRequest, onRequestError);

// Intercept https response here
export const defaultResponseInterceptor = ({
  axiosInstance,
}: {
  axiosInstance: Axios;
}) => axiosInstance.interceptors.response.use(onResponse, onResponseError);
