import React from "react";

// components
import Header from "../header";
import Footer from "../footer";
import { NotFoundPage } from "../../pages/notFound";

const ErrorBoundary: React.FunctionComponent = () => {
  return (
    <>
      <Header />
      <NotFoundPage />
      <Footer />
    </>
  );
};

export default ErrorBoundary;
