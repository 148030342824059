import * as Yup from "yup";

export const UpdatePasswordFormValidation = Yup.object().shape({
  old_password: Yup.string().required(),
  password: Yup.string().required(),
  confirm_password: Yup.string().required().oneOf(
    [Yup.ref("password"), null as any],
    "New and confirm passwords must match"
  ),
});
