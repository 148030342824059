import { create } from "zustand";
import { devtools } from "zustand/middleware";
import { setLocalStorage } from "../../helpers/localStorage.helper";

export const useUserLoginStore = create<ILoginStore>()(
  devtools(
    (set) => ({
      token: "",
      userData: {},
      saveTokenAndUser: async (param: any) => {
        setLocalStorage("userData", JSON.stringify(param.userData));
        set({
          token: param.token,
          userData: param.userData,
        });
      },
    }),
    {
      name: "user_login_flow_store",
      anonymousActionType: "Login_flow_store",
    }
  )
);

export interface ILoginStore {
  token: string;
  userData: {
    name?: string;
    email?: string;
    phone_no?: string;
    id?: number;
  };
  saveTokenAndUser: (param: any) => void;
}
