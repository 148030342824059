import { Formik, FormikHelpers, FormikProps } from "formik";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Button, Col, Form, Row, Spinner } from "react-bootstrap";
import { toast, ToastOptions, TypeOptions } from "react-toastify";
import { MyProfileFormValidation } from "../../../../../validation/profile/my-profile.validation";
import { useAuthStore } from "../../../../../zustand-store/auth/auth.store";
import { useConfigStore } from "../../../../../zustand-store/config/config.store";
import { PhoneInput } from "react-international-phone";
import { ICountryDetail } from "../../../../../interface/config/config.interface";
import { IPhoneNumber } from "../../../../../interface/my-profile/my-profile.interface";
import { PhoneNumberUtil } from "google-libphonenumber";
import "react-international-phone/style.css";
import axios, { AxiosError, AxiosResponse } from "axios";
import axiosInstance from "../../../../../helpers/axios/axios.config";

interface IMyProfileFormParams {
  firstName: string;
  lastName: string;
  email: string;
  companyName: string;
  phone: string;
  position: string;
  address: string;
  photo_url: File | null;
}

interface IPersonalInformationProps {}

const PersonalInformation: React.FunctionComponent<
  IPersonalInformationProps
> = () => {
  const { countryList, fetchCountryList } = useConfigStore();
  const {
    loggedInUserDetails,
    updateProfile,
    updateUserProfilePhoto,
    isProfileUpdating,
    profileUpdateError,
  } = useAuthStore();
  const inputFile = useRef<HTMLInputElement>(null);
  const [preview, setPreview] = useState<string | ArrayBuffer | null>(null);
  const [phoneNumber, setPhoneNumber] = useState<IPhoneNumber | null>(null);
  const phoneUtil = PhoneNumberUtil.getInstance();

  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [photoUrl, setPhotoUrl] = useState<string | null>(null);

  const isPhoneValid = useCallback(
    (phone: string, dialCode: string) => {
      try {
        return phoneUtil.isValidNumber(
          phoneUtil.parseAndKeepRawInput(phone, dialCode)
        );
      } catch (error) {
        return false;
      }
    },
    [phoneUtil]
  );

  const isValid = useMemo(() => {
    if (phoneNumber?.phone && phoneNumber?.dialCode) {
      const validatePhoneNumber = isPhoneValid(
        phoneNumber?.phone,
        phoneNumber?.dialCode
      );

      return validatePhoneNumber;
    }
  }, [isPhoneValid, phoneNumber?.dialCode, phoneNumber?.phone]);

  useEffect(() => {
    if (!countryList.length) {
      fetchCountryList();
    }
  }, [fetchCountryList, countryList]);

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const target = e.target as HTMLInputElement & {
      files: FileList;
    };

    const file = new FileReader();
    file.onload = function () {
      setSelectedFile(e.target.files ? e.target.files[0] : null);
      setPreview(file.result);
    };

    file.readAsDataURL(target.files[0]);
  };

  const handleUpload = async () => {
    if (!selectedFile) {
      return;
    }

    try {
      const { data: response } = await axiosInstance.get<
        AxiosResponse<{ url: string }>
      >("users/generate_presigned_url", {
        params: { user: { photo: selectedFile.name } },
      });

      const { url: presignedUrl } = response;
      if (presignedUrl) {
        await axios.put(presignedUrl, selectedFile, {
          headers: { "Content-Type": selectedFile.type },
        });

        const { id } = loggedInUserDetails || {};
        if (id) {
          await axiosInstance.patch(`/users/${id}`, {
            user: { photo: presignedUrl.split("?")[0] },
          });
        }

        setPhotoUrl(presignedUrl.split("?")[0]);

        toast("Profile photo updated successfully", {
          type: "success" as TypeOptions,
        } as ToastOptions);
      }
    } catch (error) {
      toast("Something went wrong, Please try again later.", {
        type: "error" as TypeOptions,
      } as ToastOptions);
    }
  };

  const handleUpdateProfileInfo = (
    values: IMyProfileFormParams,
    formikHelpers: FormikHelpers<IMyProfileFormParams>
  ) => {
    const selectedCountryDetails: ICountryDetail | undefined = countryList.find(
      (country: ICountryDetail) => country.phone_code === phoneNumber?.dialCode
    );

    let contactNumber: string = "";
    if (phoneNumber?.phone) {
      const number = phoneUtil.parseAndKeepRawInput(
        phoneNumber?.phone,
        phoneNumber?.dialCode
      );
      if (number.getNationalNumber()) {
        contactNumber = `+${phoneNumber?.dialCode}${
          number.getNationalNumber()?.toString() ?? ""
        }`;
      }
    }

    if (!isValid) {
      formikHelpers.setFieldError("phone", "Please enter valid phone number");
      return;
    }

    updateProfile(
      {
        user: {
          name: values.firstName + " " + values.lastName,
          country_id: selectedCountryDetails?.id as any,
          phone: contactNumber,
          position: values.position,
          address: values.address,
          company_name: values.companyName,
        },
      },
      toast("Profile updated sucessfully.", {
        type: "success" as TypeOptions,
      } as ToastOptions)
    );
  };

  useEffect(() => {
    if (profileUpdateError) {
      toast("Something went wrong, Please try again later.", {
        type: "error" as TypeOptions,
      } as ToastOptions);
    }
  }, [profileUpdateError]);

  const onUploadUserAvatarClick = () => {
    if (inputFile.current) {
      inputFile.current.click();
    }
  };

  return (
    <div className="form-common-box rounded-0">
      <h2 className="myprofile-title text-dark">
        <span className="icon-user-profile pe-2"></span>
        Personal Information
      </h2>
      <div className="upload-image">
        <div className="user-image-wrapper">
          <img
            src={
              loggedInUserDetails?.photo_url
                ? preview
                  ? preview
                  : loggedInUserDetails.photo_url
                : require("../../../../../images/upload-img.png")
            }
            alt="Profile pic"
            className="user-avatar"
          />
        </div>
        <div className="upload-profile-info">
          <h4>{loggedInUserDetails?.name}</h4>
          <p className="common-para mb-3">
            {loggedInUserDetails?.company_name}
          </p>
          <div className="d-flex gap-3">
            <Button
              variant="outline-primary"
              onClick={onUploadUserAvatarClick}
              className="action-buttons"
            >
              <input
                ref={inputFile}
                type="file"
                id="image"
                name="image"
                accept="image/*"
                onChange={handleImageChange}
                className="select-user-avatar-input"
              />
              <span className="icon-trash fs-5 pe-1"></span> Change
            </Button>
            <Button
              variant="outline-primary"
              onClick={handleUpload}
              className="action-buttons"
            >
              <span className="icon-user-profile fs-5 pe-1"></span> Update
            </Button>
          </div>
        </div>
      </div>
      <Formik
        initialValues={
          {
            firstName: loggedInUserDetails?.name?.split(" ")[0],
            lastName: loggedInUserDetails?.name?.split(" ")[1],
            email: loggedInUserDetails?.email,
            companyName: loggedInUserDetails?.company_name,
            address: loggedInUserDetails?.address,
            phone: loggedInUserDetails?.phone,
            position: loggedInUserDetails?.position,
          } as IMyProfileFormParams
        }
        onSubmit={handleUpdateProfileInfo}
        validationSchema={MyProfileFormValidation}
        validateOnBlur={false}
        validateOnChange={false}
        validateOnMount={false}
      >
        {({
          values,
          errors,
          handleSubmit,
          handleChange,
          handleBlur,
          setFieldValue,
        }: FormikProps<IMyProfileFormParams>) => (
          <Form>
            <Row>
              <Col md={12} lg={6}>
                <Form.Group
                  className="mb-4 position-relative"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>First Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="firstName"
                    key={1}
                    className={`form-icon-control ${
                      errors.firstName ? "error" : ""
                    }`}
                    value={values.firstName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder="Enter your first name"
                  />
                  <span className="icon-profile  field-icon"></span>
                  <Form.Control.Feedback
                    type="invalid"
                    className="d-block"
                  ></Form.Control.Feedback>
                </Form.Group>
                <Form.Group
                  className="mb-4 position-relative"
                  controlId="exampleForm.ControlInput2"
                >
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="text"
                    name="email"
                    key={3}
                    className={`form-icon-control ${
                      errors.email ? "error" : ""
                    }`}
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder="Enter your email"
                  />
                  <span className="icon-main  field-icon"></span>
                  <Form.Control.Feedback
                    type="invalid"
                    className="d-block"
                  ></Form.Control.Feedback>
                </Form.Group>

                <Form.Group
                  className="mb-4 position-relative"
                  controlId="exampleForm.ControlInput3"
                >
                  <Form.Label>Company Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="companyName"
                    key={5}
                    className="form-icon-control"
                    value={values.companyName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder="Enter your company name"
                  />
                  <span className="icon-briefcase  field-icon"></span>
                  <Form.Control.Feedback
                    type="invalid"
                    className="d-block"
                  ></Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md={12} lg={6}>
                <Form.Group
                  className="mb-4 position-relative"
                  controlId="exampleForm.ControlInput4"
                >
                  <Form.Label>Last Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="lastName"
                    key={2}
                    className={`form-icon-control ${
                      errors.lastName ? "error" : ""
                    }`}
                    value={values.lastName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder="Enter your last name"
                  />
                  <span className="icon-profile  field-icon"></span>
                  <Form.Control.Feedback
                    type="invalid"
                    className="d-block"
                  ></Form.Control.Feedback>
                </Form.Group>
                <Form.Group
                  className="mb-4 position-relative"
                  controlId="exampleForm.ControlInput5"
                >
                  <Form.Label>Phone</Form.Label>
                  <PhoneInput
                    value={values.phone}
                    onChange={(phone, { country }) =>
                      setPhoneNumber({
                        phone: phone,
                        dialCode: country.dialCode,
                        name: country.name,
                        format: country.format as string,
                        iso2: country.iso2,
                      })
                    }
                    /* Note: for edit the phone number add dialCode + phoneNumber */

                    // value="+91 8160840949"
                    inputProps={{
                      placeholder: "Enter phone number",
                      className: `${
                        errors.phone ? "form-control error" : "form-control "
                      }`,
                    }}
                  />
                  {errors.phone && (
                    <Form.Control.Feedback type="invalid" className="d-block">
                      {errors.phone}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
                <Form.Group
                  className="mb-4 position-relative"
                  controlId="exampleForm.ControlInput6"
                >
                  <Form.Label>Designation</Form.Label>
                  <Form.Control
                    type="text"
                    name="position"
                    className="form-icon-control"
                    value={values.position}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder="Enter your designation"
                  />
                  <span className="icon-user-octagon  field-icon"></span>
                  <Form.Control.Feedback
                    type="invalid"
                    className="d-block"
                  ></Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md={12} lg={12}>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlTextarea1"
                >
                  <Form.Label>Address</Form.Label>
                  <Form.Control
                    as="textarea"
                    placeholder="Enter your workspace address"
                    name="address"
                    className={`h-auto ${errors.address ? "error" : ""}`}
                    value={values.address}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    rows={5}
                  />

                  <Form.Control.Feedback
                    type="invalid"
                    className="d-block"
                  ></Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mt-2 float-end">
                  <Button
                    variant="primary"
                    onClick={() => handleSubmit()}
                    disabled={isProfileUpdating}
                  >
                    {isProfileUpdating ? (
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                    ) : (
                      "Update"
                    )}
                  </Button>
                </Form.Group>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default PersonalInformation;
