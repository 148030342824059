import * as React from "react";
import { useExploreMembershipStore } from "../../../../../zustand-store/explore-memberships/exploreMembership.store";
import { Table } from "react-bootstrap";
import { toast, ToastOptions, TypeOptions } from "react-toastify";
import { AuthenticatedRoutesEnums } from "../../../../../constants/routes.constants";
import { useLocation } from "react-router-dom";

interface IExploreMembershipsListProps {}

const ExploreMembershipsList: React.FunctionComponent<
  IExploreMembershipsListProps
> = (props) => {
  const {
    exploreMembershipList,
    isFetchingExploreMembership,
    noExploreMembership,
    fetchExploreMembershipError,
    fetchExploreMembershipList,
  } = useExploreMembershipStore();
  const location = useLocation();

  // React.useEffect(() => {
  //   fetchExploreMembershipList();
  // }, [fetchExploreMembershipList]);
  // React.useEffect(() => {
  //   if (fetchExploreMembershipError)
  //     toast("Failed to fetch list of explore memberships list.", {
  //       type: "error" as TypeOptions,
  //     } as ToastOptions);
  // }, [fetchExploreMembershipError]);

  React.useEffect(() => {
    if (
      location.pathname === AuthenticatedRoutesEnums.DASHBOARD_EXPLORE_MEMBERSHIPS
    ) {
      fetchExploreMembershipList();
    }
  }, [location.pathname]);
  return (
    <>
      <Table hover responsive className="dashboard-table">
        <thead>
          <tr>
            <th>Name</th>
            <th>Email</th>
            <th>Type</th>
          </tr>
        </thead>
        <tbody>
          {isFetchingExploreMembership && (
            <tr>
              <td>Loading...</td>
            </tr>
          )}
          {noExploreMembership && (
            <tr>
              <td>No Explore membership lists</td>
            </tr>
          )}
          {exploreMembershipList?.map((user) => (
            <tr key={`${user.id}-${user.email}`}>
              <td>{user.name}</td>
              <td>{user.email}</td>
              <td>{user.explore_type}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
};

export default ExploreMembershipsList;
