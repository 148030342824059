import { useState } from "react";
import { Container, Form } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import Tab from "react-bootstrap/Tab";
import DashboardHomeicon from "../../../../images/icons/home.svg";
import DashboardPeopleicon from "../../../../images/icons/people.svg";
import Searchicon from "../../../../images/icons/search.svg";
import DashboardUserProfileicon from "../../../../images/icons/user-profile.svg";
import "../../../../styles/dashboard.scss";
import UsersList from "./user-list/index.jsx";
import ExploreMembershipsList from "./explore-memberships";
import ActivateAccountsList from "./activate-accounts";
import WorkspacesList from "./workspaces/index.jsx";
import Communities from "./communities/index.jsx";
import { Link } from 'react-router-dom';
import { AuthenticatedRoutesEnums } from "../../../../constants/routes.constants";


function HomeDahsboard() {
  const [searchSlug, setSearchSlug] = useState("");
  return (
    <Container className="vertical-tabs" fluid>
      <Tab.Container id="left-tabs-example" defaultActiveKey="users">
        <Row className="h-100">
          <Col md={3}>
            <Nav variant="pills" className="flex-column">
              <Nav.Item>
                <Nav.Link eventKey="dashboard">
                  <img
                    src={DashboardHomeicon}
                    alt="icon"
                    className="sidebar-icon"
                  />
                  Dashboard
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="users">
                  <img
                    src={DashboardPeopleicon}
                    alt="icon"
                    className="sidebar-icon"
                  />
                  Users
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="activate-accounts"
                as={Link}
                to={AuthenticatedRoutesEnums.DASHBOARD_ACTIVATE_ACCOUNT}
                >
                  <img
                    src={DashboardPeopleicon}
                    alt="icon"
                    className="sidebar-icon"
                  />
                  Activate accounts
                </Nav.Link>
              </Nav.Item>
              {/* <Nav.Item>
                <Nav.Link eventKey="third">
                  <img
                    src={DashboardBulidingicon}
                    alt="icon"
                    className="sidebar-icon"
                  />
                  Co-work spaces
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="fourth">
                  <img
                    src={DashboardGlobalicon}
                    alt="icon"
                    className="sidebar-icon"
                  />{" "}
                  Website management
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="fifth">
                  <img
                    src={DashboardGraphicon}
                    alt="icon"
                    className="sidebar-icon"
                  />{" "}
                  Financial data{" "}
                </Nav.Link>
              </Nav.Item> */}
              <Nav.Item>
                <Nav.Link eventKey="explore-membership"
                as={Link}
                to={AuthenticatedRoutesEnums.DASHBOARD_EXPLORE_MEMBERSHIPS}
                 >
                  <img
                    src={DashboardUserProfileicon}
                    alt="icon"
                    className="sidebar-icon"
                  />{" "}
                  Explore membership
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="for-workspaces"
                 as={Link}
                 to={AuthenticatedRoutesEnums.DASHBOARD_WORKSPACES}
                 >
                  <img
                    src={DashboardUserProfileicon}
                    alt="icon"
                    className="sidebar-icon"
                  />{" "}
                  For Workspaces
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="for-communities"
                    as={Link}
                    to={AuthenticatedRoutesEnums.DASHBOARD_COMMUNITIES}
                    >
                  <img
                    src={DashboardUserProfileicon}
                    alt="icon"
                    className="sidebar-icon"
                  />{" "}
                  For Communities
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </Col>
          <Col md={9}>
            <Tab.Content>
              <Tab.Pane eventKey="users">
                <UsersList />
              </Tab.Pane>
              <Tab.Pane eventKey="activate-accounts">
                <ActivateAccountsList />
              </Tab.Pane>
              <Tab.Pane eventKey="explore-membership">
                <ExploreMembershipsList />
              </Tab.Pane>
              <Tab.Pane eventKey="for-workspaces">
                <WorkspacesList />
              </Tab.Pane>
              <Tab.Pane eventKey="for-communities">
                <Communities />
              </Tab.Pane>
            </Tab.Content>
          </Col>
        </Row>
      </Tab.Container>
    </Container>
  );
}

export default HomeDahsboard;
