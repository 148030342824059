import { Button, Table } from "react-bootstrap";
import axiosInstance from "../../../../../helpers/axios/axios.config";
import DashboardEyeicon from "../../../../../images/icons/eye.svg";
import DashboardSendicon from "../../../../../images/icons/send.svg";
import DashboardTrashicon from "../../../../../images/icons/trash.svg";
import { useState, useEffect, useCallback } from "react";
import { useLocation } from "react-router-dom";
import { AuthenticatedRoutesEnums } from "../../../../../constants/routes.constants";

export default function WorkspacesList() {
  const location = useLocation();
  const [workspaces, setWorkspaces] = useState(undefined);

  const fetchWorkspaces = useCallback(async () => {
    try {
      const response = await axiosInstance.get("/workspace_inquiries");
      if (response) {
        setWorkspaces(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    if (location.pathname === AuthenticatedRoutesEnums.DASHBOARD_WORKSPACES) {
      fetchWorkspaces();
    }
  }, [fetchWorkspaces, location.pathname]);

  return (
    <>
      <div className="dashboard-search-box">
        <h3 className="form-common-title">Workspaces</h3>
      </div>

      <Table hover responsive className="dashboard-table">
        <thead>
          <tr>
            <th>Name</th>
            <th>Position</th>
            <th>Email</th>
            <th>Phone </th>
            <th>Address </th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {!workspaces && (
            <tr>
              <td>Loading...</td>
            </tr>
          )}
          {workspaces &&
            workspaces?.map((Workspaces) => {
              return (
                <tr key={`${Workspaces.id}-${Workspaces.email}`}>
                  <td>{Workspaces.name}</td>
                  <td>{Workspaces.Position || "NA"}</td>
                  <td>{Workspaces.email}</td>
                  <td>{Workspaces.phone}</td>
                  <td>{Workspaces.address}</td>
                  <td>
                    <div className="btn-actions">
                      {!["activated", "accepted"].includes(
                        Workspaces.status
                      ) && (
                        <Button
                          variant="white"
                          // onClick={() => {
                          //   handleSendInviteButton(user.id);
                          // }}
                        >
                          <img src={DashboardSendicon} alt="icon" />
                        </Button>
                      )}
                      <Button
                        variant="white"
                        className="me-2 ms-2"
                        // onClick={() => {
                        //   handleWorkspacesInfoModalShow(workspaces.id);
                        // }}
                      >
                        <img src={DashboardEyeicon} alt="icon" />
                      </Button>
                      <Button
                        variant="white"
                        // onClick={() => {
                        //   handleDeleteUserModalShow(user.id);
                        // }}
                      >
                        <img src={DashboardTrashicon} alt="icon" />
                      </Button>
                    </div>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </Table>
      {/* {showDetailsForWorkspacesId !== undefined && (
        <UserInformation
          showUserInfoModal={showDetailsForWorkspacesId !== undefined}
          handleWorkspacesInfoModalClose={handleWorkspacesInfoModalClose}
          WorkspacesId={showDetailsForWorkspacesId}
        />
      )}
      {showDeleteForWorkspacesId !== undefined && (
        <DeleteUser
          showDeleteUserModal={showDeleteForWorkspacesId}
          handleDeleteUserModalClose={handleDeleteUserModalClose}
          WorkspacesId={showDeleteForWorkspacesId}
        />
      )} */}
    </>
  );
}
