import { Container, Dropdown, Nav, Navbar, NavDropdown } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  AuthenticatedRoutesEnums,
  UnAuthenticatedRoutesEnums,
} from "../../constants/routes.constants";
import "../../styles/header.scss";
import { useAuthStore } from "../../zustand-store/auth/auth.store";
import { UserType } from "../../constants/user_type.constant";

function Header() {
  const { loggedInUserDetails, doLogout } = useAuthStore();
  const location = useLocation();
  const navigate = useNavigate();
  return (
    <header className="header">
      <Navbar expand="lg">
        <Container>
          <Navbar.Brand href={UnAuthenticatedRoutesEnums.HOME}>
            <img src={require("../../images/logo.png")} alt="logo" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto">
              {loggedInUserDetails === undefined ? (
                <>
                  <Nav.Link
                    as={Link}
                    to={UnAuthenticatedRoutesEnums.ABOUT_US}
                    className={
                      location.pathname === UnAuthenticatedRoutesEnums.ABOUT_US
                        ? "active"
                        : ""
                    }
                  >
                    About
                  </Nav.Link>
                  <Nav.Link
                    as={Link}
                    to={UnAuthenticatedRoutesEnums.BUSINESS}
                    className={
                      location.pathname === UnAuthenticatedRoutesEnums.BUSINESS
                        ? "active"
                        : ""
                    }
                  >
                    For Workspaces
                  </Nav.Link>
                  <Nav.Link
                    as={Link}
                    to={UnAuthenticatedRoutesEnums.WORKSPACE}
                    className={
                      location.pathname ===
                        UnAuthenticatedRoutesEnums.WORKSPACE ||
                      location.pathname ===
                        UnAuthenticatedRoutesEnums.CUSTOMER ||
                      location.pathname === UnAuthenticatedRoutesEnums.EMPLOYEE
                        ? "active"
                        : ""
                    }
                  >
                    For Communities
                  </Nav.Link>
                </>
              ) : (
                <NavDropdown
                  id="nav-dropdown-dark-example"
                  className="user-dropdown"
                  title={
                    <Navbar.Text className="text-dark">
                      {loggedInUserDetails?.photo_url ? (
                        <img
                          src={loggedInUserDetails?.photo_url as string}
                          alt="Profile pic"
                          className="me-2 profile_icon"
                        />
                      ) : (
                        <img
                          src={require("../../images/user-profile.png")}
                          className="me-2"
                          alt="user-profile"
                        />
                      )}

                      {loggedInUserDetails.name}
                    </Navbar.Text>
                  }
                >
                  <NavDropdown.Item>
                    {loggedInUserDetails?.photo_url ? (
                      <img
                        src={loggedInUserDetails?.photo_url as string}
                        alt="Profile pic"
                        className="me-2 profile_icon"
                      />
                    ) : (
                      <img
                        src={require("../../images/user-profile.png")}
                        className="me-2"
                        alt="user-profile"
                      />
                    )}
                    <span className="text-dark fw-bold">
                      {loggedInUserDetails.name}
                    </span>
                  </NavDropdown.Item>
                  <Dropdown.Divider />
                  <NavDropdown.Item
                    as={Link}
                    to={AuthenticatedRoutesEnums.PROFILE + "?tab=profile"}
                  >
                    Personal Information
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    as={Link}
                    to={AuthenticatedRoutesEnums.BUSINESS_PROFILE}
                  >
                    Space Information
                  </NavDropdown.Item>
                  {loggedInUserDetails.user_type === UserType.SUPER_ADMIN && (
                    <NavDropdown.Item
                      as={Link}
                      to={AuthenticatedRoutesEnums.DASHBOARD}
                    >
                      Dashboard{" "}
                    </NavDropdown.Item>
                  )}

                  {/* <NavDropdown.Item as={Link} to="#action/3.4">
                    Change Location
                  </NavDropdown.Item> */}
                  <NavDropdown.Item
                    as={Link}
                    to={AuthenticatedRoutesEnums.PROFILE + "?tab=password"}
                  >
                    Change Password
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    as={Link}
                    to={AuthenticatedRoutesEnums.PROFILE + "?tab=delete"}
                  >
                    Delete Account
                  </NavDropdown.Item>
                  <Dropdown.Divider />
                  <NavDropdown.Item
                    className="text-dark"
                    onClick={() => {
                      doLogout(navigate(UnAuthenticatedRoutesEnums.HOME));
                    }}
                  >
                    Sign out{" "}
                    <img
                      src={require("../../images/icons/signout.png")}
                      alt="icon"
                    />
                  </NavDropdown.Item>
                </NavDropdown>
              )}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>
  );
}

export default Header;
