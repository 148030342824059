import * as Yup from "yup";

export const ExploreMembershipFormValidation = Yup.object().shape({
  name: Yup.string().required("Please enter name"),
  email: Yup.string()
    .email("Please enter valid email")
    .required("Please enter email"),
  explore_type: Yup.string().required().oneOf(["Individual", "Group", "Other"]),
  explore_other: Yup.string(),
});
