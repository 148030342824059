import React, { useEffect, useState } from "react";
import { Button, Col, Container, Row, Spinner, Card } from "react-bootstrap";
import { BsArrowLeft } from "react-icons/bs";
import { useNavigate, useParams } from "react-router-dom";
import { IWorkspaceDetails } from "../../interface/workspace/workspace.interface";
import "../../styles/header.scss";
import "../../styles/workspaceDetails.scss";
import { useWorkspaceStore } from "../../zustand-store/workspace/workspace.store";
import MapComponent from "../MapContainer";
import ShowWorkSpace from "../authenticated-pages/profile/business-profile/tabs/show-workspace";
import { handleImageError } from "../../helpers";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";

const WorkSpaceDetailsPage = () => {
  const {
    workspaces,
    isFetchingWorkspaces,
    noWorkspacesFound,
    fetchWorkspaceList,
  } = useWorkspaceStore();

  const navigate = useNavigate();

  useEffect(() => {
    if (!workspaces.length) fetchWorkspaceList();
  }, [fetchWorkspaceList, workspaces.length]);

  const { workspaceId } = useParams();
  const [selectedWorkspace, setWorkspace] = useState<
    IWorkspaceDetails | undefined
  >(undefined);

  const { photo_urls, latitude, longitude } = selectedWorkspace ?? {};

  useEffect(() => {
    if (workspaces && workspaceId) {
      const workspaceDetails = workspaces.find(
        (workspace) => workspace.id.toString() === workspaceId
      );
      setWorkspace(workspaceDetails);
    }
  }, [workspaceId, workspaces]);

  return (
    <section className="workspace-wrapper">
      <Container>
        <div className="mt-3 d-flex justify-content-md-start">
          <Button
            variant="white"
            className="custom-button text-dark"
            onClick={() => navigate(-1)}
          >
            <BsArrowLeft className="me-2" />
            Back to Searching
          </Button>
        </div>
        {noWorkspacesFound ? (
          <>No workspace found.</>
        ) : isFetchingWorkspaces ? (
          <Spinner />
        ) : !selectedWorkspace ? (
          <>
            <p>
            There is some issue with this workspace. Please check after some time or reload the page.
            </p>
          </>
        ) : (
          <>
            <div className="mt-3 text-dark position-relative">
              <h2>
                <b>{selectedWorkspace?.name}</b>
              </h2>
              <h6 className="text-secondary">{selectedWorkspace?.address}</h6>
            </div>
            <Row>
              <Col>
                <div className="workspace-image-slider-wrapper">
                  <Swiper
                    modules={[Navigation, Pagination, Scrollbar, A11y]}
                    slidesPerView={2}
                    navigation 
                    pagination={{ clickable: true }}
                    className="workspace-inner-slider"
                  >
                    {photo_urls
                      ? photo_urls.map(
                          (workspaceImage, index: number) => {
                            return (
                              <div key={workspaceImage.id}>
                                <SwiperSlide>
                                  <img
                                    src={workspaceImage.url}
                                    alt={`img-${index}`}
                                    className="workspace-img"
                                    onError={handleImageError}
                                  />
                                </SwiperSlide>
                              </div>
                            );
                          }
                        )
                      : null}
                  </Swiper>
                </div>
              </Col>
            </Row>
            <h4 className="mt-4 text-dark">Available Workspace</h4>
            <Row className="mt-4 flex-wrap">
              {/* First Card */}
              <Col md={4}>
                  <Card>
                    <Row noGutters>
                      <Col md={4}>
                        <Card.Img
                          variant="top"
                          className="mt-3 ms-3"
                          src={require("../../images/workspace.png")}
                        />
                      </Col>
                      <Col md={8}>
                        <Card.Body>
                          <h6>
                            <b>Daily Pass</b>
                          </h6>
                          <div className="text-secondary">
                            Charge as per day
                          </div>
                          <Button
                            variant="outline-secondary"
                            className="text-primary mt-3 book"
                            // onClick={() => navigate("/workplace")}
                          >
                            Book Now at ${selectedWorkspace?.day_pass || 30}
                          </Button>
                        </Card.Body>
                      </Col>
                    </Row>
                  </Card>
                </Col>

              {/* Second Card */}
              <Col md={4}>
                  <Card>
                    <Row noGutters>
                      <Col md={4}>
                        <Card.Img
                          variant="top"
                          className="mt-3 ms-3"
                          src={require("../../images/private-room.png")}
                        />
                      </Col>
                      <Col md={8}>
                        <Card.Body>
                          <h6>
                            <b>Weekly Pass</b>
                          </h6>
                          <div className="text-secondary">
                            Changes as per week
                          </div>
                          <Button
                            variant="outline-secondary"
                            className="text-primary mt-3 book"
                          >
                           Book Now at ${selectedWorkspace?.weekly_pass || 100}
                          </Button>
                        </Card.Body>
                      </Col>
                    </Row>
                  </Card>
                </Col>

              {/* Third Card */}
              <Col md={4}>
                <Card>
                  <Row noGutters>
                    <Col md={4}>
                      <Card.Img
                        variant="top"
                        className="mt-3 ms-3"
                        src={require("../../images/private-office.png")}
                      />
                    </Col>
                    <Col md={8}>
                      <Card.Body>
                        <h6>
                          <b>Per Hours</b>
                        </h6>
                        <div className="text-secondary">
                          Charge as per hours
                        </div>
                        <Button
                          variant="outline-secondary"
                          className="text-primary mt-3 book"
                        >
                          Book Now at ${selectedWorkspace?.per_hour || 50}
                        </Button>
                      </Card.Body>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
            <Row className="workspace-details-container">
              <Col md={6}>
                <h3 className="text-dark mb-3">Location</h3>
                <MapComponent searchedCountry={{ latitude, longitude }} />
              </Col>
              <Col md={6}>
                <h3 className="text-dark mb-3 w-detail-title">
                  Workspace details
                </h3>
                <ShowWorkSpace
                  workSpace={selectedWorkspace}
                  isWorkspaceGallery={false}
                />
              </Col>
            </Row>
          </>
        )}
      </Container>
    </section>
  );
};

export default WorkSpaceDetailsPage;
