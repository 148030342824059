import { Card } from "react-bootstrap";
import { IEditWorkspace } from "../../../../../interface/workspace/workspace.interface";
import { memo } from "react";
import { handleImageError } from "../../../../../helpers";

interface Props {
  workSpace: IEditWorkspace;
  isWorkspaceGallery: boolean;
}

const ShowWorkSpace: React.FC<Props> = memo(
  ({ workSpace, isWorkspaceGallery = false }) => {
    const {
      name,
      address,
      phone_no,
      location_url,
      country_name,
      postal_code,
      range,
      city_name,
      space_type_name,
      photo_urls,
      price,
      day_pass,
      weekly_pass,
      per_hour,
    } = workSpace ?? {};

    return (
      <Card>
        <Card.Header>
          <b className="ms-1">{name}</b>
        </Card.Header>
        <Card.Body>
          <Card.Text>
            <b>Address</b>: {address}
          </Card.Text>
          <Card.Text>
            <b>Phone No</b>: {phone_no}
          </Card.Text>
          <Card.Text>
            <b>Country</b>: {country_name}
          </Card.Text>
          <Card.Text>
            <b>Location URL</b>: {location_url}
          </Card.Text>
          <Card.Text>
            <b>Postal Code</b>: {postal_code}
          </Card.Text>
          <Card.Text>
            <b>City</b>: {city_name}
          </Card.Text>
          <Card.Text>
            <b>Space Type</b>: {space_type_name}
          </Card.Text>
          <Card.Text>
            <b>Team Range</b>: {range}
          </Card.Text>
          <Card.Text>
            <b>Price($)</b>: {price}
          </Card.Text>
          <Card.Text>
            <b>Day Pass</b>: {day_pass || "N/A"}
          </Card.Text>
          <Card.Text>
            <b>Weekly Pass</b>: {weekly_pass || "N/A"}
          </Card.Text>
          <Card.Text>
            <b>Per Hour</b>: {per_hour || "N/A"}
          </Card.Text>

          {isWorkspaceGallery && photo_urls && photo_urls.length ? (
            <>
              <hr />
              <div className="workspace-gallery-wrapper">
                <h3 className="heading">Workspace gallery</h3>
                <div className="list-groups">
                  {photo_urls.map((workspaceImg) => (
                    <div className="img-wrapper">
                      <img
                        src={workspaceImg.url}
                        alt={workspaceImg.url}
                        key={workspaceImg.id}
                        onError={handleImageError}
                      />
                    </div>
                  ))}
                </div>
              </div>
            </>
          ) : null}
        </Card.Body>
      </Card>
    );
  }
);

export default ShowWorkSpace;
