import { AxiosError, AxiosResponse } from "axios";
import { useEffect, useRef, useState } from "react";
import { Col, Container, Row, Spinner } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { UnAuthenticatedRoutesEnums } from "../../../constants/routes.constants";
import axiosInstance from "../../../helpers/axios/axios.config";
import { useUserLoginStore } from "../../../zustand-store/auth/userLogin.store";

interface IVerifyUserTokenProps {}

const VerifyUserToken: React.FunctionComponent<IVerifyUserTokenProps> = (
  props
) => {
  const initialized = useRef(false);
  const { token } = useParams();
  const navigate = useNavigate();
  const [tokenError, setTokenError] = useState<string | undefined>(undefined);

  const { saveTokenAndUser } = useUserLoginStore();

  useEffect(() => {
    if (!initialized.current && token) {
      initialized.current = true;
      axiosInstance
        .get(`/verify_user/${token}`)
        .then((result) => {
          const userData = (result as AxiosResponse).data.user;
          const resetToken = (result as AxiosResponse).data.token;
          saveTokenAndUser({
            token: resetToken,
            userData: userData,
          });
          // navigate(UnAuthenticatedRoutesEnums.USER_INFO);
          navigate(
            UnAuthenticatedRoutesEnums.USER_INFO.replace(":token", token)
          );
        })
        .catch((error) => {
          const axiosError: AxiosError = error as AxiosError;
          const errorMessage: string = (axiosError.response?.data as any)
            ?.message;
          if (!errorMessage) {
            setTokenError("Something went wrong!!");
          }
          toast(errorMessage, { type: "error" });
          toast("Redirecting you to home page.", { type: "info" });
          setTimeout(() => {
            navigate(UnAuthenticatedRoutesEnums.HOME);
          }, 3000);
        });
    }
  }, [navigate, saveTokenAndUser, token]);

  return (
    <section className="pb-0 pt-0">
      {tokenError ? (
        <>{tokenError}</>
      ) : (
        <Container>
          <Row>
            <Col md={12} lg={12} sm={12} className="d-flex align-items-center">
              <div className="form-common-box">
                <h3 className="form-common-title">Verifing user token.</h3>
                <p className="common-para mb-3">
                  Please wait a moment while we are verifing token.
                </p>
                <Spinner />
              </div>
            </Col>
          </Row>
        </Container>
      )}
    </section>
  );
};

export default VerifyUserToken;
