import { AxiosError } from "axios";
import { Formik, FormikHelpers, FormikProps } from "formik";
import { useState } from "react";
import { Button, Form, Modal, Spinner } from "react-bootstrap";
import axiosInstance from "../../../../helpers/axios/axios.config";
import { IExploreMemberShipRequestData } from "../../../../interface/exploreMembership/exploreMembership.interface";
import { ExploreMembershipFormValidation } from "../../../../validation/exploreMembership/exploreMembership.validation";
import { toast, ToastOptions, TypeOptions } from "react-toastify";

export interface IExploreMemberShipModalProps {
  showExploreModal: boolean;
  handleExploreModalClose: () => void;
}

interface IExploreMembershipForm {
  name: string;
  email: string;
  explore_type: "Individual" | "Group" | "Other";
  explore_other: "";
}

export function ExploreMemberShipModal(props: IExploreMemberShipModalProps) {
  const [formError, setFormError] = useState<string | undefined>(undefined);
  const handleSubmitForm = async (
    values: IExploreMembershipForm,
    formikHelpers: FormikHelpers<IExploreMembershipForm>
  ) => {
    try {
      setFormError(undefined);
      await axiosInstance.post("/explore_memberships", {
        explore_membership: {
          name: values.name,
          email: values.email,
          explore_type:
            values.explore_type === "Other"
              ? values.explore_other
              : values.explore_type,
        },
      } as IExploreMemberShipRequestData);
      toast("Membership request submitted successfully.", {
        type: "success" as TypeOptions,
      } as ToastOptions);
      formikHelpers.resetForm();
      props.handleExploreModalClose();
    } catch (error) {
      if (
        error instanceof AxiosError &&
        ((error as AxiosError)?.response?.data as any)?.email
      ) {
        formikHelpers.setFieldError(
          "email",
          "Email " + ((error as AxiosError)?.response?.data as any)?.email
        );
      } else {
        toast("Something went wrong, Please try again later.", {
          type: "error" as TypeOptions,
        } as ToastOptions);
      }
    }
  };

  const initialValues: IExploreMembershipForm = {
    name: "",
    email: "",
    explore_type: "Individual",
    explore_other: "",
  };

  return (
    <Modal
      className="common-modal"
      centered
      show={props.showExploreModal}
      onHide={props.handleExploreModalClose}
    >
      <Modal.Header closeButton>
        <Modal.Title>Explore membership</Modal.Title>
        <p className="mb-0">Thank you for choosing to be part of our journey towards a borderless business world</p>
      </Modal.Header>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmitForm}
        validationSchema={ExploreMembershipFormValidation}
        validateOnChange={false}
        validateOnBlur={false}
      >
        {({
          values,
          errors,
          touched,
          isSubmitting,
          handleSubmit,
          handleChange,
          handleBlur,
        }: FormikProps<IExploreMembershipForm>) => (
          <>
            <Modal.Body>
              <Form>
                <Form.Group
                  className="mb-4 position-relative"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="email"
                    name="email"
                    placeholder="Email@borderless.business"
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={`form-icon-control ${
                      errors.email ? "error" : ""
                    }`}
                  />
                  <span className="icon-main  field-icon"></span>

                  {errors.email && (
                    <Form.Control.Feedback type="invalid" className="d-block">
                      {errors.email}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>

                <Form.Group
                  className="mb-4 position-relative"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="name"
                    placeholder="Steve Jobs"
                    value={values.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={`form-icon-control ${
                      errors.name ? "error" : ""
                    }`}
                  />
                  <span className="icon-profile field-icon"></span>
                  {errors.name && (
                    <Form.Control.Feedback type="invalid" className="d-block">
                      {errors.name}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>
                    What type of membership you are exploring
                  </Form.Label>
                  <ul className="list-unstyled d-flex radio-inline">
                    <li>
                      <input
                        type="radio"
                        name="explore_type"
                        className="radio-input"
                        value="Individual"
                        checked={values.explore_type === "Individual"}
                        onChange={handleChange}
                      />
                      <label htmlFor="Individual" className="radio-label">
                        Individual
                      </label>
                    </li>
                    <li>
                      <input
                        type="radio"
                        name="explore_type"
                        className="radio-input"
                        value="Group"
                        checked={values.explore_type === "Group"}
                        onChange={handleChange}
                      />
                      <label htmlFor="Group" className="radio-label">
                        Company
                      </label>
                    </li>
                    <li>
                      <input
                        type="radio"
                        name="explore_type"
                        className="radio-input"
                        value="Other"
                        checked={values.explore_type === "Other"}
                        onChange={handleChange}
                      />
                      <label htmlFor="Other" className="radio-label">
                        Other
                      </label>
                    </li>
                  </ul>
                </Form.Group>

                {values.explore_type === "Other" && (
                  <Form.Group>
                    <Form.Control
                      type="text"
                      name="explore_other"
                      placeholder="Community"
                      value={values.explore_other}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.explore_other && (
                      <Form.Control.Feedback type="invalid" className="d-block">
                        {errors.explore_other}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>
                )}
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button
                disabled={isSubmitting}
                variant="primary"
                className="w-100"
                onClick={() => handleSubmit()}
              >
                {isSubmitting ? (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                ) : (
                  "Explore membership"
                )}
              </Button>
              {formError && (
                <Form.Group>
                  <Form.Control.Feedback type="invalid" className="d-block">
                    {formError}
                  </Form.Control.Feedback>
                </Form.Group>
              )}
            </Modal.Footer>
          </>
        )}
      </Formik>
    </Modal>
  );
}
