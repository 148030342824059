import { AxiosError } from "axios";
import { Formik, FormikHelpers, FormikProps } from "formik";
import { useEffect } from "react";
import { Button, Col, Container, Form, Row, Spinner } from "react-bootstrap";
import PhoneInput from "react-phone-input-2";
import { isValidPhoneNumber, parsePhoneNumber } from "react-phone-number-input";
import { toast, ToastOptions, TypeOptions } from "react-toastify";
import { Roles } from "../../../constants/roles.constant";
import { UserType } from "../../../constants/user_type.constant";
import axiosInstance from "../../../helpers/axios/axios.config";
import { ICountryDetail } from "../../../interface/config/config.interface";
import { ICreateWorkspacesRequest } from "../../../interface/createAccount/createAccount.interface";
import "../../../styles/header.scss";
import { CreateWorkspaceAccountFormValidation } from "../../../validation/createAccount/createWorkspaceAccount.validation";
import { useConfigStore } from "../../../zustand-store/config/config.store";
import { useNavigate } from "react-router-dom";
import { UnAuthenticatedRoutesEnums } from "../../../constants/routes.constants";

interface ICreateAccountFormParams {
  name: string;
  email: string;
  position: string;
  phone: string;
  address: string;
}

interface IForBusinessProps {}

const ForBusiness: React.FunctionComponent<IForBusinessProps> = (props) => {
  const navigate = useNavigate()
  const { countryList, fetchCountryList } = useConfigStore();
  useEffect(() => {
    if (!countryList.length) fetchCountryList();
  }, [fetchCountryList, countryList]);
  const handleSubmitForm = async (
    values: ICreateAccountFormParams,
    formikHelpers: FormikHelpers<ICreateAccountFormParams>
  ) => {
    if (!isValidPhoneNumber("+" + values.phone)) {
      formikHelpers.setFieldError("phone", "Please enter valid phone number");
      return;
    }
    const parsedPhoneNumber = parsePhoneNumber("+" + values.phone);
    const selectedCountryDetails: ICountryDetail | undefined = countryList.find(
      (country: ICountryDetail) =>
        country.phone_code === parsedPhoneNumber?.countryCallingCode
    );
    try {
      await axiosInstance.post("/workspace_inquiries", {
       workspace_inquiry: {
          name: values.name,
          email: values.email,
          phone: parsedPhoneNumber?.nationalNumber,
          address: values.address,
          position: values.position,
        },
      } as ICreateWorkspacesRequest);
      navigate('/thankyou',{ state: { fromCreateWorkspace: true } });
      formikHelpers.resetForm();
    } catch (error) {
      if (
        error instanceof AxiosError &&
        ((error as AxiosError)?.response?.data as any)?.email
      ) {
        formikHelpers.setFieldError(
          "email",
          "Email " + ((error as AxiosError)?.response?.data as any)?.email
        );
      } else {
        toast("Something went wrong, Please try again later.", {
          type: "error" as TypeOptions,
        } as ToastOptions);
      }
    }
  };

  const initialValues: ICreateAccountFormParams = {
    name: "",
    email: "",
    position: "",
    phone: "41",
    address: "",
  };
  return (
    <section className="pb-0 pt-0">
      <Container>
        <Row>
          <Col md={12} lg={6} className="d-flex align-items-center">
            <div className="form-common-box">
              <h3 className="form-common-title">
                Connect with our partnerships team
              </h3>
              <p className="common-para mb-3">
                We're excited to hear from you, and always excited to hear about
                your community space special story.
              </p>
              <Formik
                initialValues={initialValues}
                onSubmit={handleSubmitForm}
                validationSchema={CreateWorkspaceAccountFormValidation}
                validateOnChange={false}
                validateOnBlur={false}
              >
                {({
                  values,
                  errors,
                  isSubmitting,
                  handleSubmit,
                  handleChange,
                  handleBlur,
                  setFieldValue,
                }: FormikProps<ICreateAccountFormParams>) => (
                  <Form>
                    <Row>
                      <Col md={12} lg={6}>
                        <Form.Group
                          className="mb-4 position-relative"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Label>Name</Form.Label>
                          <Form.Control
                            type="text"
                            name="name"
                            placeholder="Antoni Gaudi"
                            value={values.name}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className={`form-icon-control ${
                              errors.name ? "error" : ""
                            }`}
                          />
                          <span className="icon-profile field-icon"></span>
                          <Form.Control.Feedback
                            type="invalid"
                            className="d-block"
                          >
                            {errors.name}
                          </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group
                          className="mb-4 position-relative"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Label>Email</Form.Label>
                          <Form.Control
                            type="email"
                            name="email"
                            placeholder="beautiful@things.com"
                            value={values.email}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className={`form-icon-control ${
                              errors.email ? "error" : ""
                            }`}
                          />
                          <span className="icon-main  field-icon"></span>
                          <Form.Control.Feedback
                            type="invalid"
                            className="d-block"
                          >
                            {errors.email}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col md={12} lg={6}>
                        <Form.Group
                          className="mb-4 position-relative"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Label>Position</Form.Label>
                          <Form.Control
                            type="text"
                            name="position"
                            placeholder="Marketing Manager"
                            value={values.position}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className={`form-icon-control ${
                              errors.position ? "error" : ""
                            }`}
                          />
                          <span className="icon-user-octagon  field-icon"></span>
                          <Form.Control.Feedback
                            type="invalid"
                            className="d-block"
                          >
                            {errors.position}
                          </Form.Control.Feedback>
                        </Form.Group>

                        {/* <Form.Group
                        className="mb-4"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label>Phone</Form.Label>

                        <Form.Control
                          type="text"
                          name="phone"
                          placeholder="999XXXXXXX"
                          value={values.phone}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className={`${errors.phone ? "error" : ""}`}
                        />
                        
                      </Form.Group> */}
                        <Form.Group
                          className="mb-4"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Label>Phone</Form.Label>
                          <PhoneInput
                            enableSearch
                            value={values.phone}
                            inputProps={{
                              placeholder: "Swiss flag",
                              className: `${
                                errors.phone
                                  ? "form-control error"
                                  : "form-control "
                              }`,
                            }}
                            onChange={(phone) =>
                              setFieldValue("phone", phone || "")
                            }
                          />
                          {errors.phone && (
                            <Form.Control.Feedback
                              type="invalid"
                              className="d-block"
                            >
                              {errors.phone}
                            </Form.Control.Feedback>
                          )}
                          {errors.phone && (
                            <Form.Control.Feedback
                              type="invalid"
                              className="d-block"
                            >
                              {errors.phone}
                            </Form.Control.Feedback>
                          )}
                        </Form.Group>
                      </Col>
                      <Col md={12} lg={12}>
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlTextarea1"
                        >
                          <Form.Label>Address</Form.Label>
                          <Form.Control
                            as="textarea"
                            className="h-auto"
                            placeholder="Drop a Hint, Share Your Needs* Let Us Know What You're Looking For! Don't Forget to Include Your workspace Address for Better Assistance."
                            name="address"
                            rows={5}
                            value={values.address}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {errors.address && (
                            <Form.Control.Feedback
                              type="invalid"
                              className="d-block"
                            >
                              {errors.address}
                            </Form.Control.Feedback>
                          )}
                        </Form.Group>
                      </Col>
                      <Col lg={12}>
                        <Form.Group className="mt-2">
                          <Button
                            variant="primary"
                            className="w-100"
                            onClick={() => {handleSubmit()}}
                            disabled={isSubmitting}
                          >
                            {isSubmitting ? (
                              <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                              />
                            ) : (
                              "Contact for create a workspaces"
                            )}
                          </Button>
                        </Form.Group>
                      </Col>
                    </Row>
                  </Form>
                )}
              </Formik>
            </div>
          </Col>
          <Col md={12} lg={6}>
            <img
              src={require("../../../images/forbusiness-img.png")}
              alt="hero banner"
              className="forworkspace-img"
            />
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default ForBusiness;
