import { AxiosError, AxiosResponse } from "axios";
import { create } from "zustand";
import { devtools } from "zustand/middleware";
import axiosInstance from "../../helpers/axios/axios.config";
import { ICustomerDetails } from "../../interface/customers/customer.interceptor";

export const useCustomerStore = create<ICustomerStore>()(
  devtools(
    (set, get) => ({
      customers: [],
      isFetchingCustomers: false,
      noCustomersFound: true,
      fetchCustomersError: undefined,
      isDeleteingCustomers: false,
      deleteCustomersError: undefined,
      fetchCustomers: async (id: number) => {
        try {
          set({
            isFetchingCustomers: true,
            noCustomersFound: false,
            fetchCustomersError: undefined,
          });
          const customerListResponse: AxiosResponse<{
            users: ICustomerDetails[];
          }> = await axiosInstance.get(`/workspaces/${id}/customers`);
          set({
            customers: customerListResponse.data.users,
            isFetchingCustomers: false,
            noCustomersFound: customerListResponse.data.users.length === 0,
            fetchCustomersError: undefined,
          });
        } catch (error) {
          let message: string = "Something went wrong";
          if (error instanceof AxiosError) {
            message = ((error as AxiosError)?.response?.data as any)
              ?.message as string;
          }
          set({
            isFetchingCustomers: false,
            noCustomersFound: false,
            fetchCustomersError: message,
          });
        }
      },
      deleteCustomers: async (id: number, handleDeleteUserModalClose?: any) => {
        try {
          set({
            isDeleteingCustomers: true,
            deleteCustomersError: undefined,
          });
          await axiosInstance.delete("/users/" + id);
          const { customers } = get();
          const updatedCustomersList = customers.filter(
            (user: ICustomerDetails) => user.id !== id
          );
          set({
            customers: updatedCustomersList,
            noCustomersFound: updatedCustomersList.length === 0,
            isDeleteingCustomers: false,
            deleteCustomersError: undefined,
          });
          handleDeleteUserModalClose && handleDeleteUserModalClose();
        } catch (error) {
          let message: string = "Something went wrong";
          if (error instanceof AxiosError) {
            message = ((error as AxiosError)?.response?.data as any)
              ?.message as string;
          }
          set({
            isDeleteingCustomers: false,
            deleteCustomersError: message,
          });
        }
      },
    }),
    {
      name: "customers_store",
      anonymousActionType: "customers_actions",
    }
  )
);

export interface ICustomerStore {
  customers: ICustomerDetails[];
  isFetchingCustomers: boolean;
  fetchCustomersError?: string;
  isDeleteingCustomers: boolean;
  deleteCustomersError?: string;
  noCustomersFound: boolean;
  fetchCustomers: (id: number) => void;
  deleteCustomers: (id: number, handleDeleteUserModalClose?: any) => void;
}
