import * as Yup from "yup";

export const UploadDetailsFormValidation = Yup.object().shape({
  name: Yup.string().required("Please enter the workspace name"),
  phone_no: Yup.string()
    // .matches(/^(\+\d{1,4}[- ]?)?\d{12}$/, "Enter valid phone number")
    .matches(/^\+?\d{9,12}$/, "Enter a valid phone number")
    .required("Please enter a phone number"),
  city_name: Yup.string().required("Please enter the city"),
  postal_code: Yup.mixed().test(
    "postal_code",
    "Please enter postal code",
    (value) => typeof value === "number" || typeof value === "string"
  ),
  location_url: Yup.string()
    .matches(
      /^(http[s]?:\/\/){0,1}(www\.){0,1}[a-zA-Z0-9.\-]+\.[a-zA-Z]{2,5}[\.]{0,1}/,
      "Enter correct url!"
    )
    .required("Please enter location url"),
  country_id: Yup.mixed().test(
    "country_id",
    "Please select country",
    (value) => typeof value === "number" || typeof value === "string"
  ),
  space_type_id: Yup.mixed().test(
    "space_type_id",
    "Please select space type",
    (value) => typeof value === "number" || typeof value === "string"
  ),
  team_range_id: Yup.mixed().test(
    "team_range_id",
    "Please select team range",
    (value) => typeof value === "number" || typeof value === "string"
  ),
  price: Yup.number()
    .moreThan(1, "Price must be greater than one")
    .transform((value) => (Number.isNaN(value) ? null : value))
    .required("Price is required"),
  day_pass: Yup.number()
    .min(1, "Minimum one daily pass is required")
    .transform((value) => (Number.isNaN(value) ? null : value))
    .required("Day pass is required"),
  weekly_pass: Yup.number()
    .min(1, "Minimum one weekly pass is required")
    .transform((value) => (Number.isNaN(value) ? null : value))
    .required("Weekly pass is required"),
  per_hour: Yup.number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .min(1, "Minimum one hour is required")
    .required("Per hour pass is required"),
  address: Yup.string().required("Please enter the address"),
});
