import { useEffect, useState } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import { IEmployeeDetails } from "../../../../../../interface/employees/employees.interceptor";
import { useEmployeeStore } from "../../../../../../zustand-store/employees/employees.store";

interface IEmployeeInfoModalProps {
  showEmployeeInfoModal: boolean;
  handleEmployeeInfoModalClose: () => void;
  userId: number | undefined;
}

const EmployeeInfoModal: React.FunctionComponent<IEmployeeInfoModalProps> = (
  props
) => {
  const { employees } = useEmployeeStore();
  const [userDetails, setUserDetails] = useState<IEmployeeDetails | undefined>(
    undefined
  );

  useEffect(() => {
    const UserDetailsById = employees?.find(
      (user: IEmployeeDetails) => user.id === props.userId
    );
    setUserDetails(UserDetailsById);
  }, [employees, props.userId]);

  return (
    <Modal
      className="common-modal"
      centered
      show={props.showEmployeeInfoModal}
      onHide={props.handleEmployeeInfoModalClose}
    >
      <Modal.Header closeButton>
        <Modal.Title>Employee Information</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col sm={5}>
            <span className="user-label">Name</span>
          </Col>
          <Col sm={7}>
            <span className="user-text">{userDetails?.name}</span>
          </Col>
        </Row>
        <Row>
          <Col sm={5}>
            <span className="user-label">Email</span>
          </Col>

          <Col sm={7}>
            <span className="user-text">{userDetails?.email}</span>
          </Col>
        </Row>
        <Row>
          <Col sm={5}>
            <span className="user-label">Phone</span>
          </Col>

          <Col sm={7}>
            <span className="user-text">{userDetails?.phone}</span>
          </Col>
        </Row>
        <Row>
          <Col sm={5}>
            <span className="user-label">Position</span>
          </Col>

          <Col sm={7}>
            <span className="user-text">{userDetails?.position}</span>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default EmployeeInfoModal;
