import { Button, Form, Table } from "react-bootstrap";
import axiosInstance from "../../../../../helpers/axios/axios.config";
import DashboardEyeicon from "../../../../../images/icons/eye.svg";
import DashboardSendicon from "../../../../../images/icons/send.svg";
import DashboardTrashicon from "../../../../../images/icons/trash.svg";
import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { AuthenticatedRoutesEnums } from "../../../../../constants/routes.constants";


export default function Communities() {
  const location = useLocation()
  const [communitiesInquiries, SetCommunitiesInquiries]= useState(undefined);
  const loadComm = () =>{ axiosInstance.get("/communities_inquiries").then((response) => {
    SetCommunitiesInquiries(response.data)
    // return response;
  });
}
  // useEffect(() => loadComm(), [])
  //   console.log('-----response communities_inquiries----',communitiesInquiries );
  
    useEffect(() => {
      if (
        location.pathname === AuthenticatedRoutesEnums.DASHBOARD_COMMUNITIES
      ) 
        loadComm();
    }, [ location.pathname]);  
    
    
  return (
    <>
      <div className="dashboard-search-box">
        <h3 className="form-common-title">Communities</h3>
        </div >
         
      <Table hover responsive className="dashboard-table">
        <thead>
          <tr>
            <th>Name</th>
            <th>Email</th>
            <th>Company name</th>
            <th>Position</th>
            <th>User Type</th>
            <th>Team Range</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {!communitiesInquiries  && (
            <tr>
              <td>Loading...</td>
            </tr>
          )}
          {communitiesInquiries?.map((community_inquiry) => {
            return (
              <tr key={`${community_inquiry.id}-${community_inquiry.email}`}>
                <td>{community_inquiry.name}</td>
                <td>{community_inquiry.email|| "NA"}</td>
                <td>{community_inquiry.company_name}</td>
                <td>{community_inquiry.position}</td>
                <td>{community_inquiry.user_type}</td>
                <td>{community_inquiry.team_range}</td>
                <td>
                  <div className="btn-actions">
                    {!["activated", "accepted"].includes(community_inquiry.status) && (
                      <Button
                        variant="white"
                        // onClick={() => {
                        //   handleSendInviteButton(user.id);
                        // }}
                      >
                        <img src={DashboardSendicon} alt="icon" />
                      </Button>
                    )}
                    <Button
                      variant="white"
                      className="me-2 ms-2"
                      // onClick={() => {
                      //   handleUserInfoModalShow(user.id);
                      // }}
                    >
                      <img src={DashboardEyeicon} alt="icon" />
                    </Button>
                    <Button
                      variant="white"
                      // onClick={() => {
                      //   handleDeleteUserModalShow(user.id);
                      // }}
                    >
                      <img src={DashboardTrashicon} alt="icon" />
                    </Button>
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
      {/* {showDetailsForUserId !== undefined && (
        <UserInformation
          showUserInfoModal={showDetailsForUserId !== undefined}
          handleUserInfoModalClose={handleUserInfoModalClose}
          userId={showDetailsForUserId}
        />
      )}
      {showDeleteForUserId !== undefined && (
        <DeleteUser
          showDeleteUserModal={showDeleteForUserId}
          handleDeleteUserModalClose={handleDeleteUserModalClose}
          userId={showDeleteForUserId}
        />
      )} */}
    </>
  );
}
 