import { AxiosRequestConfig } from "axios";
import { IAxiosInstance } from "./interface/axios.interface";

const axiosMethods = (instance: IAxiosInstance): IAxiosInstance => ({
  get: (path: string, config?: AxiosRequestConfig) =>
    instance.get(path, config),
  post: (path: string, payload?: any, config?: AxiosRequestConfig) =>
    instance.post(path, payload, config),
  put: (path: string, payload: any, config?: AxiosRequestConfig) =>
    instance.put(path, payload, config),
  delete: (path: string, config?: AxiosRequestConfig) =>
    instance.delete(path, config),
  patch: (path: string, payload: any, config?: AxiosRequestConfig) =>
    instance.patch(path, payload, config),
});

export default axiosMethods;
