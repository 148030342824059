import { useEffect, useState } from "react";
import { Button, Carousel, Col, Container, Form, Row } from "react-bootstrap";
import { ICountryDetail } from "../../interface/config/config.interface";
import "../../styles/footer.scss";
import "../../styles/pass-access.scss";
import { useConfigStore } from "../../zustand-store/config/config.store";
import { useNavigate } from "react-router-dom";
import { AuthenticatedRoutesEnums } from "../../constants/routes.constants";

const images = [
  require("../../images/teamworks.png"),
  require("../../images/worksdetails.png"),
  require("../../images/works.png"),
  require("../../images/works4.png"),
  require("../../images/teamworks.png"),
  require("../../images/worksdetails.png"),
  require("../../images//works.png"),
];

const PassAccessPage: React.FC = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [selectedCountry, setCountry] = useState<string>();
  const { countryList, fetchCountryList, fetchingCountryList } =
    useConfigStore();
  const navigate = useNavigate();

  useEffect(() => {
    if (!countryList.length) {
      fetchCountryList();
    }
  }, [countryList, fetchCountryList]);

  const handleCountryChange = (e: any) => {
    setCountry(e.target.value);
  };

  const handleInMyAreaClick = () => {
    navigate(
      selectedCountry
        ? `${AuthenticatedRoutesEnums.WORKSPACES}?co=${selectedCountry}`
        : AuthenticatedRoutesEnums.WORKSPACES
    );
  };

  return (
    <Container className="pass-container">
      <Row>
        <Col>
          <Carousel
            slide={true}
            interval={3000}
            controls={false}
            indicators={true}
            className="my-custom-carousel"
          >
            {images.map((image, index) => {
              const nextIndex = (index + 1) % images.length;
              if (index % 2 === 0) {
                return (
                  <Carousel.Item key={index}>
                    <Row>
                      <Col>
                        <img
                          className="d-block w-100 carousel-img"
                          src={image}
                          alt={`carousel ${index + 1}`}
                        />
                      </Col>
                      <Col>
                        <img
                          className="d-block w-100 carousel-img"
                          src={images[nextIndex]}
                          alt={`carousel ${nextIndex + 1}`}
                        />
                      </Col>
                    </Row>
                  </Carousel.Item>
                );
              }
              return null;
            })}
          </Carousel>
        </Col>
      </Row>

      <div className="form-common-box">
        <Row className="mt-4 align-items-center">
          <Col md={6}>
            <div className="text-body">
              <h2>
                <b>Pass access</b>
              </h2>
              <p>If you want to work together with us then join us</p>
            </div>
          </Col>
          <Col md={4}>
            <Form.Group controlId="exampleForm.ControlSelect1">
              <Form.Select
                as="select"
                className="country-select"
                onChange={(e) => setCountry(e.target.value)}
              >
                {fetchingCountryList ? (
                  <option>Loading country list</option>
                ) : (
                  [
                    <option value="" className="country-select">
                      Select country
                    </option>,
                    ...countryList?.map((country: ICountryDetail) => (
                      <option
                        className="country-select"
                        key={country.id}
                        value={country.id}
                        label={`${country.name} ${country.emoji} `}
                      />
                    )),
                  ]
                )}
              </Form.Select>
            </Form.Group>
          </Col>
          <Col>
            <Button variant="primary" onClick={handleInMyAreaClick}>
              In My Area
            </Button>
          </Col>
        </Row>
      </div>
    </Container>
  );
};

export default PassAccessPage;
