import { LocalStorageEnums } from "../enums/localStorage.enums";

// Methods helps to check if used is logged in or not.
export const isUserLoggedIn = (): boolean => {
  // Update the function when start login
  //   return !!localStorage.getItem(LocalStorageEnums.USER_LOGGED_IN);
  return true;
};

// Method helps to get user token from local storage
export const getUserToken = (): string | null => {
  return localStorage.getItem(LocalStorageEnums.USER_TOKEN);
};

export const getUserData = (): any | null => {
  const data  = localStorage.getItem('userData')
  let userData;
  if (data) {
    userData = JSON.parse(data);
  };
  return userData;
};

export const clearLocalStorage = (): void => {
  localStorage.clear();
};

export const setLocalStorage = (key: string, value: any): void => {
  localStorage.setItem(key, value);
};

export const removeLocalStorageItem = (key: string): void => {
  localStorage.removeItem(key);
};
