import React, { useCallback, useState } from 'react'
import { Button, Col, Container, Form, Row } from 'react-bootstrap'
import { FileUploader } from "react-drag-drop-files";
import { useDropzone } from 'react-dropzone'


function UserRedeem() {

    const [value, setValue] = useState()

    const onDrop = useCallback(acceptedFiles => {
        // Do something with the files
    }, [])
    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

    return (
        <section className='flex-1'>
            <Container>
                <Row className="justify-content-md-center">
                    <Col lg={6}>
                        <div className='form-common-box'>
                            <div className='align-items-center d-flex justify-content-between'>
                                <div>
                                    <h3 className="form-common-title text-dark">
                                        Lorem ipsum
                                    </h3>
                                    {/* <p className="common-para mb-3">
                                        Lorem ipsum dolor sit amet consectetur.
                                    </p> */}
                                </div>
                                <div>
                                    <Button
                                        variant="primary"
                                    >
                                        Scan QR
                                    </Button>
                                </div>
                            </div>

                            <Row>
                                <Col lg={12}>
                                    <Form.Group className="mb-3 position-relative" controlId="exampleForm.ControlInput1">
                                        <Form.Label>User code number</Form.Label>
                                        <Form.Control type="text"
                                            placeholder="Enter user code number here"
                                        />
                                    </Form.Group>
                                </Col>

                                <Col lg={12}>
                                    <Form.Group className="mt-2">
                                        <Button
                                            variant="primary"
                                        >
                                            Confirm
                                        </Button>
                                    </Form.Group>
                                </Col>
                            </Row>
                        </div>

                    </Col>
                </Row>
            </Container>
        </section>

    )
}

export default UserRedeem

