import { useEffect, useState } from "react";
import { Button, Form, Spinner, Table } from "react-bootstrap";
import { toast, ToastOptions, TypeOptions } from "react-toastify";
import { IEmployeeDetails } from "../../../../../../interface/employees/employees.interceptor";
import { useAuthStore } from "../../../../../../zustand-store/auth/auth.store";
import { useEmployeeStore } from "../../../../../../zustand-store/employees/employees.store";
import AddEmployeeModal from "./add-or-edit-employee-modal";
import EmployeeDeleteModal from "./employee-delete-modal";
import EmployeeInfoModal from "./employee-details-modal";
import { useLocation } from "react-router-dom";
import { AuthenticatedRoutesEnums } from "../../../../../../constants/routes.constants";

function Employee() {
  const location = useLocation();
  const [showAddEmployeeModal, setShowAddEmployeeModal] =
    useState<boolean>(false);
  const handleAddEmployeeModalClose = () => {
    setSelectedEmployeeDetail(undefined);
    setShowAddEmployeeModal(false);
  };
  const handleAddEmployeeModalShow = () => setShowAddEmployeeModal(true);

  const [selectedEmployeeDetail, setSelectedEmployeeDetail] = useState<
    IEmployeeDetails | undefined
  >(undefined);

  const [showDetailsForEmployeeById, setShowDetailsForEmployeeById] = useState<
    number | undefined
  >(undefined);
  const handleEmployeeInfoModalClose = () =>
    setShowDetailsForEmployeeById(undefined);
  const handleEmployeeInfoModalShow = (id: number) =>
    setShowDetailsForEmployeeById(id);

  const [showDeleteForEmployeeId, setShowDeleteForEmployeeId] = useState<
    number | undefined
  >(undefined);
  const handleDeleteEmployeeModalClose = () =>
    setShowDeleteForEmployeeId(undefined);
  const handleDeleteEmployeeModalShow = (id: number) =>
    setShowDeleteForEmployeeId(id);

  const { employees, fetchEmployee, isFetchingEmployees, fetchEmployeeError } =
    useEmployeeStore();
  const { loggedInUserDetails } = useAuthStore();

  const [listOfEmployees, setListOfEmployees] = useState<IEmployeeDetails[]>(
    []
  );
  const [searchSlug, setSearchSlug] = useState<string>("");

  useEffect(() => {
    setListOfEmployees(employees);
  }, [employees]);
  useEffect(() => {
    if (fetchEmployeeError)
      toast(
        "Failed to fetch the list of employees, please try to refresh the page.",
        {
          type: "error" as TypeOptions,
        } as ToastOptions
      );
  }, [fetchEmployeeError]);

  useEffect(() => {
    setListOfEmployees(
      employees.filter(
        (employee) =>
          employee.name.toLowerCase().includes(searchSlug.toLowerCase()) ||
          employee.email.toLowerCase().includes(searchSlug.toLowerCase())
      )
    );
  }, [searchSlug, employees]);

  useEffect(() => {
    if (
      location.pathname === AuthenticatedRoutesEnums.BUSINESS_PROFILE_EMPLOYEES
    ) {
      fetchEmployee(loggedInUserDetails?.workspaces[0]?.id as any);
    }
  }, [fetchEmployee, location.pathname, loggedInUserDetails]);

  return (
    <>
      <div className="form-common-box rounded-0">
        <div className="d-flex justify-content-between align-items-center mb-3">
          <h2 className="myprofile-title text-dark mb-0">
            <span className="icon-users pe-2"></span>
            Employees
          </h2>
          <div className="d-flex align-items-center">
            <div className="search-field d-flex">
              <Form.Control
                type="text"
                placeholder="search employees..."
                onChange={(e) => {
                  setSearchSlug(e.target.value);
                }}
              />
              <span className="icon-search-normal"></span>
            </div>
            <Button
              variant="primary"
              className="ms-2" 
              onClick={handleAddEmployeeModalShow}
            >
              Add Employee
            </Button>
          </div>
        </div>

        <Table
          hover
          responsive
          className="dashboard-table dashboard-table-bordred"
        >
          <thead>
            <tr>
              <th>Name</th>
              <th>Email</th>
              <th>Phone</th>
              <th>Employee type</th>
              <th>Action</th>
            </tr>
          </thead>

          <tbody>
            {isFetchingEmployees ? (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            ) : (
              listOfEmployees.map((employeeDetails: IEmployeeDetails) => {
                return (
                  <tr key={employeeDetails.id}>
                    <td>{employeeDetails.name}</td>
                    <td>{employeeDetails.email}</td>
                    <td>{employeeDetails.phone}</td>
                    <td>{employeeDetails.position}</td>
                    <td>
                      <div className="btn-actions">
                        <Button
                          variant="white"
                          onClick={() =>
                            handleEmployeeInfoModalShow(employeeDetails.id)
                          }
                        >
                          <span className="icon-view fs-4"></span>
                        </Button>

                        <Button
                          variant="white"
                          className="me-2 ms-2"
                          onClick={() => {
                            setSelectedEmployeeDetail(employeeDetails);
                            handleAddEmployeeModalShow();
                          }}
                        >
                          <span className="icon-edit fs-4"></span>
                        </Button>
                        <Button
                          variant="white"
                          onClick={() =>
                            handleDeleteEmployeeModalShow(employeeDetails.id)
                          }
                        >
                          <span className="icon-trash fs-4"></span>
                        </Button>
                      </div>
                    </td>
                  </tr>
                );
              })
            )}
          </tbody>
        </Table>
      </div>
      {showDetailsForEmployeeById !== undefined && (
        <EmployeeInfoModal
          showEmployeeInfoModal={showDetailsForEmployeeById !== undefined}
          handleEmployeeInfoModalClose={handleEmployeeInfoModalClose}
          userId={showDetailsForEmployeeById}
        />
      )}
      {showDeleteForEmployeeId !== undefined && (
        <EmployeeDeleteModal
          showEmployeeDeleteModal={showDeleteForEmployeeId !== undefined}
          handleEmployeeDeleteModalClose={handleDeleteEmployeeModalClose}
          userId={showDeleteForEmployeeId}
        />
      )}
      {/* Explore Membership */}
      <AddEmployeeModal
        showAddEmployeeModal={showAddEmployeeModal}
        employeeDetails={selectedEmployeeDetail}
        handleAddEmployeeModalClose={handleAddEmployeeModalClose}
      />
    </>
  );
}

export default Employee;
