import {
  Button,
  Card,
  Col,
  Container,
  Form,
  InputGroup,
  Row,
} from "react-bootstrap";
import { BsArrowLeft, BsCalendar, BsClock } from "react-icons/bs";
import { FaStar } from "react-icons/fa";
import "../../styles/header.scss";
import "../../styles/workplace.scss";

const Workplace = () => {
  return (
    <>
      <section>
        <Container>
          <Row className="justify-content-center">
            <Col md={8}>
              <Card className="p-4">
                <div className="d-flex justify-content-start">
                  <Button variant="white" className="custom-button text-dark">
                    <BsArrowLeft className="me-2" />
                    Back
                  </Button>
                </div>
                <div className="mt-3 text-dark">
                  <h3>
                    <b>Selected Space</b>
                  </h3>
                </div>
                <hr />
                <Card className="mt-3 border-0">
                  <Row>
                    <Col md={4}>
                      <Card.Img
                        variant="top"
                        src="https://images.pexels.com/photos/1595385/pexels-photo-1595385.jpeg?auto=compress&cs=tinysrgb&w=600"
                      />
                    </Col>
                    <Col md={8} className="mt-3">
                      <div className="mt-4">
                        <h4>
                          <b>Carrer de Pallars, 194</b>
                        </h4>
                        <div className="text-secondary mt-2">
                          Barclona Barclohna 0895
                        </div>
                      </div>
                      <div className="d-flex align-items-center mt-3">
                        <Button
                          variant="warning"
                          size="sm"
                          className="d-flex align-items-center me-2"
                        >
                          <FaStar className="text-white" />
                          <span className="text-white ms-1">4.5</span>
                        </Button>
                        <span className="text-secondary">(12k Ratings)</span>
                      </div>
                    </Col>
                  </Row>
                </Card>
                <Form className="mt-5">
                  <Row className="mb-4">
                    <Col md={6}>
                      <Form.Group controlId="availableSpace">
                        <Form.Label>Available Space</Form.Label>
                        <Form.Select
                          className="text-muted"
                          aria-label="Select available space"
                        >
                          <option>Select space</option>
                          <option value="1">Option 1</option>
                          <option value="2">Option 2</option>
                        </Form.Select>
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group controlId="person">
                        <Form.Label>Person</Form.Label>
                        <Form.Select
                          className="text-muted"
                          aria-label="Select person"
                        >
                          <option>Select person</option>
                          <option value="1">Option 1</option>
                          <option value="2">Option 2</option>
                        </Form.Select>
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row className="mt-4">
                    <Col md={6}>
                      <Form.Group controlId="date">
                        <Form.Label> Select Date & Time </Form.Label>
                        <InputGroup>
                          <Form.Control
                            placeholder="Select date"
                            className="input-with-icon"
                          />
                          <InputGroup.Text className="icon-group">
                            <BsCalendar />
                          </InputGroup.Text>
                        </InputGroup>
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group controlId="time">
                        <Form.Label>&nbsp;</Form.Label>
                        <InputGroup>
                          <Form.Control
                            placeholder="Select Time"
                            className="input-with-icon"
                          />
                          <InputGroup.Text className="icon-group">
                            <BsClock />
                          </InputGroup.Text>
                        </InputGroup>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Form.Group controlId="specialRequest" className="mt-4">
                    <Form.Label>Special Request</Form.Label>
                    <Form.Control
                      as="textarea"
                      placeholder="Add notes here"
                      rows={3}
                      style={{ height: "150px" }}
                    />
                  </Form.Group>
                  <Button className="mt-4" variant="primary" type="submit">
                    Submit
                  </Button>
                </Form>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Workplace;
