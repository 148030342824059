import { AxiosError, AxiosResponse } from "axios";
import { create } from "zustand";
import { devtools } from "zustand/middleware";
import axiosInstance from "../../helpers/axios/axios.config";
import { IExploreMemberShipResponseData } from "../../interface/exploreMembership/exploreMembership.interface";

export const useExploreMembershipStore = create<IExploreMembershipStore>()(
  devtools(
    (set, get) => ({
      exploreMembershipList: [],
      isFetchingExploreMembership: false,
      noExploreMembership: true,
      fetchExploreMembershipError: undefined,
      fetchExploreMembershipList: async () => {
        try {
          set({
            isFetchingExploreMembership: true,
            noExploreMembership: false,
            fetchExploreMembershipError: undefined,
          });
          const usersListResponse: AxiosResponse<
            IExploreMemberShipResponseData[]
          > = await axiosInstance.get("/explore_memberships");
          set({
            exploreMembershipList: usersListResponse.data,
            isFetchingExploreMembership: false,
            noExploreMembership: usersListResponse.data.length === 0,
            fetchExploreMembershipError: undefined,
          });
        } catch (error) {
          let message: string = "Something went wrong";
          if (error instanceof AxiosError) {
            message = ((error as AxiosError)?.response?.data as any)
              ?.message as string;
          }
          set({
            isFetchingExploreMembership: false,
            noExploreMembership: false,
            fetchExploreMembershipError: message,
          });
        }
      },
    }),
    {
      name: "explore_membership_list_store",
      anonymousActionType: "explore_membership_actions",
    }
  )
);

export interface IExploreMembershipStore {
  exploreMembershipList: IExploreMemberShipResponseData[];
  isFetchingExploreMembership: boolean;
  noExploreMembership: boolean;
  fetchExploreMembershipError?: string;
  fetchExploreMembershipList: () => void;
}
