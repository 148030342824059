import { Button, Card, Col, Container, Row } from "react-bootstrap";
import { BsArrowLeft } from "react-icons/bs";
import { FaStar } from "react-icons/fa";
import "../../styles/orderconfirmation.scss";

const OrderConfirmation = () => {
  return (
    <section>
      <Container>
        <Row>
          <Col xs={12} md={10} lg={8}>
            <div className="mt-4">
              <Button variant="white" className="custom-button text-dark">
                <BsArrowLeft className="me-2" />
                Back
              </Button>
            </div>
            <div className="mt-3 text-dark">
              <h3>
                <b>Order Confirmation</b>
              </h3>
              <p className="text-secondary">
                Lorem ipsum, dolor sit amet consectetur.
              </p>
            </div>
            <hr />
            <div className="extra-height order-info">
              <Col md={4}>
                <Card.Img
                  variant="top"
                  src="https://images.pexels.com/photos/1595385/pexels-photo-1595385.jpeg?auto=compress&cs=tinysrgb&w=600"
                />
              </Col>
              <Col md={8} className="mt-2 ps-3">
                <h4 className="text-dark">
                  <b>Carrer de Pallars, 194</b>
                </h4>
                <div className="text-secondary mt-2">
                  Barclona Barclohna 0895
                </div>
                <div className="d-flex align-items-center mt-2">
                  <Button
                    variant="warning"
                    size="sm"
                    className="d-flex align-items-center me-2"
                  >
                    <FaStar className="text-white" />
                    <span className="text-white ms-1">4.5</span>
                  </Button>
                  <span className="text-secondary">(12k Ratings)</span>
                </div>
                <div className="d-flex align-items-center mt-2">
                  <h4 className="text-dark mt-2">
                    <b>19.00 USD</b>
                  </h4>
                  {/* <Button variant="link" className="custom-margin-left"> 
                                        <FaTrash className="text-danger" />
                                    </Button> */}
                </div>
              </Col>
            </div>
          </Col>
          <Col xs={12} md={10} lg={4}>
            <div className="border p-4 order-summary">
              <h3 className="text-dark">Order Summary</h3>
              <p className="text-muted mb-4">Barcelona, Barcelona 08005</p>
              <hr />
              <div className="d-flex justify-content-between mt-4">
                <span className="order-title">Amount</span>
                <span className="order-amount">$ 19.00</span>
              </div>
              <div className="d-flex justify-content-between mt-4">
                <span className="order-title">Tax</span>
                <span className="order-amount">$ 2.00</span>
              </div>
              <div className="border-dashed mt-4 mb-4"></div>
              <div className="d-flex justify-content-between">
                <span>
                  <h4 className="text-dark order-total">Order Total</h4>
                </span>
                <span className="order-amount">$ 21.00</span>
              </div>
              <div className="mt-5  text-center">
                <Button variant="white" className="dashed-btn">
                  Add coupon code here
                </Button>
              </div>
              <div className="d-grid gap-2 mt-5 mb-3">
                <Button variant="primary" size="lg">
                  Checkout
                </Button>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default OrderConfirmation;
