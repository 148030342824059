import React, { useState, useCallback } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { Formik, FormikHelpers, FormikProps, useFormik } from "formik";
import { ICountryDetail } from "../../../../../../interface/config/config.interface";
import { useConfigStore } from "../../../../../../zustand-store/config/config.store";
import ActionButtons from "../../../../profile/business-profile/tabs/action-buttons";
import axiosInstance from "../../../../../../helpers/axios/axios.config";
import { toast, TypeOptions, ToastOptions } from "react-toastify";
import { UserAccountFormValidation } from "../../../../../../validation/createAccount/createUserAccount.validation";
import { AxiosError } from "axios";
import ErrorMsg from "../../../../../../components/common/ErrorMsg";
import { useNavigate } from "react-router-dom";
import { AuthenticatedRoutesEnums } from "../../../../../../constants/routes.constants";

interface IAddEdituserFormParams {
  id: number;
  name: string;
  email: string;
  phone: string;
  country_id: string | number;
  key: number;
  user_type: string;

}

const initialValues: IAddEdituserFormParams = {
  id: 0,
  name: "",
  email: "",
  phone: "",
  country_id: "",
  key: 0,
  user_type: "",
};

const AddEditUser: React.FunctionComponent<any> = ({
  editUser,
  disabledUserForm,
}) => {
  const [userValues, setUserValues] = useState<
    Partial<IAddEdituserFormParams>
  >({});
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [formError, setFormError] = useState({
    email: "",
    name: "",
    country_id: "",
  });
  const { countryList } = useConfigStore();
  const navigate = useNavigate()

  const onSubmitWorkspaceDetails = async (
    values: IAddEdituserFormParams,
    formikHelpers: FormikHelpers<IAddEdituserFormParams>
  ) => {
    try {
      setIsLoading(true);
      formikHelpers.setSubmitting(true);
      const params = {
        name: values.name,
        email: values.email,
        phone: values.phone,
        country_id: values.country_id,
        key: values.key,
        user_type: values.user_type
      };
      let message = "";
      if (values.id) {
        await axiosInstance.patch(`/users/${values.id}`, {
          user: params,
        });
        message = "User updated successfully"
        navigate(AuthenticatedRoutesEnums.DASHBOARD)
      } else {
        await axiosInstance.post("/users", {
          user: params,
        });
        message = "User created successfully and sent mail"
        formikHelpers.resetForm();
      }
      toast(message, {
        type: "success" as TypeOptions,
      } as ToastOptions);
      formikHelpers.setSubmitting(false);
    } catch (error) {
      if (error instanceof AxiosError) {
        const responseData = ((error as AxiosError)?.response?.data as any)
        if (responseData) {
          const errors = ErrorMsg(responseData);
          setFormError((formError) => ({
            ...formError,
            email: errors.email,
            name: errors.name,
            country_id: errors.country_id,
          }));
        }
      }
      formikHelpers.setSubmitting(false);
      toast("Something went wrong, Please try again later.", {
        type: "error" as TypeOptions,
      } as ToastOptions);
    } finally {
      setIsLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: editUser ? editUser : initialValues,
    validateOnChange: false,
    validateOnBlur: false,
    enableReinitialize: true,
    onSubmit: onSubmitWorkspaceDetails,
    validationSchema: UserAccountFormValidation,
  });

  const {
    values,
    errors,
    touched,
    handleSubmit,
    handleChange,
    handleBlur,
    setFieldValue,
    validateField,
  } = formik;

  const handleInputChange = useCallback(
    async (e: React.ChangeEvent<any>, fieldName: string) => {
      setUserValues((prev) => ({
        ...prev,
        [fieldName]: e.target.value,
      }));
      await handleChange(e);
      validateField(fieldName);
    },
    [handleChange, validateField]
  );


  return (
    <div className="form-common-box rounded-0">
      <h2 className="myprofile-title text-dark">
        <span className="icon-briefcase-outline pe-2"></span>
        {editUser && editUser.id ? "Update user" : "Add user"}
      </h2>
      {/* <Formik
        initialValues={editUser ? editUser : initialValues}
        onSubmit={onSubmitWorkspaceDetails}
        validationSchema={UserAccountFormValidation}
        enableReinitialize
        validateOnChange={true}
      >
        {({
          values,
          errors,
          touched,
          handleSubmit,
          handleChange,
        }: FormikProps<IAddEdituserFormParams>) => {
          return ( */}
      <Form onSubmit={formik.handleSubmit} autoComplete="off">
        <Row>
          <Col md={12} lg={12}>
            <Form.Group
              className="mb-4 position-relative"
              controlId="uploadDetails.name"
            >
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                name="name"
                placeholder="Enter User Name"
                className={`${errors.name ? "error" : ""}`}
                value={values?.name}
                onBlur={handleBlur}
                onChange={(e) => handleInputChange(e, "name")}
                isValid={touched.name && !errors.name && !!values.name}
                isInvalid={!!errors.name}
              />
              <Form.Control.Feedback
                type="invalid"
                className="d-block"
              >
                {errors.name && touched.name && (
                  <p>{errors.name}</p>
                )}
                {formError.name}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md={12} lg={12}>
            <Form.Group
              className="mb-4 position-relative"
              controlId="uploadDetails.email"
            >
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="text"
                name="email"
                placeholder="supercowork@mail.com"
                className={`${errors.email ? "error" : ""}`}
                value={values?.email}
                onBlur={handleBlur}
                onChange={(e) => handleInputChange(e, "email")}
                isValid={touched.email && !errors.email && !!values.email}
                isInvalid={!!errors.email}
              />
              <Form.Control.Feedback
                type="invalid"
                className="d-block"
              >
                {errors.email && touched.email && (
                  <p>{errors.email}</p>
                )}
                {formError.email}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group
              className="mb-4 position-relative"
              controlId="uploadDetails.country_id"
            >
              <Form.Label>Country</Form.Label>
              <Form.Control
                as="select"
                type="select"
                name="country_id"
                className={`${errors.country_id ? "error" : ""}`}
                value={values.country_id}
                onBlur={handleBlur}
                onChange={(e) => handleInputChange(e, "country_id")}
                isValid={
                  touched.country_id &&
                  !errors.country_id &&
                  !!values.country_id
                }
                isInvalid={!!errors.country_id}
              >
                <option value="" label="Select your country" />
                {countryList
                  ? countryList.map((country: ICountryDetail) => {
                    const { id, name, emoji } = country ?? {};

                    return (
                      <option
                        key={id}
                        value={id}
                        label={`${name} ${emoji}`}
                      />
                    );
                  })
                  : null}
              </Form.Control>
              <Form.Control.Feedback
                type="invalid"
                className="d-block"
              >
                {errors.country_id && touched.country_id && (
                  <p>{errors.country_id}</p>
                )}
                {formError.country_id}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group
              className="mb-4 position-relative"
              controlId="uploadDetails.phone_no"
            >
              <Form.Label>Phone</Form.Label>
              <Form.Control
                type="number"
                name="phone"
                placeholder="999XXXXXXX"
                className={`${errors.phone ? "error" : ""}`}
                value={values?.phone}
                onBlur={handleBlur}
                onChange={(e) => handleInputChange(e, "phone")}
                isValid={
                  touched.phone && !errors.phone && !!values.phone
                }
                isInvalid={!!errors.phone}
              />
              {errors.phone && touched.phone && (
                <Form.Control.Feedback type="invalid" className="d-block">
                  {errors.phone}
                </Form.Control.Feedback>
              )}
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group
              className="mb-4 position-relative"
              controlId="uploadDetails.key"
            >
              <Form.Label>Key</Form.Label>
              <Form.Control
                type="number"
                name="key"
                placeholder="XXXXXXX"
                className={`${errors.key ? "error" : ""}`}
                value={values?.key}
                onChange={handleChange}
              />
              {errors.key && touched.key && (
                <Form.Control.Feedback type="invalid" className="d-block">
                  {errors.key}
                </Form.Control.Feedback>
              )}
            </Form.Group>
          </Col>
          <Col md={6} >
            <Form.Group
              className="mb-4 position-relative"
              controlId="uploadDetails.user_type"
            >
              <Form.Label>User Type</Form.Label>
              <Form.Select
                as="select"
                name="user_type"
                onChange={handleChange}
                className={`${errors.user_type ? "error" : ""}`}
                value={values.user_type}
              >
                <option value="" label="Select user type" />
                <option value="business">Business</option>
                <option value="individual">Individual</option>
                <option value="cowork_admin">Cowork Admin</option>
              </Form.Select>
              {errors.user_type && (
                <Form.Control.Feedback type="invalid" className="d-block">
                  {errors.user_type}
                </Form.Control.Feedback>
              )}
            </Form.Group>
          </Col>
          <Col>
            <ActionButtons
              action_id={values.id}
              handleSubmit={handleSubmit}
              isLoading={isLoading}
            // onClick={() => navigate(AuthenticatedRoutesEnums.DASHBOARD)}
            />
          </Col>
        </Row>
      </Form>
      {/* );
        }}
      </Formik> */}
    </div>
  );
};
export default AddEditUser;
