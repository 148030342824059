import { AxiosError, AxiosResponse } from "axios";
import { create } from "zustand";
import { devtools } from "zustand/middleware";
import axiosInstance from "../../helpers/axios/axios.config";
import {
  IGetUsersListResponse,
  IUserDetail,
} from "../../interface/users/users.interface";
import { error } from "console";

export const useUserStore = create<IUserStore>()(
  devtools(
    (set, get) => ({
      users: [],
      isFetchingUsers: false,
      deletingUser: false,
      deleteUserError: undefined,
      noUsersFound: true,
      fetchUsersError: undefined,
      fetchUsers: (search?: string, user_type?: string, status?: number) => {

        const params = Object.assign(
          {},
          search ? { search: search } : {},
          user_type ? { user_type: user_type } : {},
          status ? { status: status } : {}
        );
        set({
          isFetchingUsers: true,
          noUsersFound: false,
          fetchUsersError: undefined,
        });
        axiosInstance
          .get<AxiosResponse<IGetUsersListResponse>>("/users", {
            params: params,
          })
          .then((response) => {
            set({
              users: response.data.users,
              isFetchingUsers: false,
              noUsersFound: response.data.users.length === 0,
              fetchUsersError: undefined,
            });
          })
          .catch((error) => {
            let message: string = "Something went wrong";
            if (error instanceof AxiosError) {
              message = ((error as AxiosError)?.response?.data as any)
                ?.message as string;
            }
            set({
              isFetchingUsers: false,
              noUsersFound: false,
              fetchUsersError: message,
            });
          });
      },
      deleteUser: async (id: number, handleDeleteUserModalClose) => {
        try {
          set({
            deletingUser: true,
            deleteUserError: undefined,
          });
          await axiosInstance.delete("/users/" + id);
          const { users } = get();
          // const updatedUsersList = users
          // .filter(
          //   (user: IUserDetail) => user.id !== id
          // );
          set({
            users: users,
            noUsersFound: users.length === 0,
            deletingUser: false,
            deleteUserError: undefined,
          });
          handleDeleteUserModalClose && handleDeleteUserModalClose();
        } catch (error) {
          let message: string = "Something went wrong";
          if (error instanceof AxiosError) {
            message = ((error as AxiosError)?.response?.data as any)
              ?.message as string;
          }
          set({
            deletingUser: false,
            deleteUserError: message,
          });
        }
      },
    }),
    {
      name: "user_store",
      anonymousActionType: "user_actions",
    }
  )
);

export interface IUserStore {
  users: IUserDetail[];
  isFetchingUsers: boolean;
  noUsersFound: boolean;
  fetchUsersError?: string;
  fetchUsers: (search?: string, user_type?: string, status?: number) => void;
  deletingUser: boolean;
  deleteUserError?: string;
  deleteUser: (id: number, handleDeleteUserModalClose?: () => void) => void;
}
