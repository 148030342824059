import { Col, Container, Row } from "react-bootstrap";
import { LuCheckCircle } from "react-icons/lu";
import "../../../styles/header.scss";

import * as React from "react";

interface IAboutUSProps {}

const AboutUS: React.FunctionComponent<IAboutUSProps> = (props) => {
  return (
    <>
      <section>
        <Container>
          <Row>
            <Col md={12} lg={6}>
              <p className="common-para">
                "In the modern office, connections are the currency of success,
                and collaboration is the key to unlocking limitless potential."
              </p>
              <h1 className="section-title">
                Why <span className="text-primary"> Businesspass? </span>
              </h1>

              <p className="common-para mb-3">
              As companies deeply committed to nurturing their global network of exceptional talent, we understand the paramount importance of facilitating connections, fostering collaboration, and empowering its explorations to thrive worldwide. For companies as such global business isn't just a convenience—it's an indispensable tool for cultivating innovation, strengthening partnerships, and achieving unparalleled success.
              </p>
              <p className="common-para mb-3">
              In today's interconnected business landscape, esteemed partners are scattered across the globe, each bringing their unique expertise and insights to the table. The global business serves as a vital conduit for team members to engage with essential partners, attend industry events, and cultivate relationships with fellow professionals, all of which are essential for driving growth and staying ahead of the curve.
              </p>
              <p className="common-para mb-3">
              That's why we place immense value on the concept of the business pass. Far more than just a credential, it represents a gateway to opportunity and a testament to our unwavering commitment to empowering businesses. With the business pass, get the freedom to traverse continents, seize new prospects, and collaborate with colleagues from diverse backgrounds, all while knowing they have the support and resources of your company behind them.
              </p>
              <p className="common-para mb-3">
              In the fast-paced world of modern business, success hinges on the ability to forge meaningful connections and leverage collective expertise.
              </p>
              
            </Col>

            <Col md={12} lg={6}>
              <img
                src={require("../../../images/about-1.png")}
                alt="hero banner"
                className="mobile-img-padding-top"
              />
              <Row className="mt-4">
                <Col md={6}>
                  <ul className="list-unstyled amenities-list">
                    <li>
                      <LuCheckCircle fontSize={25} className="icons" /> 
                      Global Accessibility
                    </li>
                    <li>
                      <LuCheckCircle fontSize={25} className="icons" />{" "}
                      Tailored Safety Measures
                    </li>
                    <li>
                      <LuCheckCircle fontSize={25} className="icons" /> 
                      Priority Services and Amenities
                    </li>
                    <li>
                      <LuCheckCircle fontSize={25} className="icons" /> Data Security 
                    </li>
                  </ul>
                </Col>
                <Col md={6}>
                  <ul className="list-unstyled amenities-list">
                    <li>
                      <LuCheckCircle fontSize={25} className="icons" /> Cost-Effectiveness
                    </li>
                    <li>
                      <LuCheckCircle fontSize={25} className="icons" />{" "}
                      Private Rooms
                    </li>
                    <li>
                      <LuCheckCircle fontSize={25} className="icons" /> Streamlined Booking Process
                    </li>
                    <li>
                      <LuCheckCircle fontSize={25} className="icons" /> Priority service request 
                    </li>
                  </ul>
                </Col>
              </Row>

            </Col>
          </Row>
        </Container>
      </section>
      {/* <div className="common-padding">
        <Container>
          <h1 className="section-title text-center">
            Meet our <span className="text-primary">Team Member</span>
          </h1>
          <p className="common-para text-center mb-4">
            Our founding team has very deep expertise
          </p>
          <div className="row text-center">
            <div className="col-xl-3 col-sm-6">
              <div className="bg-white team-member-card">
                <img
                  src={require("../../images/team-1.png")}
                  alt="team banner"
                  className="w-100"
                />
                <div className="team-member-card-content">
                  <h5>Dianne Russell</h5>
                  <span className="small text-uppercase text-muted">
                    Founder & CEO
                  </span>
                  <ul className="social mb-0 list-inline mt-3">
                    <li className="list-inline-item">
                      <a href="#" className="social-link">
                        <FaLinkedinIn />
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a href="#" className="social-link">
                        <FaFacebookF />
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a href="#" className="social-link">
                        <RiTwitterXLine />
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a href="#" className="social-link">
                        <RiGithubLine />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="col-xl-3 col-sm-6">
              <div className="bg-white team-member-card">
                <img
                  src={require("../../images/team-2.png")}
                  alt="team banner"
                  className="w-100"
                />
                <div className="team-member-card-content">
                  <h5>Kathryn Murphy</h5>
                  <span className="small text-uppercase text-muted">
                    Medical Assistant
                  </span>
                  <ul className="social mb-0 list-inline mt-3">
                    <li className="list-inline-item">
                      <a href="#" className="social-link">
                        <FaLinkedinIn />
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a href="#" className="social-link">
                        <FaFacebookF />
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a href="#" className="social-link">
                        <RiTwitterXLine />
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a href="#" className="social-link">
                        <RiGithubLine />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="col-xl-3 col-sm-6">
              <div className="bg-white team-member-card">
                <img
                  src={require("../../images/team-3.png")}
                  alt="team banner"
                  className="w-100"
                />
                <div className="team-member-card-content">
                  <h5>Courtney Henry</h5>
                  <span className="small text-uppercase text-muted">
                    Marketing Coordinator
                  </span>
                  <ul className="social mb-0 list-inline mt-3">
                    <li className="list-inline-item">
                      <a href="#" className="social-link">
                        <FaLinkedinIn />
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a href="#" className="social-link">
                        <FaFacebookF />
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a href="#" className="social-link">
                        <RiTwitterXLine />
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a href="#" className="social-link">
                        <RiGithubLine />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="col-xl-3 col-sm-6">
              <div className="bg-white team-member-card">
                <img
                  src={require("../../images/team-4.png")}
                  alt="team banner"
                  className="w-100"
                />
                <div className="team-member-card-content">
                  <h5>Ralph Edwards</h5>
                  <span className="small text-uppercase text-muted">
                    Web Designer
                  </span>
                  <ul className="social mb-0 list-inline mt-3">
                    <li className="list-inline-item">
                      <a href="#" className="social-link">
                        <FaLinkedinIn />
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a href="#" className="social-link">
                        <FaFacebookF />
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a href="#" className="social-link">
                        <RiTwitterXLine />
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a href="#" className="social-link">
                        <RiGithubLine />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div> */}
    </>
  );
};

export default AboutUS;
