// App.tsx

import React, { useCallback, useEffect, useState } from "react";
import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
} from "react-router-dom";
import {
  AuthenticatedRoutesEnums,
  UnAuthenticatedRoutesEnums,
} from "../constants/routes.constants";
import MainLayout from "../layout/MainLayout";
import BusinessProfile from "../pages/authenticated-pages/profile/business-profile";
import ProfilePage from "../pages/authenticated-pages/profile/personal-profile";
import HomeDahsboard from "../pages/authenticated-pages/super-admin/dashboard/dashboard";
import { NotFoundPage } from "../pages/notFound";
import { SpinnerLoadingPage } from "../pages/spinner";
import AboutUs from "../pages/unauthenticated-pages/aboutus";
import ForBusiness from "../pages/unauthenticated-pages/business";
import ForWorkspace from "../pages/unauthenticated-pages/forworkspace";
import ForCustomer from "../pages/unauthenticated-pages/forworkspace/customers";
import ForEmployee from "../pages/unauthenticated-pages/forworkspace/employees";
import Home from "../pages/unauthenticated-pages/home";
import CreatePassword from "../pages/unauthenticated-pages/login/create-password";
// import CreateProfile from "../pages/unauthenticated-pages/login/create-profile";
import UserInformation from "../pages/unauthenticated-pages/login/user-information";
import VerifyUserToken from "../pages/unauthenticated-pages/login/verify-user";
import WhatWillBeUsed from "../pages/unauthenticated-pages/login/WhatWillBeUsed";
import { useAuthStore } from "../zustand-store/auth/auth.store";
import PassAccessPage from "../pages/PassAccess";
import UpdateProfile from "../pages/PassAccess/update-profile";
import WorkspacesListPage from "../pages/workspaces";
import WorkspaceDetails from "../pages/WorkspaceDetails";
import Workplace from "../pages/workplace";
import OrderConfirmation from "../pages/orderConfirmation";
import PaymentDone from "../pages/payment-done/PaymentDone";
import ThankYou from "../pages/thankyou/ThankYou";
import AttendeesUserInfo from "../pages/attendees-user/AttendeesUserInfo";
import AttendeesFileUpload from "../pages/attendees-user/AttendeesFileUpload";
import UserRedeem from "../pages/user-redeem/UserRedeem";
import SpaceInformation from "../pages/authenticated-pages/profile/business-profile/tabs/space-information";
import Employee from "../pages/authenticated-pages/profile/business-profile/tabs/employees";
import CustomersList from "../pages/authenticated-pages/profile/business-profile/tabs/customers";
import UpdatePassword from "../pages/authenticated-pages/profile/common/update-password";
import DeleteAccount from "../pages/authenticated-pages/profile/common/delete-account";
import ErrorBoundary from "../components/Error/ErrorBoundary";
import Communities from '../pages/authenticated-pages/super-admin/dashboard/communities/index';
import WorkspacesList from '../pages/authenticated-pages/super-admin/dashboard/workspaces/index';
import ActivateAccountsList from "../pages/authenticated-pages/super-admin/dashboard/activate-accounts";
import ExploreMembershipsList from "../pages/authenticated-pages/super-admin/dashboard/explore-memberships";

const App: React.FC = () => {
  const { loggedInUserDetails } = useAuthStore();
  // const { id } = loggedInUserDetails ?? {};
  // const [isSignedIn, setIsSignedIn] = useState<boolean>(false);

  // const checkUserSignIn = useCallback((): void => {
  //   if (id) {
  //     setIsSignedIn(true);
  //   } else {
  //     setIsSignedIn(false);
  //   }
  // }, [id]);

  // useEffect(() => {
  //   checkUserSignIn();
  // }, [checkUserSignIn]);

  const router = createBrowserRouter([
    {
      element: <MainLayout />,
      errorElement: <ErrorBoundary />,
      children: loggedInUserDetails
        ? [
            // authenticated routes
            {
              path: AuthenticatedRoutesEnums.DASHBOARD,
              element: <HomeDahsboard />,
              children: [ 
                {
                  path: AuthenticatedRoutesEnums.DASHBOARD_USERS,
                  element: <HomeDahsboard />,
                },
                {
                  path: AuthenticatedRoutesEnums.DASHBOARD_ACTIVATE_ACCOUNT,
                  element: <ActivateAccountsList />,
                },
                {
                  path: AuthenticatedRoutesEnums.DASHBOARD_EXPLORE_MEMBERSHIPS,
                  element: <ExploreMembershipsList />
                },
                {
                  path: AuthenticatedRoutesEnums.DASHBOARD_COMMUNITIES,
                  element: <Communities />,
                },
                {
                  path: AuthenticatedRoutesEnums.DASHBOARD_WORKSPACES,
                  element: <WorkspacesList />,
                },
              ]
            },   
            {
              path: AuthenticatedRoutesEnums.PROFILE,
              element: <ProfilePage />,
            },
            {
              path: AuthenticatedRoutesEnums.UPDATE_PROFILE,
              element: <UpdateProfile />,
            },
            {
              path: AuthenticatedRoutesEnums.LOADER,
              element: <SpinnerLoadingPage />,
            },
            {
              path: AuthenticatedRoutesEnums.PASS_ACCESS,
              element: <PassAccessPage />,
            },
            {
              path: AuthenticatedRoutesEnums.WORKSPACES,
              element: <WorkspacesListPage />,
            },
            {
              path: AuthenticatedRoutesEnums.WORKSPACE_DETAILS,
              element: <WorkspaceDetails />,
            },
            {
              path: AuthenticatedRoutesEnums.BUSINESS_PROFILE,
              element: <BusinessProfile />,
              children: [
                {
                  path: AuthenticatedRoutesEnums.BUSINESS_PROFILE_WORKSPACES,
                  element: <SpaceInformation />,
                },
                {
                  path: AuthenticatedRoutesEnums.BUSINESS_PROFILE_EMPLOYEES,
                  element: <Employee />,
                },
                {
                  path: AuthenticatedRoutesEnums.BUSINESS_PROFILE_CUSTOMERS,
                  element: <CustomersList />,
                },
                {
                  path: AuthenticatedRoutesEnums.BUSINESS_PROFILE_UPDATE_PASSWORD,
                  element: <UpdatePassword />,
                },
                {
                  path: AuthenticatedRoutesEnums.BUSINESS_PROFILE_DELETE_ACCOUNT,
                  element: <DeleteAccount />,
                },
              ],
            },
            {
              path: "*",
              element: (
                <Navigate
                  to={{ pathname: AuthenticatedRoutesEnums.PASS_ACCESS }}
                />
              ),
            },
          ]
        : [
            // unauthenticated routes
            { path: UnAuthenticatedRoutesEnums.HOME, element: <Home /> },
            { path: UnAuthenticatedRoutesEnums.ABOUT_US, element: <AboutUs /> },

            {
              path: UnAuthenticatedRoutesEnums.BUSINESS,
              element: <ForBusiness />,
            },
            {
              path: UnAuthenticatedRoutesEnums.WORKSPACE,
              element: <ForWorkspace />,
            },
            {
              path: UnAuthenticatedRoutesEnums.CUSTOMER,
              element: <ForCustomer />,
            },
            {
              path: UnAuthenticatedRoutesEnums.EMPLOYEE,
              element: <ForEmployee />,
            },
            {
              path: UnAuthenticatedRoutesEnums.VERIFY_TOKEN,
              element: <VerifyUserToken />,
            },
            {
              path: UnAuthenticatedRoutesEnums.USER_INFO,
              element: <UserInformation />,
            },
            {
              path: UnAuthenticatedRoutesEnums.CREATE_PASSWORD,
              element: <CreatePassword />,
            },
            {
              path: UnAuthenticatedRoutesEnums.WILL_BE_USED_FOR,
              element: <WhatWillBeUsed />,
            },
            {
              path: UnAuthenticatedRoutesEnums.WORKSPACE_SELECT,
              element: <Workplace />,
            },
            {
              path: UnAuthenticatedRoutesEnums.ORDER_CONFIRMATION,
              element: <OrderConfirmation />,
            },
            {
              path: UnAuthenticatedRoutesEnums.PAYMENTDONE,
              element: <PaymentDone />,
            },
            {
              path: UnAuthenticatedRoutesEnums.THANKYOU,
              element: <ThankYou />,
            },
            {
              path: UnAuthenticatedRoutesEnums.ATTENDEESUSER,
              element: <AttendeesUserInfo />,
            },
            {
              path: UnAuthenticatedRoutesEnums.ATTENDEESFILEUPLOAD,
              element: <AttendeesFileUpload />,
            },
            {
              path: UnAuthenticatedRoutesEnums.USERREDEEM,
              element: <UserRedeem />,
            },
            {
              path: UnAuthenticatedRoutesEnums.WILDCARD,
              element: (
                <Navigate
                  to={{ pathname: UnAuthenticatedRoutesEnums.NOT_FOUND }}
                />
              ),
            },
            {
              path: UnAuthenticatedRoutesEnums.NOT_FOUND,
              element: <NotFoundPage />,
            },
          ],
    },
  ]);

  return <RouterProvider router={router} />;
};

export default App;
