import { Button, Card, Col, Container, Form, Row } from "react-bootstrap";
import "../../styles/footer.scss";
import "../../styles/searching.scss";
import MapContainer from "../MapContainer";
import { useWorkspaceStore } from "../../zustand-store/workspace/workspace.store";
import { useEffect, useState, useMemo, useRef, useCallback } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { AuthenticatedRoutesEnums } from "../../constants/routes.constants";
import {
  ITeamRangeDetail,
  ISpaceTypeDetail,
} from "../../interface/workspace/workspace.interface";
import { useConfigStore } from "../../zustand-store/config/config.store";
import { ICountryDetail } from "../../interface/config/config.interface";
import { debounce, replace } from "lodash";

export default function WorkspacesListPage() {
  const searchTextInputRef = useRef<HTMLInputElement>(null);
  const {
    isFetchingWorkspaces,
    noWorkspacesFound,
    fetchWorkspaceList,
    fetchTeamRangeList,
    fetchingTeamRangeList,
    teamRangeList,
    spaceTypeList,
    fetchSpaceTypeList,
    workspaceList,
  } = useWorkspaceStore();
  const { countryList, fetchingCountryList } = useConfigStore();

  const [searchText, setSearchText] = useState<string>("");
  const [spaceType, setSpaceType] = useState(undefined);
  const [team_range, setTeamRange] = useState(undefined);
  const [latitude, setLatitude] = useState<string>("");
  const [longitude, setLongitude] = useState<string>("");
  const [selectedCountryId, setSelectedCountryId] = useState<number>();
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const selectedParamsCountryId = searchParams.get("co");

  const [selectedCountry, setSelectedCountry] =
    useState<ICountryDetail | null>();
  const [displayedCountry, setDisplayedCountry] =
    useState<ICountryDetail | null>();

  const onSearchCountry = useCallback(() => {
    if (selectedCountry) {
      navigate(
        `${AuthenticatedRoutesEnums.WORKSPACES}?co=${selectedCountry.id}`
      );
      setDisplayedCountry(selectedCountry);
    }
  }, [navigate, selectedCountry]);

  const handleCountryChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const countryId = event.target.value;
    const pickedCountry = countryList.find(
      (c) => c.id === parseInt(countryId)
    );
    setSelectedCountryId(parseInt(countryId))
    setDisplayedCountry(pickedCountry);
    navigate(
      `${AuthenticatedRoutesEnums.WORKSPACES}?co=${countryId}`
    );
  };

  const country = useMemo(() => {
    const parsedSelectedCountryId = selectedParamsCountryId
        ? selectedParamsCountryId
        : 0;
    if(parsedSelectedCountryId !== 0){
      const pickedCountry = countryList.find(
        (c) => c.id === parseInt(parsedSelectedCountryId)
      );
      if(pickedCountry) {
        setSelectedCountryId(parseInt(parsedSelectedCountryId))
        setLatitude(pickedCountry.latitude as string);
        setLongitude(pickedCountry.longitude as string);
        setDisplayedCountry(pickedCountry);
      }

      return pickedCountry;
    }
  }, [countryList]);

  useEffect(() => {
    if (teamRangeList && !teamRangeList.length) fetchTeamRangeList();
  }, [teamRangeList, fetchTeamRangeList]);

  useEffect(() => {
    if (spaceTypeList && !spaceTypeList.length) fetchSpaceTypeList();
  }, [spaceTypeList, fetchSpaceTypeList]);

  const handleWorkspaceDetailsClick = (workspaceId: number) => {
    navigate(
      AuthenticatedRoutesEnums.WORKSPACE_DETAILS.replace(
        ":workspaceId",
        workspaceId.toString()
      )
    );
  };

  const onNameChange = useMemo(
    () =>
      debounce(
        (
          selectedCountryId,
          searchText,
          spaceType,
          team_range,
          latitude,
          longitude
        ) => {
          if (searchText === "N/A") {
            searchText = undefined;
          }
          if (team_range === "Person Capacity") {
            team_range = undefined;
          }
          if (spaceType === "Workspace Type") {
            spaceType = undefined;
          }
          fetchWorkspaceList(
            selectedCountryId,
            searchText,
            spaceType,
            team_range,
            latitude,
            longitude
          );
        },
        500
      ),
    [fetchWorkspaceList]
  );

  useEffect(() => {
    onNameChange(
      selectedCountryId,
      searchText,
      spaceType,
      team_range,
      latitude,
      longitude
    );
  }, [
    onNameChange,
    selectedCountryId,
    searchText,
    spaceType,
    team_range,
    latitude,
    longitude,
  ]);

  const handleSearch = () => {
    const searchTextValue = searchTextInputRef.current?.value;
    if (searchTextValue) {
      setSearchText(searchTextValue);
    }
    else{
      setSearchText("N/A");
    }
  };

  return (
    <>
      <section>
        <Container>
          <Row>
            <Col md={6} className="flex-wrapper">
              {selectedCountryId && (
                <h5>
                  Exploring inspiring spaces in{" "}
                  <b className="text-primary"> 
                    {displayedCountry ? displayedCountry.name : "N/A"}
                  </b>
                </h5>
              )}
              <div className="d-flex mt-2">
                <Form.Control
                  type="text"
                  className="form-control"
                  placeholder="Search for workspaces..."
                  //value={searchText}
                  id="search-text"
                  ref={searchTextInputRef}
                />
                <Button
                  variant="primary"
                  className="font-size24 ms-2"
                  onClick={handleSearch}
                >
                  Search
                </Button>
              </div>
              <Col xs={12} md={6} className="mt-3">
                <div className="mt-3 mt-md-0 d-flex flex-wrap flex-md-nowrap align-items-center select-wrapper">
                  <Form.Select
                    as="select"
                    className="me-md-4 text-secondary mb-2 mb-md-0"
                    onChange={(e: any) => handleCountryChange(e)}
                  >
                    {fetchingCountryList ? (
                      <option>Loading country list</option>
                    ) : (
                      [
                        <option key="" value="">
                          Select country
                        </option>,
                        ...countryList?.map((country: ICountryDetail) => (
                          <option
                            key={country.id}
                            value={country.id}
                            label={`${country.name} ${country.emoji} `}
                            selected={country.id === selectedCountryId ? true : false}
                          />
                        )),
                      ]
                    )}
                  </Form.Select>
                  <Form.Select
                    aria-label="Workspace Type"
                    className="flex-grow-1 me-md-4 text-secondary mb-2 mb-md-0 select-wrapper "
                    onChange={(e: any) => setSpaceType(e.target.value)}
                  >
                    <option>Workspace Type</option>
                    {fetchingTeamRangeList ? (
                      <option>Loading Workspace</option>
                    ) : (
                      spaceTypeList?.map(
                        (spaceTypeDetail: ISpaceTypeDetail) => (
                          <option
                            value={spaceTypeDetail.id}
                            label={spaceTypeDetail.name}
                          />
                        )
                      )
                    )}
                  </Form.Select>
                  <Form.Select
                    aria-label="Default select example"
                    className="flex-grow-1 me-md-4 text-secondary mb-2 mb-md-0 select-wrapper"
                    onChange={(e: any) => setTeamRange(e.target.value)}
                  >
                    <option>Person Capacity</option>
                    {fetchingTeamRangeList ? (
                      <option>Loading team ranges</option>
                    ) : (
                      teamRangeList?.map(
                        (teamRangeDetail: ITeamRangeDetail) => (
                          <option
                            value={teamRangeDetail.id}
                            label={teamRangeDetail.range}
                          />
                        )
                      )
                    )}
                  </Form.Select>
                </div>
              </Col>
              {isFetchingWorkspaces ? (
                <>Fetching workspaces....</>
              ) : noWorkspacesFound ? (
                <>No Workspace found</>
              ) : (
                workspaceList.map((workspace: any) => (
                  <Card
                    className="workspace-card flex-wrap"
                    onClick={() => handleWorkspaceDetailsClick(workspace.id)}
                  >
                    <Row >
                      <Col md={4}>
                        <Card.Img
                          variant="top"
                          src={
                            workspace.photo_urls &&
                            workspace.photo_urls.length > 0
                              ? workspace.photo_urls[0].url
                              : require("../../images/fallback.jpg")
                          }
                        />
                      </Col>
                      <Col md={8} className="mt-2 position-relative card-text">
                        <div className="d-flex align-items-center justify-content-between card-text">
                          <div>
                            <h6>
                              <b>{workspace.name}</b>
                            </h6>
                            <div className="text-secondary">
                              {workspace.address}
                            </div>
                            <div className="text-secondary">
                              {workspace.space_type_name} ({workspace.range})
                            </div>
                          </div>
                        </div>
                        <Button
                          variant="outline-secondary"
                          className="text-primary mt-1 book"
                        >
                          Book Now
                        </Button>
                        
                      </Col>
                    </Row>
                  </Card>
                ))
              )}
            </Col>
            <Col md={6}>
              <MapContainer searchedCountry={displayedCountry} />
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}
