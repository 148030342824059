import { AxiosError } from "axios";
import { Formik, FormikHelpers, FormikProps } from "formik";
import { Button, Col, Container, Form, Row, Spinner } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { toast, ToastOptions, TypeOptions } from "react-toastify";
import { AuthenticatedRoutesEnums } from "../../../constants/routes.constants";
import axiosInstance from "../../../helpers/axios/axios.config";
import { IChangePasswordRequestParams } from "../../../interface/changePassword/changePassword.interface";
import { useAuthStore } from "../../../zustand-store/auth/auth.store";
import { useUserLoginStore } from "../../../zustand-store/auth/userLogin.store";
import { useState } from "react";
import { setPasswordValidation } from "../../../validation/setPassword/setPassword.validation";
import ErrorMsg from "../../../components/common/ErrorMsg";

interface IChangePasswordParam {
  password: string;
  password_confirmation: string;
}

interface ICreatePasswordProps {}

const CreatePassword: React.FunctionComponent<ICreatePasswordProps> = (
  props
) => {
  const { token, userData } = useUserLoginStore();
  const [formError, setFormError] = useState({
    password: "",
    password_confirmation: "",
  });
  const { doLogin } = useAuthStore();
  const { reset_token } = useParams();
  const navigate = useNavigate();
  const initialValues: IChangePasswordParam = {
    password: "",
    password_confirmation: "",
  };
  const handleChangePassword = async (
    value: IChangePasswordParam,
    formikHelpers: FormikHelpers<IChangePasswordParam>
  ) => {
    try {
      await axiosInstance.put("/users/password", {
        user: {
          password: value.password,
          password_confirmation: value.password_confirmation,
          reset_password_token: token ? token : reset_token,
        },
      } as IChangePasswordRequestParams).then((response) => {
        if((response as any).status === 204 ){
          toast("Password updated successfully.", {
            type: "success" as TypeOptions,
          } as ToastOptions);
          formikHelpers.resetForm();
          
          doLogin({
            user: {
              email: userData.email as any,
              password: value.password,
            },
          });
          console.log("Time to jump");
          setTimeout(() => {
            navigate(AuthenticatedRoutesEnums.UPDATE_PROFILE);
          }, 2000);
        }
      });
      
    } catch (error) {
      if (error instanceof AxiosError) {
        const responseData = ((error as AxiosError)?.response?.data as any)
          ?.errors;
        if (responseData?.reset_password_token) {
          toast("Reset password token has expired, please request a new one", {
            type: "warning" as TypeOptions,
          } as ToastOptions);
        } else {
          const errors = ErrorMsg(responseData);
          setFormError((formError) => ({
            ...formError,
            password_confirmation: errors.password_confirmation,
            password: errors.password,
          }));
        }
      } else {
        toast("Something went wrong, Please try again later.", {
          type: "error" as TypeOptions,
        } as ToastOptions);
      }
    }
  };
  return (
    <section className="pb-0 pt-0">
      <Container>
        <Row>
          <Col md={12} lg={6} className="d-flex align-items-center">
            <div className="form-common-box">
              <h3 className="form-common-title">Set Password</h3>
              <p className="common-para mb-3">Set your login credentials</p>
              <Formik
                initialValues={initialValues}
                onSubmit={handleChangePassword}
                validationSchema={setPasswordValidation}
              >
                {({
                  values,
                  errors,
                  touched,
                  isSubmitting,
                  handleSubmit,
                  handleChange,
                  handleBlur,
                }: FormikProps<IChangePasswordParam>) => (
                  <Form>
                    <Row>
                      <Col md={12} lg={12}>
                        <Form.Group
                          className="mb-4 position-relative"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Label>New password</Form.Label>
                          <Form.Control
                            type="password"
                            name="password"
                            placeholder="Enter your new password"
                            className={`form-icon-control ${
                              errors.password ? "error" : ""
                            }`}
                            value={values.password}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          <span className="icon-lock  field-icon"></span>
                          <Form.Control.Feedback
                            type="invalid"
                            className="d-block"
                          >
                            {errors.password && touched.password && (
                              <p>{errors.password}</p>
                            )}
                            {formError.password}
                          </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group
                          className="mb-4 position-relative"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Label>Re-enter new password</Form.Label>
                          <Form.Control
                            type="password"
                            name="password_confirmation"
                            placeholder="Enter your re-enter new password"
                            className={`form-icon-control ${
                              errors.password_confirmation ? "error" : ""
                            }`}
                            value={values.password_confirmation}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          <span className="icon-lock  field-icon"></span>
                          <Form.Control.Feedback
                            type="invalid"
                            className="d-block"
                          >
                            {errors.password_confirmation &&
                              touched.password_confirmation && (
                                <p>{errors.password_confirmation}</p>
                              )}
                            {formError.password_confirmation}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="mt-2">
                          <Button
                            variant="primary"
                            className="w-100"
                            onClick={() => handleSubmit()}
                            disabled={isSubmitting}
                          >
                            {isSubmitting ? (
                              <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                              />
                            ) : (
                              "Update"
                            )}
                          </Button>
                        </Form.Group>
                      </Col>
                    </Row>
                  </Form>
                )}
              </Formik>
            </div>
          </Col>
          <Col md={12} lg={6}>
            <img
              src={require("../../../images/user-login-info.png")}
              alt="hero banner"
              className="forworkspace-img"
            />
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default CreatePassword;
