import { AxiosResponse } from "axios";
import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";
import axiosInstance from "../../helpers/axios/axios.config";
import {
  ICountryDetail,
  ICountryList,
  ITeamRangeDetail,
  ITeamRangeList,
  ISpaceTypeList, ISpaceTypeDetail 
} from "../../interface/config/config.interface";

export const  useConfigStore = create<IConfigStore>()(
  persist(
    devtools((set, get) => ({
      countryList: [],
      fetchingCountryList: false,
      fetchCountryList: async () => {
        try {
          const { countryList } = get();
          if (!countryList.length) {
            set({
              fetchingCountryList: true,
            });
            const countryListResponse: AxiosResponse<ICountryList> =
              await axiosInstance.get("/countries");
            const countryList: ICountryDetail[] =
              countryListResponse.data.countries;
            set({
              countryList: countryList,
              fetchingCountryList: false,
            });
          }
        } catch (error) {
          set({
            fetchingCountryList: false,
          });
        }
      },
      teamRangeList: [],
      fetchingTeamRangeList: false,
      fetchTeamRangeList: async () => {
        try {
          const { teamRangeList } = get();
          if (!teamRangeList.length) {
            set({
              fetchingTeamRangeList: true,
            });
            const teamRangeListResponse: AxiosResponse<ITeamRangeList> =
              await axiosInstance.get("/team_ranges");
            const teamRangeList: ITeamRangeDetail[] =
              teamRangeListResponse.data.team_ranges;
            set({
              teamRangeList: teamRangeList,
              fetchingTeamRangeList: false,
            });
          }
        } catch (error) {
          set({
            fetchingTeamRangeList: false,
          });
        }
      },
      spaceTypeList: [],
      fetchingSpaceTypeList: false,
      fetchSpaceTypeList: async () => {
        try {
          const { spaceTypeList } = get();
          if (!spaceTypeList.length) {
            set({
              fetchingSpaceTypeList: true,
            });
            const spaceTypeListResponse: AxiosResponse<ISpaceTypeList> =
              await axiosInstance.get("/space_types");
            const spaceTypeList: ISpaceTypeDetail[] =
            spaceTypeListResponse.data.space_types;
            set({
              spaceTypeList: spaceTypeList,
              fetchingSpaceTypeList: false,
            });
          }
        } catch (error) {
          set({
            fetchingSpaceTypeList: false,
          });
        }
      },
    })),
    {
      name: "config_store",
    }
  )
);

export interface IConfigStore {
  countryList: ICountryDetail[];
  fetchingCountryList: boolean;
  fetchCountryList: () => void;
  teamRangeList: ITeamRangeDetail[];
  fetchingTeamRangeList: boolean;
  fetchTeamRangeList: () => void;
  spaceTypeList: ISpaceTypeDetail[];
  fetchingSpaceTypeList: boolean;
  fetchSpaceTypeList: () => void;
}
