import * as React from "react";
import { Button, Form, Spinner, Table } from "react-bootstrap";
import { toast, ToastOptions, TypeOptions } from "react-toastify";
import { ICustomerDetails } from "../../../../../../interface/customers/customer.interceptor";
import { useAuthStore } from "../../../../../../zustand-store/auth/auth.store";
import { useCustomerStore } from "../../../../../../zustand-store/customers/customers.store";
import AddCustomerModal from "./add-customer-modal";
import { AuthenticatedRoutesEnums } from "../../../../../../constants/routes.constants";
import { useLocation } from "react-router-dom";

interface ICustomersListProps {}

const CustomersList: React.FunctionComponent<ICustomersListProps> = (props) => {
  const { loggedInUserDetails } = useAuthStore();
  const [showAddCustomerModal, setShowAddCustomerModal] =
    React.useState<boolean>(false);
  const handleAddCustomerModalClose = () => setShowAddCustomerModal(false);
  const handleAddCustomerModalShow = () => setShowAddCustomerModal(true);
  const location = useLocation();

  const {
    customers,
    fetchCustomers,
    isFetchingCustomers,
    fetchCustomersError,
  } = useCustomerStore();

  const [listOfCustomers, setListOfCustomers] = React.useState<
    ICustomerDetails[]
  >([]);
  const [searchSlug, setSearchSlug] = React.useState<string>("");

  React.useEffect(() => {
    setListOfCustomers(customers);
  }, [customers]);
  React.useEffect(() => {
    if (fetchCustomersError)
      toast(
        "Failed to fetch the list of customer, please try to refresh the page.",
        {
          type: "error" as TypeOptions,
        } as ToastOptions
      );
  }, [fetchCustomersError]);

  React.useEffect(() => {
    setListOfCustomers(
      customers.filter(
        (customer) =>
          customer.name.toLowerCase().includes(searchSlug.toLowerCase()) ||
          customer.email.toLowerCase().includes(searchSlug.toLowerCase())
      )
    );
  }, [searchSlug, customers]);

  React.useEffect(() => {
    if (
      location.pathname === AuthenticatedRoutesEnums.BUSINESS_PROFILE_CUSTOMERS
    ) {
      fetchCustomers(loggedInUserDetails?.workspaces[0]?.id as any);
    }
  }, [fetchCustomers, location.pathname, loggedInUserDetails]);

  return (
    <div className="form-common-box rounded-0">
      <div className="d-flex justify-content-between align-items-center mb-3">
        <h2 className="myprofile-title text-dark mb-0">
          <span className="icon-people pe-2"></span>
          Customers
        </h2>
        <div className="d-flex align-items-center">
          <div className="search-field d-flex">
            <Form.Control
              type="text"
              placeholder="search customers..."
              onChange={(e) => {
                setSearchSlug(e.target.value);
              }}
            />
            <span className="icon-search-normal me-2"></span>
          </div>
          <Button
            variant="primary"
            className="ms-2"
            onClick={() => handleAddCustomerModalShow()}
          >
            Invite Customer
          </Button>
        </div>
      </div>

      <Table
        hover
        responsive
        className="dashboard-table dashboard-table-bordred"
      >
        <thead>
          <tr>
            <th>Name</th>
            <th>Email</th>
            <th>Phone</th>
            <th>Status</th>
          </tr>
        </thead>

        <tbody>
          {isFetchingCustomers ? (
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />
          ) : (
            listOfCustomers.map((employeeDetails: ICustomerDetails) => {
              return (
                <tr key={employeeDetails.id}>
                  <td>{employeeDetails.name}</td>
                  <td>{employeeDetails.email}</td>
                  <td>{employeeDetails.phone}</td>
                  <td>
                    <span className="text-success">
                      <span className="dots  bg-success"></span>Confirm
                    </span>
                  </td>
                </tr>
              );
            })
          )}
        </tbody>
      </Table>

      <AddCustomerModal
        showAddCustomerModal={showAddCustomerModal}
        handleAddCustomerModalClose={handleAddCustomerModalClose}
      />
    </div>
  );
};

export default CustomersList;
