import axios from "axios";
import {
  defaultRequestInterceptor,
  defaultResponseInterceptor,
} from "./interceptors/axios.interceptors";
import axiosMethods from "./methods";

// Update base branch as per env
const baseURL: string = `https://api.businesspass.eu/api/v1`;
const axiosInternalInstance = axios.create({
  baseURL,
});

defaultRequestInterceptor({ axiosInstance: axiosInternalInstance });
defaultResponseInterceptor({ axiosInstance: axiosInternalInstance });

const axiosInstance = axiosMethods(axiosInternalInstance);
export default axiosInstance;
