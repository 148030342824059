import React from "react";
import { Button, Form, Spinner } from "react-bootstrap";

interface Props {
  action_id: number;
  isLoading: boolean;
  handleSubmit: () => void;
}

const SpinnerComponent = () => {
  return (
    <Spinner
      as="span"
      animation="border"
      size="sm"
      role="status"
      aria-hidden="true"
    />
  );
};

const ActionButtons: React.FunctionComponent<Props> = ({
  action_id,
  isLoading,
  handleSubmit,
}) => {
  return (
    <Form.Group className="mt-2 float-end">
      <Button
        variant="primary"
        onClick={() => handleSubmit()}
        className="w-100"
      >
        {isLoading ? <SpinnerComponent /> : action_id ? "Update" : "Create"}
      </Button>
    </Form.Group>
  );
};

export default ActionButtons;
