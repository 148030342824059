import React from "react";

interface Props {
  places: any;
  choosePlace: (placeId: string) => void;
}

const Places: React.FunctionComponent<Props> = ({ choosePlace, places }) => {
  return (
    <div className="suggestions-wrapper">
      {places.map((place: any) => (
        <div className="inner-wrapper" key={place.place_id}>
          <span className="icon-location"></span>
          <div
            className="suggestion-item"
            role="presentation"
            onClick={() => choosePlace(place.place_id)}
          >
            {place.description}
          </div>
        </div>
      ))}
      <div className="image-container">
        <img
          className="powered-by-google"
          src="https://storage.googleapis.com/geo-devrel-public-buckets/powered_by_google_on_white.png"
          alt="Powered by Google"
        />
      </div>
    </div>
  );
};

export default Places;
