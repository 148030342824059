import * as Yup from "yup";

export const UserAccountFormValidation = Yup.object().shape({
  name: Yup.string().required("Please enter name"),
  // email: Yup.string()
  //   .email("Please enter valid email")
  //   .required("Please enter email"),
  email: Yup.string()
    .required("Please enter an email")
    .matches(
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
      "Please enter a valid email"
    ),
  phone: Yup.string()
    .matches(/^\+?\d{9,12}$/, "Enter a valid phone number")
    .required("Please enter a phone number"),
    // .matches(/^(\+\d{1,3}[- ]?)?\d{10}$/, "Enter valid phone number")
    // .required("Please enter a phone number"),
  country_id: Yup.mixed().test(
    "country_id",
    "Please select country",
    (value) => typeof value === "number" || typeof value === "string"
  ),
});
