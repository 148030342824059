import * as Yup from "yup";

export const CreateWorkspaceAccountFormValidation = Yup.object().shape({
  name: Yup.string().required("Please enter name"),
  email: Yup.string()
    .email("Please enter valid email")
    .required("Please enter email"),
  position: Yup.string().required("Please enter position"),
  phone: Yup.string().required("Please enter phone number."),
  address: Yup.string(),
});

export const CreateCustomerAccountFormValidation = Yup.object().shape({
  name: Yup.string().required("Please enter name"),
  email: Yup.string()
    .email("Please enter valid email")
    .required("Please enter email"),
  position: Yup.string().required("Please enter position"),
  companyName: Yup.string().required("Please enter company name"),
  country: Yup.string().required("Please select country"),
  numOfPerson: Yup.string().required("Please select number of person"),
});

export const CreateOrUpdateEmployeeFormValidation = Yup.object().shape({
  name: Yup.string().required("Please enter name"),
  email: Yup.string()
    .email("Please enter valid email")
    .required("Please enter email"),
  position: Yup.string().required("Please enter position"),
  phone: Yup.string().required("Please enter phone number."),
});

export const CreateCustomerFromBusinessProfileFormValidation =
  Yup.object().shape({
    name: Yup.string().required("Please enter name"),
    email: Yup.string()
      .email("Please enter valid email")
      .required("Please enter email"),
    position: Yup.string().required("Please enter position"),
    phone: Yup.string().required("Please enter phone number."),
  });
