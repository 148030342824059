export enum AuthenticatedRoutesEnums {
  PASS_ACCESS = "/",
  DASHBOARD = "/dashboard",
  DASHBOARD_USERS = "/dashboard/:tab/:user_id",
  DASHBOARD_ACTIVATE_ACCOUNT = "/dashboard/activate-account",
  DASHBOARD_EXPLORE_MEMBERSHIPS= "/dashboard/explore-memberships",
  DASHBOARD_WORKSPACES = "/dashboard/workspaces",
  DASHBOARD_COMMUNITIES = "/dashboard/communities",
  PROFILE = "/profile",
  UPDATE_PROFILE = "/update-profile",
  BUSINESS_PROFILE = "/business-profile",
  BUSINESS_PROFILE_WORKSPACES = "/business-profile/workspaces",
  BUSINESS_PROFILE_EMPLOYEES = "/business-profile/employees",
  BUSINESS_PROFILE_CUSTOMERS = "/business-profile/customers",
  BUSINESS_PROFILE_UPDATE_PASSWORD = "/business-profile/update-password",
  BUSINESS_PROFILE_DELETE_ACCOUNT = "/business-profile/delete-account",
  LOADER = "/waiting/:redirectTo/:delay",
  WORKSPACES = "/workspaces",
  WORKSPACE_DETAILS = "/workspaces/:workspaceId",
  WILDCARD = "*",
}

export enum UnAuthenticatedRoutesEnums {
  HOME = "/",
  ABOUT_US = "/about",
  BUSINESS = "/business",
  WORKSPACE = "/workspace",
  CUSTOMER = "/customer",
  EMPLOYEE = "/employee",
  VERIFY_TOKEN = "/verify_user/:token",
  USER_INFO = "/user-info/:token",
  WILL_BE_USED_FOR = "/what-will-used/:reset_token",
  CREATE_PASSWORD = "/create-password/:reset_token",
  WILDCARD = "*",
  NOT_FOUND = "/404",
  WORKSPACE_SELECT = "/workplace",
  ORDER_CONFIRMATION = "/order_confirmation",
  PAYMENTDONE = "/payment-done",
  THANKYOU = "/thankyou",
  ATTENDEESUSER = "/attendees-user",
  ATTENDEESFILEUPLOAD = "/attendees-file-upload",
  USERREDEEM = "user-redeem",
}
