import { useEffect, useState } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { IUserDetail } from "../../../../../interface/users/users.interface";
import { useUserStore } from "../../../../../zustand-store/users/users.store";

interface IDeleteUserProps {
  showDeleteUserModal: boolean;
  handleDeleteUserModalClose: () => void;
  userId: number | undefined;
  
}

const DeleteUser: React.FunctionComponent<IDeleteUserProps> = (props) => {
  const { users, deleteUser, deletingUser, deleteUserError } = useUserStore();
  const [userDetails, setUserDetails] = useState<IUserDetail | undefined>(
    undefined
  );

  useEffect(() => {
    const userDetailsById = users?.find(
      (user: IUserDetail) => user.id === props.userId
    );
    setUserDetails(userDetailsById);
  }, [users, props.userId]);
  
  return (
    <Modal
      className="common-modal"
      centered
      show={props.showDeleteUserModal}
      onHide={props.handleDeleteUserModalClose}
    >
      <Modal.Header closeButton>
        <Modal.Title>Delete User</Modal.Title>
        <p className="mb-0">Are you sure you want to delete this user?</p>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col sm={5}>
            <span className="user-label">Name</span>
          </Col>
          <Col sm={7}>
            <span className="user-text">{userDetails?.name}</span>
          </Col>
        </Row>
        <Row>
          <Col sm={5}>
            <span className="user-label">Email</span>
          </Col>

          <Col sm={7}>
            <span className="user-text">{userDetails?.email}</span>
          </Col>
        </Row>
        <Row>
          <Col sm={5}>
            <span className="user-label">Country</span>
          </Col>

          <Col sm={7}>
            <span className="user-text">{userDetails?.country_name || "NA"}</span>
          </Col>
        </Row>
        <Row>
          <Col sm={5}>
            <span className="user-label">Phone</span>
          </Col>
          <Col sm={7}>
            <span className="user-text">
              {(userDetails as any)?.phone || "NA"}{" "}
            </span>
          </Col>
        </Row>
        <Row>
          <Col sm={5}>
            <span className="user-label">Key</span>
          </Col>
          <Col sm={7}>
            <span className="user-text">
              {(userDetails as any)?.key || "NA"}{" "}
            </span>
          </Col>
        </Row>
        <Row>
          <Col sm={5}>
            <span className="user-label">User Type</span>
          </Col>
          <Col sm={7}>
            <span className="user-text">
              {(userDetails as any)?.user_type || "NA"}
            </span>
          </Col>
        </Row>
        <Row>
          <Col sm={5}>
            <span className="user-label">Status</span>
          </Col>
          <Col sm={7}>
            <span className="user-text">
              {(userDetails as any)?.status}
            </span>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="outline-primary"
          className="me-2"
          onClick={props.handleDeleteUserModalClose}
        >
          Cancel
        </Button>
        <Button
          variant="danger"
          disabled={deletingUser}
          className=""
          onClick={() => {
            if (props.userId)
              deleteUser(props.userId, props.handleDeleteUserModalClose);
          }}
        >
          Delete User
        </Button>
        {deleteUserError && <div>{deleteUserError}</div>}
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteUser;
