import { debounce } from "lodash";
import { useEffect, useMemo, useState, useCallback } from "react";
import { Button, Form, Table } from "react-bootstrap";
import axiosInstance from "../../../../../helpers/axios/axios.config";
import DashboardEyeicon from "../../../../../images/icons/eye.svg";
import Searchicon from "../../../../../images/icons/search.svg";
import DashboardSendicon from "../../../../../images/icons/send.svg";
import DashboardTrashicon from "../../../../../images/icons/trash.svg";
import { useUserStore } from "../../../../../zustand-store/users/users.store";
import DeleteUser from "../delete-user";
import UserInformation from "./user-information";
import AddEditUser from "./user-information/add-or-edit-user";
import { useNavigate, useParams } from "react-router-dom";

export default function UsersList() {
  const [isUserFormVisible, setIsUserFormVisible] = useState(false);
  const [editUser, setEditUser] = useState(null);
  const [showDetailsForUserId, setShowDetailsForUserId] = useState(undefined);
  const handleUserInfoModalClose = () => setShowDetailsForUserId(undefined);
  const handleUserInfoModalShow = (id) => setShowDetailsForUserId(id);

  const [status, setStatus] = useState(undefined);
  const [searchTerm, setSearchTeam] = useState(undefined);
  const [userType, setUserType] = useState(undefined);
  const navigate = useNavigate();
  const { tab, user_id } = useParams();
  const [showDeleteForUserId, setShowDeleteForUserId] = useState(undefined);
  const handleDeleteUserModalClose = () => setShowDeleteForUserId(undefined);
  const handleDeleteUserModalShow = (id) => setShowDeleteForUserId(id);

  const { fetchUsers, users, isFetchingUsers } = useUserStore();

  const onNameChange = useMemo(
    () =>
      debounce((searchTerm, userType, status) => {
        fetchUsers(searchTerm, userType, status);
      }, 500),
    [fetchUsers]
  );

  useEffect(() => {
    console.log("Callll params : ", tab, user_id);
    if(tab){
      if(user_id !== 0 ){
        fetchUsers()
        const getUserDetail = users && users.find((c) => c.id === parseInt(user_id));
        setEditUser(getUserDetail);
        setIsUserFormVisible(true);
      }else{
        setIsUserFormVisible(true);
      }
    }else{
      onNameChange(searchTerm, userType, status);
    }
  }, [onNameChange, searchTerm, userType, status]);

  // const handleSendInviteButton = async (userId) => {
  //   await axiosInstance.post(`/users/${userId}/send_invitations`);

  const disabledUserForm = useCallback(() => {
    navigate('/dashboard', { replace: true });
    setIsUserFormVisible(false);
    fetchUsers();
  }, [fetchUsers]);
  
  const openUserForm = useCallback(
    (id) => {
      let idd =  id == null ? 0 : id
      console.log("ID: ", idd);
      navigate('/dashboard/users_form/' + idd, { replace: true });
      const getUserDetail = users && users.find((c) => c.id === id);
      setEditUser(getUserDetail);
      setIsUserFormVisible(!isUserFormVisible);
    },
    [isUserFormVisible, users]
  );

  return (
    <>
      <div className="dashboard-search-box">
        <h3 className="form-common-title">Users</h3>
        <div className="search-bar">
          {!isUserFormVisible ? (
            <>
              <div className="search-field">
                <Form.Control
                  type="text"
                  placeholder="search user..."
                  onChange={(e) => setSearchTeam(e.target.value)}
                />
                <img src={Searchicon} alt="icon" className="search-icon" />
              </div>
              <Form.Select
                aria-label="Search user by type"
                className="form-control"
                onChange={(e) => setUserType(e.target.value)}
              >
                <option>Select User Type</option>
                {[
                  { text: "Super_Admin", value: "super_admin" },
                  { text: "Business", value: "business" },
                  { text: "Coummunity", value: "coummunity" },
                ].map((values) => (
                  <option value={values.value} label={values.text} />
                ))}
              </Form.Select>
              <Form.Select
                aria-label="Search user by status"
                className="form-control"
                onChange={(e) => setStatus(e.target.value)}
              >
                <option>Select status</option>

                {[
                  { text: "Registered", value: "registered" },
                  { text: "Invited", value: "invited" },
                  { text: "Accepted", value: "accepted" },
                  { text: "Activated", value: "activated" },
                  { text: "Deleted", value: "deleted" },
                  { text: "Deactivated", value: "deactivated" },
                  { text: "Closed", value: "closed" },
                ].map((values) => (
                  <option value={values.value} label={values.text} />
                ))}
              </Form.Select>
              <Button
                variant="primary"
                className="ms-2"
                onClick={() => openUserForm(null)}
              >
                Add user
              </Button>
            </>
          ) : (
            <Button variant="primary" onClick={disabledUserForm}>
              Back
            </Button>
          )}
        </div>
      </div>
      {!isUserFormVisible && (
        <Table hover responsive className="dashboard-table">
          <thead>
            <tr>
              <th>Name</th>
              <th>Email</th>
              <th>Country</th>
              <th>Phone</th>
              <th>Key</th>
              <th>User Type</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {isFetchingUsers && (
              <tr>
                <td>Loading...</td>
              </tr>
            )}
            {users?.map((user) => {
              const status =
                user.status.charAt(0).toUpperCase() + user.status.slice(1); // Convert first character to uppercase
              return (
                <tr key={`${user.id}-${user.email}`}>
                  <td>{user.name}</td>
                  <td>{user.email}</td>
                  <td>{user.country_name || "NA"}</td>
                  <td>{user.phone || "NA"}</td>
                  <td>{user.key || "NA"}</td>
                  <td>
                    {user.user_type.charAt(0).toUpperCase() +
                      user.user_type.slice(1)}
                  </td>
                  <td>
                    {user.status ? (
                      // Add color with users status
                      <Button
                        variant={
                          user.status === "deleted"
                            ? "outline-danger btn-light-danger"
                            : user.status === "invited"
                            ? "outline-info btn-light-info"
                            : user.status === "registered"
                            ? "outline-primary btn-light-primary"
                            : user.status === "accepted"
                            ? "outline-warning btn-light-warning"
                            : user.status === "deactivated"
                            ? "outline-warning btn-light-warning"
                            : user.status === "closed"
                            ? "outline-danger btn-light-danger"
                            : "outline-success btn-light-success"
                        }
                      >
                        {status}
                      </Button>
                    ) : (
                      <Button variant="outline-success btn-light-warning">
                        {"Pending"}
                      </Button>
                    )}
                  </td>
                  <td>
                    <div className="btn-actions">
                      {/* {!["activated", "accepted"].includes(user.status) && (
                      <Button
                        variant="white"
                        onClick={() => {
                          handleSendInviteButton(user.id);
                        }}
                      >
                        <img src={DashboardSendicon} alt="icon" />
                      </Button>
                    )} */}
                      <Button
                        variant="white"
                        className="me-2 ms-2"
                        onClick={() => {
                          handleUserInfoModalShow(user.id);
                        }}
                      >
                        <img src={DashboardEyeicon} alt="icon" />
                      </Button>
                      <Button
                        variant="white"
                        onClick={() => openUserForm(user.id)}
                      >
                        <span className="icon-edit fs-4"></span>
                      </Button>
                      <Button
                        variant="white"
                        onClick={() => {
                          handleDeleteUserModalShow(user.id);
                        }}
                      >
                        <img src={DashboardTrashicon} alt="icon" />
                      </Button>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      )}
      {isUserFormVisible ? (
        <AddEditUser editUser={editUser} disabledUserForm={disabledUserForm} />
      ) : null}
      {showDetailsForUserId !== undefined && (
        <UserInformation
          showUserInfoModal={showDetailsForUserId !== undefined}
          handleUserInfoModalClose={handleUserInfoModalClose}
          userId={showDetailsForUserId}
        />
      )}
      {showDeleteForUserId !== undefined && (
        <DeleteUser
          showDeleteUserModal={showDeleteForUserId}
          handleDeleteUserModalClose={handleDeleteUserModalClose}
          userId={showDeleteForUserId}
        />
      )}
    </>
  );
}
