import { Button, Col, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import {
  AuthenticatedRoutesEnums,
  UnAuthenticatedRoutesEnums,
} from "../../constants/routes.constants";
// import "../../styles/header.scss";
import "../../styles/header.scss";

const UpdateProfile: React.FC = () => {
// function UpdateProfile() {
  const navigate = useNavigate();
  return (
    <section className="pb-0 pt-0">
      <Container>
        <Row>
          <Col md={12} lg={6} className="d-flex align-items-center">
            <div className="form-common-box">
              <div className="text-center">
                <img
                  src={require("../../images/login-user-profile.png")}
                  alt="hero banner"
                  className="mb-2"
                />
              </div>

              <h3 className="form-common-title text-center">
                Update your profile
              </h3>
              <p className="common-para  text-center">
                Do you want to further update your profile details or skip...
              </p>
              <Row>
                <Col md={12} lg={12}>
                  <div className="d-grid gap-3">
                    <Button
                      variant="outline-primary"
                      onClick={() => {
                        navigate(`${AuthenticatedRoutesEnums.LOADER}/home/2000`);
                      }}
                    >
                      Do it later Select preferred date
                    </Button>
                    <Button
                      variant="primary"
                      onClick={() => {
                        navigate(AuthenticatedRoutesEnums.PROFILE);
                      }}
                    >
                      Update
                    </Button>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
          <Col md={12} lg={6}>
            <img
              src={require("../../images/user-login-info.png")}
              alt="hero banner"
              className="forworkspace-img"
            />
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default UpdateProfile;
