import React from "react";
import { useNavigate } from "react-router-dom";
import { UnAuthenticatedRoutesEnums } from "../../constants/routes.constants";

export const NotFoundPage: React.FC = () => {
  const navigate = useNavigate();
  return (
    <div className="not-found">
      <h1>404</h1>
      <h2>Hey captain! Looks like you're heading to a wrong planet!</h2>
      <button onClick={() => navigate(UnAuthenticatedRoutesEnums.HOME)}>
        Take me back to the homepage
      </button>
    </div>
  );
};
