import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { BsArrowLeft } from "react-icons/bs";
import "../../styles/orderconfirmation.scss";

const PaymentDone = () => {
  return (
    <section>
      <Container>
        <Row>
          <Col xs={12} md={10} lg={8}>
            <div className="mt-4">
              <Button variant="white" className="custom-button text-dark">
                <BsArrowLeft className="me-2" />
                Back
              </Button>
            </div>
            <div className="mt-3 text-dark">
              <h3>
                <b>Payment method</b>
              </h3>
              <p className="text-secondary">
                Lorem ipsum, dolor sit amet consectetur.
              </p>
            </div>
            <hr />

            <ul className="payment-method list-unstyled">
              <li className="payment-method-list">
                <input type="radio" id={"1"} name={"payment-method"} />
                <label className="radio-label" htmlFor={"1"}>
                  PayPal
                </label>
              </li>
              <li className="payment-method-list">
                <input type="radio" id={"2"} name={"payment-method"} />
                <label className="radio-label" htmlFor={"2"}>
                  Credit/Debit Card
                </label>
                <Form className="mt-3">
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>Card user name *</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter your card user name"
                    />
                  </Form.Group>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>Card number *</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter your card number"
                    />
                  </Form.Group>
                  <Row>
                    <Col lg={3}>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label>Expiration date *</Form.Label>
                        <Form.Control type="text" placeholder="00/00" />
                      </Form.Group>
                    </Col>
                    <Col lg={3}>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label>CVC code *</Form.Label>
                        <Form.Control type="text" placeholder="000" />
                      </Form.Group>
                    </Col>
                  </Row>
                </Form>
              </li>
            </ul>
          </Col>
          <Col xs={12} md={10} lg={4}>
            <div className="border p-4 order-summary">
              <h3 className="text-dark">Order Summary</h3>
              <p className="text-muted mb-4">Barcelona, Barcelona 08005</p>
              <hr />
              <div className="d-flex justify-content-between mt-4">
                <span className="order-title">Amount</span>
                <span className="order-amount">$ 19.00</span>
              </div>
              <div className="d-flex justify-content-between mt-4">
                <span className="order-title">Tax</span>
                <span className="order-amount">$ 2.00</span>
              </div>
              <div className="border-dashed mt-4 mb-4"></div>
              <div className="d-flex justify-content-between">
                <span>
                  <h4 className="text-dark order-total">Order Total</h4>
                </span>
                <span className="order-amount">$ 21.00</span>
              </div>
              <div className="mt-5  text-center">
                <Button variant="white" className="dashed-btn">
                  Add coupon code here
                </Button>
              </div>
              <div className="d-grid gap-2 mt-5 mb-3">
                <Button variant="primary" size="lg">
                  Checkout
                </Button>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default PaymentDone;
