import React, { useEffect } from "react";
import { Spinner } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";

export const SpinnerLoadingPage: React.FC = () => {
  const navigate = useNavigate();
  const { redirectTo, delay } = useParams();
  useEffect(() => {
    if (redirectTo) {
      setTimeout(() => {
        navigate(redirectTo === "home" ? "/" : redirectTo);
      }, Number(delay) || 2000);
    }
  }, [delay, redirectTo, navigate]);
  return (
    <div className="not-found">
      <Spinner />
    </div>
  );
};
