import { Formik, FormikHelpers, FormikProps } from "formik";
import { Button, Col, Form, Row, Spinner } from "react-bootstrap";
import { toast, ToastOptions, TypeOptions } from "react-toastify";
import axiosInstance from "../../../../helpers/axios/axios.config";
import { UpdatePasswordFormValidation } from "../../../../validation/profile/udpate-password.validation";

interface IUpdatePasswordFormParam {
  old_password: string;
  password: string;
  confirm_password: string;
}

interface IUpdatePasswordProps {}

const UpdatePassword: React.FunctionComponent<IUpdatePasswordProps> = (
  props
) => {
  const handleSubmit = async (
    values: IUpdatePasswordFormParam,
    formikHelpers: FormikHelpers<IUpdatePasswordFormParam>
  ) => {
    try {
      formikHelpers.setSubmitting(true);
      await axiosInstance.patch("/users/change_password", {
        user: {
          password: values.password,
          confirm_password: values.confirm_password,
          old_password: values.old_password,
        },
      });
      toast("Password updated successfully.", {
        type: "success" as TypeOptions,
      } as ToastOptions);
      formikHelpers.resetForm();
      formikHelpers.setSubmitting(false);
    } catch (error) {
      formikHelpers.setSubmitting(false);
      toast("Something went wrong, Please try again later.", {
        type: "error" as TypeOptions,
      } as ToastOptions);
    }
  };

  return (
    <div className="form-common-box rounded-0">
      <h2 className="myprofile-title text-dark">
        <span className="icon-lock pe-2"></span>
        Change Password
      </h2>

      <Formik
        initialValues={
          {
            old_password: "",
            password: "",
            confirm_password: "",
          } as IUpdatePasswordFormParam
        }
        onSubmit={handleSubmit}
        validationSchema={UpdatePasswordFormValidation}
        validateOnBlur={false}
        validateOnChange={false}
      >
        {({
          values,
          errors,
          isSubmitting,
          handleSubmit,
          handleChange,
          handleBlur,
        }: FormikProps<IUpdatePasswordFormParam>) => (
          <Form>
            <Row>
              <Col md={12} lg={8}>
                <Form.Group
                  className="mb-4 position-relative"
                  controlId="profile.updatePassword.current_password"
                >
                  <Form.Label>Current Password</Form.Label>
                  <Form.Control
                    type="password"
                    name="old_password"
                    placeholder="Enter current password"
                    className={`form-icon-control ${
                      errors.old_password ? "error" : ""
                    }`}
                    value={values.old_password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <span className="icon-lock  field-icon"></span>
                </Form.Group>

                <Form.Group
                  className="mb-4 position-relative"
                  controlId="profile.updatePassword.password"
                >
                  <Form.Label>New Password</Form.Label>
                  <Form.Control
                    type="password"
                    name="password"
                    placeholder="Enter new password"
                    className={`form-icon-control ${
                      errors.password ? "error" : ""
                    }`}
                    value={values.password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <span className="icon-lock  field-icon"></span>
                </Form.Group>

                <Form.Group
                  className="mb-4 position-relative"
                  controlId="profile.updatePassword.confirm_password"
                >
                  <Form.Label>Re-enter New Password</Form.Label>
                  <Form.Control
                    type="password"
                    name="confirm_password"
                    placeholder="Enter Re-enter New Password"
                    className={`form-icon-control ${
                      errors.confirm_password ? "error" : ""
                    }`}
                    value={values.confirm_password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <span className="icon-lock  field-icon"></span>
                  <Form.Control.Feedback type="invalid" className="d-block">
                    {errors.confirm_password}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>

              <Col md={12}>
                <Form.Group className="mt-2 float-end">
                  <Button
                    variant="primary"
                    onClick={() => handleSubmit()}
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? (
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                    ) : (
                      "Update"
                    )}
                  </Button>
                </Form.Group>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default UpdatePassword;
