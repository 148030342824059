import { AxiosError, AxiosResponse } from "axios";
import { toast, ToastOptions, TypeOptions } from "react-toastify";
import { create } from "zustand";
import { persist } from "zustand/middleware";
import { LocalStorageEnums } from "../../enums/localStorage.enums";
import axiosInstance from "../../helpers/axios/axios.config";
import { setLocalStorage } from "../../helpers/localStorage.helper";
import { IForgotPasswordRequestData } from "../../interface/forgotPassword/forgotPassword.interface";
import {
  ILoginApiRequestParams,
  ILoginApiResponse,
} from "../../interface/login/login.interface";
import {
  IUpdateMyProfileRequest,
  IUpdateUserProfilePhotoRequest,
} from "../../interface/my-profile/my-profile.interface";
import { ILoggedInUserDetails } from "../../interface/users/users.interface";

export const useAuthStore = create<ILoginStore>()(
  persist(
    (set, get) => ({
      loggedInUserDetails: undefined,
      isLoggingIn: false,
      isProfileUpdating: false,
      isDeletingAccount: false,
      doLogin: async (param: ILoginApiRequestParams, func: any) => {
        try {
          set({ isLoggingIn: true, loginError: undefined });
          const loginApiResponse: AxiosResponse = await axiosInstance.post(
            "/users/login",
            param
          );
          const loginResponse: ILoginApiResponse =
            loginApiResponse?.data as ILoginApiResponse;
          set({
            loggedInUserDetails: (loginResponse as any)?.user,
            isLoggingIn: false,
          });
          const token: string = loginApiResponse.headers["authorization"];
          setLocalStorage(LocalStorageEnums.USER_TOKEN, token);
        } catch (error) {
          let message: string = "Something went wrong";
          if (error instanceof AxiosError) {
            message = ((error as AxiosError)?.response?.data as any)
              ?.message as string;
          }
          set({ isLoggingIn: false, loginError: message });
        }
      },
      updateProfile: async (param: IUpdateMyProfileRequest, func: any) => {
        try {
          set({ isProfileUpdating: true, profileUpdateError: undefined });
          const { loggedInUserDetails } = get();
          await axiosInstance.patch("/users/" + loggedInUserDetails?.id, param);
          if (loggedInUserDetails) {
            set({
              loggedInUserDetails: {
                ...loggedInUserDetails,
                name: param.user.name,
                phone: param.user.phone,
                position: param.user.position,
                company_name: param.user.company_name || "",
                address: param.user.address,
              },
              isLoggingIn: false,
            });
          }
          set({ isProfileUpdating: false, profileUpdateError: undefined });
          // if (func) func();
        } catch (error) {
          let message: string = "Something went wrong";
          if (error instanceof AxiosError) {
            message = ((error as AxiosError)?.response?.data as any)
              ?.message as string;
          }
          set({ isProfileUpdating: false, profileUpdateError: message });
        }
      },
      updateUserProfilePhoto: async (
        param: IUpdateUserProfilePhotoRequest,
        func: any
      ) => {
        try {
          set({ isProfileUpdating: true, profileUpdateError: undefined });
          const { loggedInUserDetails } = get();
          await axiosInstance.patch(
            "/users/" + loggedInUserDetails?.id,
            param,
            {
              headers: { "Content-Type": "multipart/form-data" },
            }
          );
          if (loggedInUserDetails) {
            set({
              loggedInUserDetails: {
                ...loggedInUserDetails,
                photo_url: loggedInUserDetails?.photo_url, // param.user.photo_url,
              },
              isLoggingIn: false,
            });
          }
          set({ isProfileUpdating: false, profileUpdateError: undefined });
          toast("Profile photo updated successfully", {
            type: "success" as TypeOptions,
          } as ToastOptions);
        } catch (error) {
          let message: string = "Something went wrong";
          if (error instanceof AxiosError) {
            message = ((error as AxiosError)?.response?.data as any)
              ?.message as string;
          }
          set({ isProfileUpdating: false, profileUpdateError: message });
        }
      },
      deleteAccount: async (func?: any) => {
        try {
          set({ isDeletingAccount: true, profileUpdateError: undefined });
          const { loggedInUserDetails } = get();
          await axiosInstance.delete("/users/" + loggedInUserDetails?.id);
        } catch (error) {
          let message: string = "Something went wrong";
          if (error instanceof AxiosError) {
            message = ((error as AxiosError)?.response?.data as any)
              ?.message as string;
          }
          set({ isDeletingAccount: false, profileUpdateError: message });
        } finally {
          set({
            loggedInUserDetails: undefined,
            isDeletingAccount: false,
            profileUpdateError: undefined,
          });
          if (func) func();
          localStorage.clear();
        }
      },
      forgotPassword: async (param: IForgotPasswordRequestData, func: any) => {
        try {
          set({ isLoggingIn: true, loginError: undefined });
          await axiosInstance.post("/users/password", param);
        } catch (error) {
          let error_msg: string =
            "Something went wrong, Please try again later.";
          if (error instanceof AxiosError) {
            error_msg = ((error as AxiosError)?.response?.data as any)
              ?.error as string;
          }
          set({ isLoggingIn: false, loginError: error_msg });
        }
      },
      doLogout: async (func: any) => {
        set({
          loggedInUserDetails: undefined,
          isLoggingIn: false,
        });
        localStorage.clear();
        if (func) func();
      },
    }),
    {
      name: "auth_store",
      partialize: (state) => ({
        loggedInUserDetails: state.loggedInUserDetails,
      }),
    }
  )
);

export interface ILoginStore {
  loggedInUserDetails: ILoggedInUserDetails | undefined;
  isLoggingIn: boolean;
  loginError?: string;
  isProfileUpdating: boolean;
  profileUpdateError?: string;
  isDeletingAccount: boolean;
  doLogin: (param: ILoginApiRequestParams, func?: any) => void;
  updateProfile: (param: IUpdateMyProfileRequest, func?: any) => void;
  updateUserProfilePhoto: (
    param: IUpdateUserProfilePhotoRequest,
    func?: any
  ) => void;
  deleteAccount: (func?: any) => void;
  forgotPassword: (param: IForgotPasswordRequestData, func?: any) => void;
  doLogout: (func?: any) => void;
}
