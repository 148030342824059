import { AxiosError, AxiosResponse } from "axios";
import { create } from "zustand";
import { devtools } from "zustand/middleware";
import axiosInstance from "../../helpers/axios/axios.config";
import { IActivateMembershipResponseData } from "../../interface/activateAccount/activateAccount.interface";

export const useActivateAccountStore = create<IActivateAccountStore>()(
  devtools(
    (set, get) => ({
      activateAccountList: [],
      isFetchingActivateAccount: false,
      noActivateAccount: true,
      fetchActivateAccountError: undefined,
      fetchActivateAccountList: async () => {
        try {
          set({
            isFetchingActivateAccount: true,
            noActivateAccount: false,
            fetchActivateAccountError: undefined,
          });
          const usersListResponse: AxiosResponse<
            IActivateMembershipResponseData[]
          > = await axiosInstance.get("/activate_accounts");
          set({
            activateAccountList: usersListResponse.data,
            isFetchingActivateAccount: false,
            noActivateAccount: usersListResponse.data.length === 0,
            fetchActivateAccountError: undefined,
          });
        } catch (error) {
          let message: string = "Something went wrong";
          if (error instanceof AxiosError) {
            message = ((error as AxiosError)?.response?.data as any)
              ?.message as string;
          }
          set({
            isFetchingActivateAccount: false,
            noActivateAccount: false,
            fetchActivateAccountError: message,
          });
        }
      },
    }),
    {
      name: "explore_membership_list_store",
      anonymousActionType: "explore_membership_actions",
    }
  )
);

export interface IActivateAccountStore {
  activateAccountList: IActivateMembershipResponseData[];
  isFetchingActivateAccount: boolean;
  noActivateAccount: boolean;
  fetchActivateAccountError?: string;
  fetchActivateAccountList: () => void;
}
