import { AxiosError } from "axios";
import { Formik, FormikHelpers, FormikProps } from "formik";
import { useEffect, useState } from "react";
import { Button, Form, Modal, Spinner } from "react-bootstrap";
import PhoneInput from "react-phone-input-2";
import { isValidPhoneNumber, parsePhoneNumber } from "react-phone-number-input";
import { toast, ToastOptions, TypeOptions } from "react-toastify";
import axiosInstance from "../../../../../../helpers/axios/axios.config";
import { ICountryDetail } from "../../../../../../interface/config/config.interface";
import {
  ICreateEmployeeRequest,
  IEmployeeDetails,
} from "../../../../../../interface/employees/employees.interceptor";
import { CreateOrUpdateEmployeeFormValidation } from "../../../../../../validation/createAccount/createWorkspaceAccount.validation";
import { useAuthStore } from "../../../../../../zustand-store/auth/auth.store";
import { useConfigStore } from "../../../../../../zustand-store/config/config.store";
import { useEmployeeStore } from "../../../../../../zustand-store/employees/employees.store";

interface IAddEmployeeModalProps {
  showAddEmployeeModal: boolean;
  employeeDetails?: IEmployeeDetails;
  handleAddEmployeeModalClose: () => void;
}

interface IAddEmployeeFormParams {
  name: string;
  email: string;
  position: string;
  phone: string;
  // access: string;
}

const AddEmployeeModal: React.FunctionComponent<IAddEmployeeModalProps> = ({
  showAddEmployeeModal,
  employeeDetails,
  handleAddEmployeeModalClose,
}) => {
  const [intialValues, setIntialValues] = useState<IAddEmployeeFormParams>({
    name: "",
    email: "",
    position: "",
    phone: "41",
  });

  const { countryList, fetchCountryList } = useConfigStore();
  useEffect(() => {
    if (!countryList.length) fetchCountryList();
  }, [fetchCountryList, countryList]);

  useEffect(() => {
    if (employeeDetails) {
      const selectedCountryDetails: ICountryDetail | undefined =
        countryList.find(
          (country: ICountryDetail) => country.id === employeeDetails.country_id
        );
      setIntialValues({
        name: employeeDetails?.name,
        email: employeeDetails?.email,
        position: employeeDetails?.position,
        phone: `${selectedCountryDetails?.phone_code}${employeeDetails.phone}`,
      });
    }
  }, [countryList, employeeDetails]);

  const { fetchEmployee } = useEmployeeStore();
  const { loggedInUserDetails } = useAuthStore();
  const handleSubmitForm = async (
    values: IAddEmployeeFormParams,
    formikHelpers: FormikHelpers<IAddEmployeeFormParams>
  ) => {
    if (!isValidPhoneNumber("+" + values.phone)) {
      formikHelpers.setFieldError("phone", "Please enter valid phone number");
      return;
    }
    const parsedPhoneNumber = parsePhoneNumber("+" + values.phone);
    const selectedCountryDetails: ICountryDetail | undefined = countryList.find(
      (country: ICountryDetail) =>
        country.phone_code === parsedPhoneNumber?.countryCallingCode
    );
    try {
      const payload: ICreateEmployeeRequest = {
        employee: {
          name: values.name,
          email: values.email,
          country_id: selectedCountryDetails?.id,
          phone: parsedPhoneNumber?.nationalNumber,
          position: values.position,
        } as any,
      };
      if (employeeDetails) {
        await axiosInstance.patch(
          `/workspaces/${loggedInUserDetails?.workspaces[0]?.id}/employees/${employeeDetails.employee_id}`,
          payload
        );
      } else {
        await axiosInstance.post(
          `/workspaces/${loggedInUserDetails?.workspaces[0]?.id}/employees`,
          payload
        );
      }
      toast(`Employee ${employeeDetails ? "updated" : "added"} successfully.`, {
        type: "success" as TypeOptions,
      } as ToastOptions);
      formikHelpers.resetForm();
      handleAddEmployeeModalClose();
      fetchEmployee(loggedInUserDetails?.workspaces[0]?.id as any);
    } catch (error) {
      if (
        error instanceof AxiosError &&
        ((error as AxiosError)?.response?.data as any)?.error &&
        (
          ((error as AxiosError)?.response?.data as any)?.error as string[]
        )?.[0].includes("Email")
      ) {
        formikHelpers.setFieldError(
          "email",
          (
            ((error as AxiosError)?.response?.data as any)?.error as string[]
          )?.[0]
        );
      } else {
        toast("Something went wrong, Please try again later.", {
          type: "error" as TypeOptions,
        } as ToastOptions);
      }
    }
  };

  return (
    <Modal
      className="common-modal"
      centered
      show={showAddEmployeeModal}
      onHide={handleAddEmployeeModalClose}
    >
      <Modal.Header closeButton>
        <Modal.Title>{employeeDetails ? "Edit" : "Add"} Employee</Modal.Title>
      </Modal.Header>
      <Formik
        initialValues={intialValues}
        onSubmit={handleSubmitForm}
        validationSchema={CreateOrUpdateEmployeeFormValidation}
        validateOnChange={false}
        validateOnBlur={false}
        enableReinitialize={true}
      >
        {({
          values,
          errors,
          isSubmitting,
          handleSubmit,
          handleChange,
          handleBlur,
          setFieldValue,
        }: FormikProps<IAddEmployeeFormParams>) => {
          return (
            <>
              <Modal.Body>
                <Form>
                  <Form.Group
                    className="mb-4 position-relative"
                    controlId="addOrEditEmployeeForm.email"
                  >
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      type="email"
                      name="email"
                      placeholder="John@business.pass"
                      value={values.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className={`form-icon-control ${
                        errors.email ? "error" : ""
                      }`}
                    />
                    <span className="icon-main  field-icon"></span>
                    <Form.Control.Feedback type="invalid" className="d-block">
                      {errors.email &&
                      ![
                        "Please enter valid email",
                        "Please enter email",
                      ].includes(errors.email)
                        ? errors.email
                        : ""}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group
                    className="mb-4 position-relative"
                    controlId="addOrEditEmployeeForm.name"
                  >
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                      type="text"
                      name="name"
                      placeholder="John Doe"
                      value={values.name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className={`form-icon-control ${
                        errors.name ? "error" : ""
                      }`}
                    />
                    <span className="icon-profile field-icon"></span>
                  </Form.Group>
                  <Form.Group
                    className="mb-4"
                    controlId="addOrEditEmployeeForm.phone"
                  >
                    <Form.Label>Phone</Form.Label>
                    <PhoneInput
                      enableSearch
                      value={values.phone}
                      inputProps={{
                        placeholder: "Enter phone number",
                        className: `${
                          errors.phone ? "form-control error" : "form-control "
                        }`,
                      }}
                      onChange={(phone) => setFieldValue("phone", phone || "")}
                    />
                    {errors.phone && (
                      <Form.Control.Feedback type="invalid" className="d-block">
                        {errors.phone}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>
                  <Form.Group
                    className="mb-4"
                    controlId="addOrEditEmployeeForm.position"
                  >
                    <Form.Label>Position</Form.Label>
                    <Form.Control
                      type="text"
                      name="position"
                      placeholder="Tech lead"
                      value={values.position}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className={`${errors.position ? "error" : ""}`}
                    />
                  </Form.Group>
                  {/* <Form.Group
                    className="mb-4"
                    controlId="addOrEditEmployeeForm.email"
                  >
                    <Form.Label>Access</Form.Label>
                    <Form.Control
                      type="text"
                      name="access"
                      placeholder="Limited access"
                      value={values.access}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className={`${errors.access ? "error" : ""}`}
                    />
                  </Form.Group> */}
                </Form>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant="primary"
                  className="w-100"
                  onClick={() => handleSubmit()}
                  disabled={isSubmitting}
                >
                  {isSubmitting ? (
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  ) : employeeDetails ? (
                    "Update employee"
                  ) : (
                    "Create employee"
                  )}
                </Button>
              </Modal.Footer>
            </>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default AddEmployeeModal;
