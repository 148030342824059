import { Col, Container, Row } from "react-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";

function ThankYou() {
  const navigate = useNavigate();
  const location = useLocation();
  const fromCreateWorkspace = location.state?.fromCreateWorkspace;

  const handleGoBack = () => {
    navigate("/business")
  };

  return (
    <section className="flex-1">
      <Container>
        <Row className="justify-content-md-center">
          <Col lg={6}>
            <div className="form-common-box text-center">
              <img
                src={require("../../images/success-check.png")}
                alt="icon"
                className="mb-3"
              />
              {fromCreateWorkspace ? (
                <h1 className="section-title mb-2">Thank you 😊</h1>
              ) : (
                <h1 className="section-title mb-2">Thank you for Bookings</h1>
              )}              
                {fromCreateWorkspace ? (
                  <p className="common-para mt-3">Thank you for reaching out to us!  We've received your request. Our dedicated partnership team will be in touch with you soon to discuss further details.</p>
                ) : (
                  <p className="common-para">
                 Manage your personal information or <a href="#"> unsubscribe</a>{" "}
                 if you made an error. Read our <a href="#">Privacy Policy </a>{" "}
                 if you want to know more.
               </p>    
              )}
              {fromCreateWorkspace && (
                <button className="btn btn-primary" onClick={handleGoBack}>
                  Go Back
                </button>
              )}
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default ThankYou;

