import { useEffect, useState } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { IUserDetail } from "../../../../../../interface/users/users.interface";
import { useUserStore } from "../../../../../../zustand-store/users/users.store";
import Capitalize from '../../../../../../components/common/Capitalize';
import axiosInstance from "../../../../../../helpers/axios/axios.config";

interface IUserInformationModalProps {
  showUserInfoModal: boolean;
  handleUserInfoModalClose: () => void;
  userId: number | undefined;
}

const UserInformationModal: React.FunctionComponent<
  IUserInformationModalProps
> = (props) => {
  const { users } = useUserStore();
  const [userDetails, setUserDetails] = useState<IUserDetail | undefined>(
    undefined
  );

  useEffect(() => {
    const userDetailsById = users?.find(
      (user: IUserDetail) => user.id === props.userId
    );
    setUserDetails(userDetailsById);
  }, [users, props.userId]);

  const handleSendInviteButton = async () => {
     await axiosInstance.post(`/users/${props.userId}/send_invitations`);
  }

  return (
    <Modal
      className="common-modal"
      centered
      show={props.showUserInfoModal}
      onHide={props.handleUserInfoModalClose}
    >
      <Modal.Header closeButton>
        <Modal.Title>User Information</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col sm={5}>
            <span className="user-label">Name</span>
          </Col>
          <Col sm={7}>
            <span className="user-text">{userDetails?.name}</span>
          </Col>
        </Row>
        <Row>
          <Col sm={5}>
            <span className="user-label">Email</span>
          </Col>
          <Col sm={7}>
            <span className="user-text">{userDetails?.email}</span>
          </Col>
        </Row>
        <Row>
          <Col sm={5}>
            <span className="user-label">Country</span>
          </Col>

          <Col sm={7}>
            <span className="user-text">{userDetails?.country_name || "NA"}</span>
          </Col>
        </Row>
        <Row>
          <Col sm={5}>
            <span className="user-label">Phone</span>
          </Col>
          <Col sm={7}>
            <span className="user-text">
              {(userDetails as any)?.phone || "NA"}{" "}
            </span>
          </Col>
        </Row>
        <Row>
          <Col sm={5}>
            <span className="user-label">Key</span>
          </Col>
          <Col sm={7}>
            <span className="user-text">
              {(userDetails as any)?.key || "N/A"}
            </span>
          </Col>
        </Row>
        <Row>
          <Col sm={5}>
            <span className="user-label">User Type</span>
          </Col>
          <Col sm={7}>
            <span className="user-text">
              {Capitalize((userDetails as any)?.user_type)}
            </span>
          </Col>
        </Row>
        <Row>
          <Col sm={5}>
            <span className="user-label">Status</span>
          </Col>
          <Col sm={7}>
            <span className="user-text">
              {Capitalize((userDetails as any)?.status)}
            </span>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="outline-primary"
          className="me-3"
          onClick={props.handleUserInfoModalClose}
        >
          Cancel
        </Button>
        {userDetails?.status !== true && (
          <Button 
          variant="primary"
          className=""
          onClick={() => {
            handleSendInviteButton();
          }}>
            Invite User
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default UserInformationModal;
