import { useCallback, useState, useEffect, useMemo } from "react";
import { Button, Form, Table } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { debounce } from "lodash";

// store
import { useWorkspaceStore } from "../../../../../zustand-store/workspace/workspace.store";

// interfaces
import {
  IEditWorkspace,
  IWorkspaceDetails,
} from "../../../../../interface/workspace/workspace.interface";

// components
import UploadDetails from "./upload-details";
import ShowWorkSpace from "./show-workspace";
import { AuthenticatedRoutesEnums } from "../../../../../constants/routes.constants";

const SpaceInformation = () => {
  const [isManageWorkspaceVisible, setIsManageWorkspaceVisible] =
    useState<boolean>(false);
  const [editObj, setEditObj] = useState<IEditWorkspace | null>(null);
  const [isWorkspaceDetailsVisible, setIsWorkspaceDetailsVisible] =
    useState<boolean>(false);
  const { workspaceList, fetchWorkspaces } = useWorkspaceStore();
  const [search, setSearch] = useState(undefined);
  const location = useLocation();

  useEffect(() => {
    if (
      location.pathname === AuthenticatedRoutesEnums.BUSINESS_PROFILE ||
      location.pathname === AuthenticatedRoutesEnums.BUSINESS_PROFILE_WORKSPACES
    ) {
      fetchWorkspaces();
    }
  }, [fetchWorkspaces, location.pathname]);

  const enableWorkspaceForm = useCallback(() => {
    setIsManageWorkspaceVisible(true);
  }, []);

  const disabledWorkspaceForm = useCallback(() => {
    setIsManageWorkspaceVisible(false);
    setIsWorkspaceDetailsVisible(false);
    setEditObj(null);
    fetchWorkspaces();
  }, [fetchWorkspaces]);

  const setWorkspaceInfo = useCallback(
    (workspaceId: number) => {
      const getWorkspaceDetail =
        workspaceList && workspaceList.find((c) => c.id === workspaceId);

      if (getWorkspaceDetail) {
        const configStore = localStorage.getItem("config_store");

        if (configStore) {
          const storedValues = JSON.parse(configStore);
          const country = storedValues.state.countryList.find(
            (country: any) => country.id === getWorkspaceDetail?.country_id
          );

          setEditObj({ ...getWorkspaceDetail, country_name: country.name });
        }
      }
    },
    [workspaceList]
  );

  const openWorkSpaceForm = useCallback(
    (id: number) => {
      setWorkspaceInfo(id);
      setIsWorkspaceDetailsVisible(false);
      setIsManageWorkspaceVisible(!isManageWorkspaceVisible);
    },
    [isManageWorkspaceVisible, setWorkspaceInfo]
  );

  const openWorkSpaceDetails = (id: number) => {
    setWorkspaceInfo(id);
    setIsWorkspaceDetailsVisible(true);
  };

  const onNameChange = useMemo(
    () =>
      debounce((search) => {
        fetchWorkspaces(search);
      }, 500),
    [fetchWorkspaces]
  );

  useEffect(() => {
    onNameChange(search);
  }, [onNameChange, search]);

  const onSaveWorkspaceDetails = (workspace: any) => {
    setIsManageWorkspaceVisible(false);
    setIsWorkspaceDetailsVisible(true);
    setEditObj(workspace);
  };

  return (
    <div className="form-common-box rounded-0">
      <div className="d-flex justify-content-between align-items-center mb-3">
        <h2 className="myprofile-title text-dark mb-0">
          <span className="icon-users pe-2"></span>
          Workspaces
        </h2>
        <div className="d-flex align-items-center">
          {!isManageWorkspaceVisible && !isWorkspaceDetailsVisible ? (
            <>
              <div className="search-field d-flex">
                <Form.Control
                  type="text"
                  placeholder="search workspaces..."
                  className="search-workspace"
                  onChange={(e: any) => setSearch(e.target.value)}
                />
                <span className="icon-search-normal"></span>
              </div>

              <Button variant="primary" onClick={enableWorkspaceForm}>
                Add workspace
              </Button>
            </>
          ) : (
            <Button variant="primary" onClick={disabledWorkspaceForm}>
              Back
            </Button>
          )}
        </div>
      </div>
      {!isManageWorkspaceVisible && !isWorkspaceDetailsVisible && (
        <Table
          hover
          responsive
          className="dashboard-table dashboard-table-bordred"
        >
          <thead>
            <tr>
              <th>Name</th>
              <th>Address</th>
              <th>Phone_no</th>
              <th>Country</th>
              <th>Action</th>
            </tr>
          </thead>

          <tbody>
            {workspaceList &&
              workspaceList.map((workspace: IWorkspaceDetails) => {
                const { id, name, address, phone_no, country_name } =
                  workspace ?? {};

                return (
                  <tr key={id}>
                    <td>{name}</td>
                    <td title={address} className="address-wrapper">
                      {address || "-"}
                    </td>
                    <td>{phone_no || "-"}</td>
                    <td title={country_name || "-"}>{country_name || "-"}</td>
                    <td>
                      <div className="btn-actions workspace-action-item-wrapper">
                        <Button
                          variant="white"
                          onClick={() => openWorkSpaceDetails(id)}
                        >
                          <span className="icon-view fs-4"></span>
                        </Button>
                        <Button
                          variant="white"
                          onClick={() => openWorkSpaceForm(id)}
                        >
                          <span className="icon-edit fs-4"></span>
                        </Button>
                        <Button variant="white">
                          <span className="icon-trash fs-4"></span>
                        </Button>
                      </div>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </Table>
      )}

      {/* Manage Workspace */}
      {isManageWorkspaceVisible ? (
        <UploadDetails
          edit_obj={editObj}
          onSaveWorkspaceDetails={onSaveWorkspaceDetails}
        />
      ) : null}

      {/* Show Workspace details page */}
      {isWorkspaceDetailsVisible && editObj !== null ? (
        <ShowWorkSpace workSpace={editObj} isWorkspaceGallery />
      ) : null}
    </div>
  );
};
export default SpaceInformation;
