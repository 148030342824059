import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import { FormikHelpers, useFormik } from "formik";
import { Button, Col, Form, Row, FormLabel } from "react-bootstrap";
import { useDropzone } from "react-dropzone";
import { toast, ToastOptions, TypeOptions } from "react-toastify";
import axios, { AxiosResponse, AxiosError } from "axios";

// components
import AddressSelection from "./address-selection";

// interfaces and validation schemas
import {
  ICountryDetail,
  ITeamRangeDetail,
  ISpaceTypeDetail,
  WorkspaceImage,
} from "../../../../../interface/config/config.interface";
import {
  Address,
  FileRejection,
  FileWithPreview,
  ICreateWorkspaceRequest,
  IEditWorkspace,
  IUploadDetailsFormParams,
  error,
} from "../../../../../interface/workspace/workspace.interface";
import { UploadDetailsFormValidation } from "../../../../../validation/uploadDetails/uploadDetails.validation";

// config store
import { useConfigStore } from "../../../../../zustand-store/config/config.store";

// helpers and constants
import { handleImageError } from "../../../../../helpers";

// others
import axiosInstance from "../../../../../helpers/axios/axios.config";
import ErrorMsg from "../../../../../components/common/ErrorMsg";
import ActionButtons from "./action-buttons";

const UploadDetails: React.FunctionComponent<any> = memo(
  ({ edit_obj: workspace, onSaveWorkspaceDetails }) => {
    const { id: workSpaceId, photo_urls: workspaceImages } = workspace ?? {};

    const {
      countryList,
      fetchCountryList,
      teamRangeList,
      fetchTeamRangeList,
      spaceTypeList,
      fetchSpaceTypeList,
    } = useConfigStore();

    const [workspaceValues, setWorkspaceValues] = useState<
      Partial<IEditWorkspace>
    >({});
    const [files, setFiles] = useState<FileWithPreview[]>([]);
    const [deletedFiles, setDeletedFiles] = useState<FileWithPreview[]>([]);
    const [errorMessage, setErrorMessage] = useState<string>("");
    const [rejectedFiles, setRejectedFiles] = useState<any>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [addressSelectedByUser, setAddressSelectedByUser] = useState<Address>(
      {
        address: "",
        lat: 0,
        lng: 0,
        postalCode: "",
        city: "",
        country_id: "",
      }
    );
    const [formError, setFormError] = useState({
      location_url: "",
      name: "",
      team_range: "",
    });

    const { address, lat, lng, city, postalCode, country_id } =
      addressSelectedByUser ?? {};

    const addressSelection = useCallback(
      ({ address, lat, lng, city, postalCode, country_id }: Address) => {
        setAddressSelectedByUser({
          address,
          lat,
          lng,
          city,
          postalCode,
          country_id,
        });
      },
      []
    );

    useEffect(() => {
      if (workspaceImages && workspaceImages.length > 0) {
        const existingFiles = workspaceImages.map((file: WorkspaceImage) => ({
          id: file.id,
          url: file.url,
          isNew: false,
        })) as FileWithPreview[];
        setFiles(existingFiles);
      }
    }, [workspaceImages]);

    const { getRootProps, getInputProps } = useDropzone({
      accept: {
        "image/png": [".png"],
        "image/jpg": [".jpg"],
        "image/jpeg": [".jpeg"],
        "image/gif": [".gif"],
      },
      maxSize: 10485760,
      onDrop: (acceptedFiles: File[], fileRejections: any) => {
        setRejectedFiles((prev: any) => [...prev, ...fileRejections]);

        let duplicateFound = false;
        acceptedFiles.forEach((newFile) => {
          if (
            files.some((existingFile) => existingFile.name === newFile.name)
          ) {
            duplicateFound = true;
          }
        });

        if (duplicateFound) {
          setErrorMessage(
            "Error: One or more selected images are already in the list."
          );
        } else {
          const filesWithPreview = acceptedFiles.map((file) =>
            Object.assign(file, {
              url: URL.createObjectURL(file),
              isNew: true,
            })
          ) as FileWithPreview[];
          setFiles((prevFiles) => [...prevFiles, ...filesWithPreview]);
          setErrorMessage("");
        }
      },
    });

    const removeAll = () => {
      setFiles([]);
      setRejectedFiles([]);
    };

    const fileRejectionItems = useMemo(
      () =>
        rejectedFiles &&
        rejectedFiles.map(
          ({ file, errors }: { file: FileRejection; errors: error[] }) => {
            const { name, size } = file ?? {};

            return (
              <li key={name} className="rejected-files-list">
                {name} - {size} bytes
                <ul className="rejected-file-error-list">
                  {errors.map((error: any) => {
                    const { code, message } = error ?? {};

                    return <li key={code}>{message}</li>;
                  })}
                </ul>
              </li>
            );
          }
        ),
      [rejectedFiles]
    );

    const handleDelete = useCallback(
      (fileId: number) => {
        const updatedFiles = files && files.filter(({ id }) => id !== fileId);
        setFiles(updatedFiles);
        const deletedFile = files.filter(({ id }) => id === fileId);
        setDeletedFiles((prevFiles) => [...prevFiles, ...deletedFile]);
      },
      [files]
    );

    const thumbs = useMemo(
      () =>
        files &&
        files.map((file) => {
          const { id, url } = file ?? {};

          return (
            <div className="thumb" key={id}>
              <div className="thumbInner">
                <img
                  onError={handleImageError}
                  alt={`img-${id}`}
                  src={url}
                  className="img"
                  onLoad={() => {
                    URL.revokeObjectURL(url);
                  }}
                />
                <button className="deleteIcon" onClick={() => handleDelete(id)}>
                  &#10006;
                </button>
              </div>
            </div>
          );
        }),
      [files, handleDelete]
    );

    useEffect(() => {
      return () =>
        files && files.forEach(({ preview }) => URL.revokeObjectURL(preview));
    }, [files]);

    useEffect(() => {
      if (!countryList.length) {
        fetchCountryList();
      }
      if (!teamRangeList.length) {
        fetchTeamRangeList();
      }

      if (!spaceTypeList.length) {
        fetchSpaceTypeList();
      }
    }, [
      fetchCountryList,
      countryList,
      teamRangeList?.length,
      spaceTypeList?.length,
      fetchTeamRangeList,
      fetchSpaceTypeList,
    ]);

    let workspace_id;
    const handleSingleWorkspaceImg = useCallback(
      async (url: string, index: number, wId?: number) => {
        await axios.put(url, files[index], {
          headers: { "Content-Type": files[index].type },
        });
        workspace_id = wId ? wId : workSpaceId;
        if (workspace_id) {
          await axiosInstance.patch(`/workspaces/${workspace_id}`, {
            workspace: { photo: url.split("?")[0] },
          });
        }
      },
      [files, workspace_id]
    );

    const handleToastMessage = (msg: string, type: string) => {
      toast(msg, {
        type: type as TypeOptions,
      } as ToastOptions);
    };
    
    const onUploadWorkspaceImages = async (wId: number) => {
      try {
        const modifiedFiles =
          files && files.filter(({ isNew }) => isNew).map(({ name }) => name);
        if (!modifiedFiles.length) {
          return;
        }
        const { data: response } = await axiosInstance.post<
          AxiosResponse<{ presigned_urls: [] }>
        >(`/workspaces/${wId}/generate_presigned_url`, {
          workspace: { photos: modifiedFiles },
        });      
        const { presigned_urls } = response ?? {};
        if (presigned_urls.length) {
          presigned_urls.forEach((url: string, index: number) => {
            handleSingleWorkspaceImg(url, index);
          });
        }
      } catch (error) {
        handleToastMessage("Failed to upload the workspace image.", "error");
      }
    };

    const generatePayload = useCallback(
      (values: IUploadDetailsFormParams) => {
        const {
          name,
          phone_no,
          country_id,
          city_name,
          postal_code,
          location_url,
          space_type_id,
          team_range_id,
          photos,
          price,
          day_pass,
          weekly_pass,
          per_hour,
        } = values ?? {};

        const payload: ICreateWorkspaceRequest = {
          workspace: {
            price,
            name,
            phone_no,
            country_id,
            city_name,
            postal_code,
            address: address,
            location_url,
            space_type_id,
            team_range_id,
            photos,
            latitude: lat,
            longitude: lng,
            day_pass,
            weekly_pass,
            per_hour,
            delete_photos: deletedFiles.length
              ? deletedFiles.map(({ id }) => id)
              : [],
          },
        };

        return payload;
      },
      [address, deletedFiles, lat, lng]
    );

    const shapedWorkspace = (workspaceDetails: any) => {
      const spaceType =
        spaceTypeList &&
        spaceTypeList.find(
          (space) => space.id === Number(workspaceDetails.space_type_id)
        );

      const teamRange =
        teamRangeList &&
        teamRangeList.find(
          (team) => team.id === Number(workspaceDetails.team_range_id)
        );

      const configStore = localStorage.getItem("config_store");

      if (configStore) {
        const storedValues = JSON.parse(configStore);
        const country = storedValues.state.countryList.find(
          (country: any) => country.id === Number(workspaceDetails?.country_id)
        );

        if (country && teamRange && spaceType) {
          onSaveWorkspaceDetails({
            ...workspaceDetails,
            space_type_name: spaceType?.name || "",
            country_name: country?.name || "",
            range: teamRange?.range || "",
          });
        }
      }
    };

    const onSubmitWorkspaceDetails = async (
      values: IUploadDetailsFormParams,
      formikHelpers: FormikHelpers<IUploadDetailsFormParams>
    ) => {
      try {
        setIsLoading(true);
        const payload = generatePayload(values);

        if (!payload.workspace.address) {
          formikHelpers.setFieldError("address", "Please enter address");
          return;
        }

        if (workSpaceId) {
          if (files.length) {
            onUploadWorkspaceImages(workSpaceId);
          }
          await axiosInstance.patch(`/workspaces/${workSpaceId}`, payload);
          shapedWorkspace(payload.workspace);
        } else {
          await axiosInstance
            .post("/workspaces", payload)
            .then((response: any) => {
              if (response.data.workspace && files.length) {
                shapedWorkspace(response.data.workspace);
                onUploadWorkspaceImages(response.data.workspace.id);

                return;
              }
            });
          formikHelpers.resetForm();
        }
        const successMsg = `Workspace details ${
          workSpaceId ? "updated" : "added"
        } successfully.`;
        handleToastMessage(successMsg, "success");
      } catch (error) {
        if (error instanceof AxiosError) {
          const responseData = ((error as AxiosError)?.response?.data as any)
            ?.errors;
          if (responseData) {
            const errors = ErrorMsg(responseData);
            setFormError((formError) => ({
              ...formError,
              location_url: errors.location_url,
              name: errors.name,
              team_range: errors.team_range,
            }));
          } else {
            handleToastMessage(
              "Please try again something Went to wrong",
              "error"
            );
          }
        }
      } finally {
        setIsLoading(false);
      }
    };

    const modifiedFormValues = useMemo(() => {
      let values = null;

      if (!workSpaceId) {
        values = {
          ...workspaceValues,
          address: address,
          city_name: city,
          postal_code: workspaceValues?.postal_code || postalCode,
          country_id: country_id,
        };
      } else {
        values = {
          ...workspace,
          address: address,
          city_name: city,
          postal_code: postalCode,
          country_id: country_id,
        };
      }

      return values;
    }, [
      address,
      city,
      country_id,
      postalCode,
      workSpaceId,
      workspace,
      workspaceValues,
    ]);

    const formik = useFormik({
      initialValues: modifiedFormValues,
      validateOnChange: false,
      validateOnBlur: false,
      enableReinitialize: true,
      onSubmit: onSubmitWorkspaceDetails,
      validationSchema: UploadDetailsFormValidation,
    });

    const {
      values,
      errors,
      touched,
      handleSubmit,
      handleChange,
      handleBlur,
      setFieldValue,
      validateField,
    } = formik;

    const handleInputChange = useCallback(
      async (e: React.ChangeEvent<any>, fieldName: string) => {
        setWorkspaceValues((prev) => ({
          ...prev,
          [fieldName]: e.target.value,
        }));
        await handleChange(e);
        validateField(fieldName);
      },
      [handleChange, validateField]
    );

    return (
      <div className="form-common-box rounded-0">
        <h2 className="myprofile-title text-dark">
          <span className="icon-briefcase-outline pe-2"></span>
          {workSpaceId ? "Upload Details" : "Add Workspace"}
        </h2>
        <Form onSubmit={formik.handleSubmit} autoComplete="off">
          <Row>
            <Col md={12} lg={12}>
              <Form.Group className="mb-4 position-relative" controlId="name">
                <Form.Label>Workspace Name</Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  placeholder="Enter Workspace Name"
                  className={`${errors.name ? "error" : ""}`}
                  value={values?.name}
                  onBlur={handleBlur}
                  onChange={(e) => handleInputChange(e, "name")}
                  isValid={touched.name && !errors.name && !!values.name}
                  isInvalid={!!errors.name}
                />
                {errors.name && touched.name && (
                  <Form.Control.Feedback type="invalid" className="d-block">
                    {errors.name}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group
                className="mb-4 position-relative"
                controlId="country_id"
              >
                <Form.Label>Country</Form.Label>
                <Form.Control
                  as="select"
                  type="select"
                  name="country_id"
                  className={`${errors.country_id ? "error" : ""}`}
                  value={values.country_id}
                  onBlur={handleBlur}
                  onChange={(e) => handleInputChange(e, "country_id")}
                  isValid={
                    touched.country_id &&
                    !errors.country_id &&
                    !!values.country_id
                  }
                  isInvalid={!!errors.country_id}
                >
                  <option value="" label="Select your country" />
                  {countryList
                    ? countryList.map((country: ICountryDetail) => {
                        const { id, name, emoji } = country ?? {};

                        return (
                          <option
                            key={id}
                            value={id}
                            label={`${name} ${emoji}`}
                          />
                        );
                      })
                    : null}
                </Form.Control>
                {errors.country_id && (
                  <Form.Control.Feedback type="invalid" className="d-block">
                    {errors.country_id}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group
                className="mb-4 position-relative"
                controlId="phone_no"
              >
                <Form.Label>Phone</Form.Label>
                <Form.Control
                  type="text"
                  name="phone_no"
                  placeholder="999XXXXXXX"
                  className={`${errors.phone_no ? "error" : ""}`}
                  value={values?.phone_no}
                  onBlur={handleBlur}
                  onChange={(e) => handleInputChange(e, "phone_no")}
                  isValid={
                    touched.phone_no && !errors.phone_no && !!values.phone_no
                  }
                  isInvalid={!!errors.phone_no}
                />
                {errors.phone_no && touched.phone_no && (
                  <Form.Control.Feedback type="invalid" className="d-block">
                    {errors.phone_no}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </Col>
            <Col md={12} lg={12}>
              <Form.Group
                className="mb-4 position-relative"
                controlId="address"
              >
                <Form.Label>Workspace full address</Form.Label>
                <AddressSelection
                  workspace={workspace}
                  addressSelection={addressSelection}
                  handleBlur={handleBlur}
                  setFieldValue={setFieldValue}
                  errors={errors}
                  touched={touched}
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group
                className="mb-4 position-relative"
                controlId="city_name"
              >
                <Form.Label>City</Form.Label>
                <Form.Control
                  type="text"
                  name="city_name"
                  placeholder="Enter city name"
                  className={`${errors.city_name ? "error" : ""}`}
                  value={values?.city_name}
                  onChange={(e) => handleInputChange(e, "city_name")}
                  isValid={
                    touched.city_name && !errors.city_name && !!values.city_name
                  }
                  isInvalid={!!errors.city_name}
                  onBlur={handleBlur}
                />
                {errors.city_name && touched.city_name && (
                  <Form.Control.Feedback type="invalid" className="d-block">
                    {errors.city_name}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group
                className="mb-4 position-relative"
                controlId="postal_code"
              >
                <Form.Label>Postal Code</Form.Label>
                <Form.Control
                  type="text"
                  name="postal_code"
                  placeholder="Enter postal code"
                  className={`${errors.postal_code ? "error" : ""}`}
                  value={values?.postal_code}
                  onChange={(e) => handleInputChange(e, "postal_code")}
                  isValid={
                    touched.postal_code &&
                    !errors.postal_code &&
                    !!values.postal_code
                  }
                  isInvalid={!!errors.postal_code}
                  onBlur={handleBlur}
                />
                {errors.postal_code && touched.postal_code && (
                  <Form.Control.Feedback type="invalid" className="d-block">
                    {errors.postal_code}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </Col>

            <Col md={12} lg={12}>
              <Form.Group
                className="mb-4 position-relative"
                controlId="location_url"
              >
                <Form.Label>Workspace location link</Form.Label>
                <Form.Control
                  type="text"
                  name="location_url"
                  placeholder="Enter your workspace location link"
                  className={`${errors.location_url ? "error" : ""}`}
                  value={values?.location_url}
                  onChange={(e) => handleInputChange(e, "location_url")}
                  isValid={
                    touched.location_url &&
                    !errors.location_url &&
                    !!values.location_url
                  }
                  isInvalid={!!errors.location_url}
                  onBlur={handleBlur}
                />
                <Form.Control.Feedback type="invalid" className="d-block">
                  {errors.location_url && touched.location_url && (
                    <p>{errors.location_url}</p>
                  )}
                  {formError.location_url}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group
                className="mb-4 position-relative"
                controlId="space_type_id"
              >
                <FormLabel>Space Type</FormLabel>
                <Form.Control
                  as="select"
                  type="select"
                  name="space_type_id"
                  className={`${errors.space_type_id ? "error" : ""}`}
                  value={values.space_type_id}
                  onChange={(e) => handleInputChange(e, "space_type_id")}
                  isValid={
                    touched.space_type_id &&
                    !errors.space_type_id &&
                    !!values.space_type_id
                  }
                  isInvalid={!!errors.space_type_id}
                  onBlur={handleBlur}
                >
                  <option value="" label="Select Space type" />
                  {spaceTypeList
                    ? spaceTypeList.map((spaceTypeDetail: ISpaceTypeDetail) => {
                        const { id, name } = spaceTypeDetail ?? {};

                        return <option key={id} value={id} label={name} />;
                      })
                    : null}
                </Form.Control>
                <Form.Control.Feedback type="invalid" className="d-block">
                  {errors.space_type_id}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group
                className="mb-4 position-relative"
                controlId="team_range_id"
              >
                <Form.Label>Team Range</Form.Label>
                <Form.Control
                  as="select"
                  type="select"
                  name="team_range_id"
                  className={`${errors.team_range_id ? "error" : ""}`}
                  value={values.team_range_id}
                  onChange={(e) => handleInputChange(e, "team_range_id")}
                  isValid={
                    touched.team_range_id &&
                    !errors.team_range_id &&
                    !!values.team_range_id
                  }
                  isInvalid={!!errors.team_range_id}
                  onBlur={handleBlur}
                >
                  <option value="" label="Select Team range" />
                  {teamRangeList
                    ? teamRangeList.map((teamRangeDetail: ITeamRangeDetail) => {
                        const { id, range } = teamRangeDetail ?? {};

                        return <option key={id} value={id} label={range} />;
                      })
                    : null}
                </Form.Control>
                <Form.Control.Feedback type="invalid" className="d-block">
                  {errors.team_range_id}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Form.Group className="mb-4 position-relative" controlId="price">
                <Form.Label>Price ($)</Form.Label>
                <Form.Control
                  type="number"
                  name="price"
                  placeholder="Enter price"
                  className={`${errors.price ? "error" : ""}`}
                  value={values?.price}
                  onBlur={handleBlur}
                  onChange={(e) => handleInputChange(e, "price")}
                  isValid={touched.price && !errors.price && !!values.price}
                  isInvalid={!!errors.price}
                />
                {errors.price && touched.price && (
                  <Form.Control.Feedback type="invalid" className="d-block">
                    {errors.price}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group
                className="mb-4 position-relative"
                controlId="day_pass"
              >
                <Form.Label>Day Pass</Form.Label>
                <Form.Control
                  type="number"
                  name="day_pass"
                  placeholder="Enter day pass"
                  className={`${errors.day_pass ? "error" : ""}`}
                  value={values?.day_pass}
                  onBlur={handleBlur}
                  onChange={(e) => handleInputChange(e, "day_pass")}
                  isValid={
                    touched.day_pass && !errors.day_pass && !!values.day_pass
                  }
                  isInvalid={!!errors.day_pass}
                />
                {errors.day_pass && touched.day_pass && (
                  <Form.Control.Feedback type="invalid" className="d-block">
                    {errors.day_pass}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Form.Group
                className="mb-4 position-relative"
                controlId="weekly_pass"
              >
                <Form.Label>Weekly Pass</Form.Label>
                <Form.Control
                  type="number"
                  name="weekly_pass"
                  placeholder="Enter weekly pass"
                  className={`${errors.weekly_pass ? "error" : ""}`}
                  value={values?.weekly_pass}
                  onBlur={handleBlur}
                  onChange={(e) => handleInputChange(e, "weekly_pass")}
                  isValid={
                    touched.weekly_pass &&
                    !errors.weekly_pass &&
                    !!values.weekly_pass
                  }
                  isInvalid={!!errors.weekly_pass}
                />
                {errors.weekly_pass && touched.weekly_pass && (
                  <Form.Control.Feedback type="invalid" className="d-block">
                    {errors.weekly_pass}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group
                className="mb-4 position-relative"
                controlId="per_hour"
              >
                <Form.Label>Per Hour</Form.Label>
                <Form.Control
                  type="number"
                  name="per_hour"
                  placeholder="Enter per hour"
                  className={`${errors.per_hour ? "error" : ""}`}
                  value={values?.per_hour}
                  onBlur={handleBlur}
                  onChange={(e) => handleInputChange(e, "per_hour")}
                  isValid={
                    touched.per_hour && !errors.per_hour && !!values.per_hour
                  }
                  isInvalid={!!errors.per_hour}
                />
                {errors.per_hour && touched.per_hour && (
                  <Form.Control.Feedback type="invalid" className="d-block">
                    {errors.per_hour}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={12} lg={12}>
              <Form.Group className="mb-4 position-relative">
                <section className="workspace-img-drop-container">
                  <div {...getRootProps({ className: "dropzone" })}>
                    <img
                      src={require("../../../../../images/file-upload.png")}
                      alt="icon"
                      className="mb-2"
                    />
                    <input {...getInputProps()} />
                    {files.length ? (
                      <p className="dnd-text">Release to drop the files here</p>
                    ) : (
                      <p className="mb-0">
                        Drag and drop or browse files Exal file <br />
                        Images (.jpg, .gif, .png, up to 10 MB, no more than 4000
                        px in any dimension)
                      </p>
                    )}
                  </div>

                  {files && files.length ? (
                    <>
                      <h4 className="heading">Accepted files</h4>
                      <aside className="thumbsContainer">{thumbs}</aside>
                    </>
                  ) : null}

                  {rejectedFiles && rejectedFiles.length ? (
                    <>
                      <h4 className="heading mt-1">Rejected files</h4>
                      <aside>{fileRejectionItems}</aside>
                    </>
                  ) : null}
                  {(files.length > 0 || rejectedFiles.length > 0) && (
                    <Button
                      variant="primary"
                      className="w-100 remove-all-files-btn"
                      onClick={removeAll}
                    >
                      Remove all
                    </Button>
                  )}
                </section>
                {errorMessage && <div className="error">{errorMessage}</div>}
              </Form.Group>
            </Col>
            <Col>
              <ActionButtons
                action_id={workSpaceId}
                handleSubmit={handleSubmit}
                isLoading={isLoading}
              />
            </Col>
          </Row>
        </Form>
      </div>
    );
  }
);

export default UploadDetails;
