import { useCallback } from "react";
import { toast, ToastOptions, TypeOptions } from "react-toastify";
import { Button, Col, Container, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import "../../../styles/header.scss";
import axiosInstance from "../../../helpers/axios/axios.config";
import { useUserLoginStore } from "../../../zustand-store/auth/userLogin.store";
import { UnAuthenticatedRoutesEnums } from "../../../constants/routes.constants";
import { getUserData, removeLocalStorageItem } from "../../../helpers/localStorage.helper";

function WhatWillBeUsed() {
  const navigate = useNavigate();
  const { reset_token } = useParams();
  // const { userData } = useUserLoginStore();
  const userData  = getUserData();

  const navigateToSetPassword = useCallback(
    async (type: string) => {
      try {
        if (userData && reset_token) {
          await axiosInstance.patch(`/users/${userData?.id}/user_type`, {
            user_type: type,
          }).then((response) => {
            if (response) {
              if ((response as any)?.status === 200) {
                removeLocalStorageItem('userData');
                navigate(
                  UnAuthenticatedRoutesEnums.CREATE_PASSWORD.replace(
                    ":reset_token",
                    reset_token
                  )
                );
              }
            }
          });
          // navigate(
          //   UnAuthenticatedRoutesEnums.CREATE_PASSWORD.replace(':reset_token', reset_token)
          //   // `${UnAuthenticatedRoutesEnums.CREATE_PASSWORD}/${reset_token}`
          // );
        }
      } catch (error) {
        toast("Failed to update user details", {
          type: "error" as TypeOptions,
        } as ToastOptions);
      }
    },
    [navigate, reset_token, userData?.id]
  );

  return (
    <section className="pb-0 pt-0">
      <Container>
        <Row>
          <Col md={12} lg={6} className="d-flex align-items-center">
            <div className="form-common-box">
              <div className="text-center">
                <img
                  src={require("../../../images/login-user-profile.png")}
                  alt="hero banner"
                  className="mb-2"
                />
              </div>

              <h3 className="form-common-title text-center">
                What will be used for ?
              </h3>
              <p className="common-para  text-center">
                Do you want to further update your profile details or skip...
              </p>
              <Row>
                <Col md={12} lg={12}>
                  <div className="d-flex gap-3 justify-content-center">
                    <Button
                      variant="outline-primary"
                      className="w-100"
                      onClick={() => navigateToSetPassword("individual")}
                    >
                      My self
                    </Button>
                    <Button
                      variant="primary"
                      className="w-100"
                      onClick={() => navigateToSetPassword("business")}
                    >
                      Business
                    </Button>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
          <Col md={12} lg={6}>
            <img
              src={require("../../../images/user-login-info.png")}
              alt="hero banner"
              className="forworkspace-img"
            />
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default WhatWillBeUsed;
