import FallbackImage from "../images/fallback.jpg";

export const handleImageError = (event) => {
  event.target.src = FallbackImage;
  event.target.classList.add("fallback-img");
  return true;
};

export const Debounce = function (func, wait, immediate) {
  let timeout;
  return function () {
    const context = this;
    const args = arguments;
    const later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    const callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
};

export const getCountry = (countryName) => {
  const configStore = localStorage.getItem("config_store");

  if (configStore) {
    const storedValues = JSON.parse(configStore);
    const country = storedValues.state.countryList.find(
      (country) => country.name === countryName
    );

    return country;
  }
};
