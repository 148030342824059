import { useCallback, useEffect, useRef, useState } from "react";
import { Form } from "react-bootstrap";
import { toast, ToastOptions, TypeOptions } from "react-toastify";

// components
import Places from "./places";

// interfaces
import { Address } from "../../../../../interface/workspace/workspace.interface";

// helpers
import { Debounce, getCountry } from "../../../../../helpers";
import axios, { AxiosResponse } from "axios";

// styles
import "../../../../../styles/addressselection.scss";

interface AutoAddress {
  workspace: any;
  addressSelection: ({ address, lat, lng, city, postalCode }: Address) => void;
  handleBlur: (input: string) => void;
  setFieldValue: (input: string, value: string) => void;
  errors: any;
  touched: any;
}

const AddressSelection: React.FunctionComponent<AutoAddress> = ({
  workspace,
  addressSelection,
  handleBlur,
  setFieldValue,
  errors,
  touched,
}) => {
  const {
    address: workspaceAddress,
    latitude: workspaceLocationLat,
    longitude: workspaceLocationLng,
    city_name,
    postal_code,
    country_id,
  } = workspace ?? {};

  const [places, setPlaces] = useState<
    { place_id: string; description: string }[]
  >([]);
  const [address, setAddress] = useState<string>();
  const [showSuggestions, setShowSuggestions] = useState<boolean>(false);
  const wrapperRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    addressSelection({
      address: workspaceAddress,
      lat: workspaceLocationLat,
      lng: workspaceLocationLng,
      city: city_name,
      postalCode: postal_code,
      country_id: country_id,
    });

    setAddress(workspaceAddress);
  }, [
    addressSelection,
    city_name,
    country_id,
    postal_code,
    workspaceAddress,
    workspaceLocationLat,
    workspaceLocationLng,
  ]);

  const handleToastMessage = (msg: string, type: string) => {
    toast(msg, {
      type: type as TypeOptions,
    } as ToastOptions);
  };

  const displaySuggestions = (predictions: any, status: any) => {
    if (status !== google.maps.places.PlacesServiceStatus.OK || !predictions) {
      return;
    }

    if (predictions.length > 0) {
      setPlaces(predictions);
    } else {
      setPlaces([]);
    }
  };

  const fetchAutoAddress = useCallback(async (addressValue: string) => {
    try {
      const service = new google.maps.places.AutocompleteService();

      service.getQueryPredictions({ input: addressValue }, displaySuggestions);
    } catch (error) {
      handleToastMessage("Failed to fetch places", "error");
    }
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceSearch = useCallback(
    Debounce((addressVal: string) => {
      fetchAutoAddress(addressVal);
    }, 500),
    [fetchAutoAddress]
  );

  const onAddressSearch = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value;
      if (!value) {
        setPlaces([]);
        setAddress("");

        return;
      }

      setAddress(value);
      debounceSearch(value);
    },
    [debounceSearch]
  );

  const setAddressDetails = useCallback(
    (response: any) => {
      const { addressComponents, formattedAddress, location } = response.data;

      let selectedState: Address = {
        lat: 0,
        lng: 0,
        city: "",
        postalCode: "",
        country_id: "",
        address: "",
      };
      let hasPostalCode = false;

      selectedState = {
        ...selectedState,
        address: formattedAddress,
        lat: location.latitude,
        lng: location.longitude,
      };

      setFieldValue("address", formattedAddress);

      addressComponents.forEach((component: any) => {
        component.types.forEach((type: string) => {
          switch (type) {
            case "country":
              const selectedCountry = getCountry(component.longText);

              selectedState = {
                ...selectedState,
                country_id: selectedCountry.id,
              };
              break;

            case "postal_code":
              selectedState = {
                ...selectedState,
                postalCode: component.longText,
              };
              hasPostalCode = true;
              break;

            case "plus_code":
              if (!hasPostalCode) {
                selectedState = {
                  ...selectedState,
                  postalCode: component.longText,
                };
              }
              break;

            case "administrative_area_level_1":
              selectedState = {
                ...selectedState,
                city: component.longText,
              };
              break;

            default:
              break;
          }
        });
      });

      addressSelection({
        address: formattedAddress,
        lat: location.latitude,
        lng: location.longitude,
        city: selectedState.city || "",
        postalCode: selectedState.postalCode || "",
        country_id: selectedState.country_id || "",
      });

      setShowSuggestions(false);
    },
    [addressSelection, setFieldValue]
  );

  const choosePlace = useCallback(
    async (placeId: string) => {
      try {
        if (!placeId) {
          return;
        }

        const placeSelectedByUser =
          places && places.find((p: any) => p.place_id === placeId);

        if (placeSelectedByUser?.description) {
          setAddress(placeSelectedByUser?.description);
        }

        const response: AxiosResponse = await axios.get(
          `https://places.googleapis.com/v1/places/${placeId}`,
          {
            headers: {
              "Content-Type": "application/json",
              "X-Goog-Api-Key": "AIzaSyD5jCHZwSx0kUvWt-EQOH-zbPLENdaRKIk",
              Referer: "http://localhost:3000",
              "X-Goog-FieldMask": "*",
            },
          }
        );
        if (response.status === 200) {
          setAddressDetails(response);
        }
      } catch (error) {
        handleToastMessage("Failed to fetch place details", "error");
      }
    },
    [places, setAddressDetails]
  );

  const handleOutsideClick = (event: Event) => {
    if (
      wrapperRef.current &&
      !wrapperRef.current.contains(event.target as Node)
    ) {
      setShowSuggestions(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mouseup", handleOutsideClick);

    return () => {
      document.removeEventListener("mouseup", handleOutsideClick);
    };
  }, []);

  const handleInputFocus = useCallback(() => {
    setShowSuggestions(true);
  }, []);

  return (
    <div className="address-container" ref={wrapperRef}>
      <Form.Control
        type="text"
        name="address"
        autoComplete="off"
        placeholder="Enter a location"
        value={address}
        className={`${errors.address ? "error" : ""}`}
        onBlur={() => handleBlur("address")}
        isValid={touched.address && !errors.address && !!address}
        isInvalid={!!errors.address}
        onChange={onAddressSearch}
        onFocus={handleInputFocus}
      />
      {showSuggestions && places && places.length > 0 ? (
        <Places choosePlace={choosePlace} places={places} />
      ) : null}
      {errors.address && touched.address && (
        <Form.Control.Feedback type="invalid" className="d-block">
          {errors.address}
        </Form.Control.Feedback>
      )}
    </div>
  );
};

export default AddressSelection;
