import Capitalize from './Capitalize';
function ErrorMsg(responseData: any) {
    let errors_data: { [key: string]: string } = {};
    Object.entries(responseData).map(([key, value]) => {
        var error = value as Array<String>;
        var new_error = "";
        error.forEach((element) => {
            new_error += `${Capitalize(key)} ${element},`
        });
        errors_data[key] = new_error;
    })
    return errors_data
}
export default ErrorMsg;
