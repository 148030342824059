import React, { useCallback, useState } from 'react'
import { Button, Col, Container, Form, Row } from 'react-bootstrap'
import { FileUploader } from "react-drag-drop-files";
import { useDropzone } from 'react-dropzone'


function AttendeesFileUpload() {

    const [value, setValue] = useState()

    const onDrop = useCallback(acceptedFiles => {
        // Do something with the files
    }, [])
    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

    return (
        <section className='flex-1'>
            <Container>
                <Row className="justify-content-md-center">
                    <Col lg={6}>
                        <div className='form-common-box'>
                            <h3 className="form-common-title text-dark">
                                Attendees user Information
                            </h3>
                            {/* <p className="common-para mb-3">
                                Lorem ipsum dolor sit amet consectetur.
                            </p> */}
                            <Row>
                                <Col lg={12}>
                                    <div {...getRootProps()} className='drag-drop-file-upload'>
                                        <img
                                            src={require("../../images/file-upload.png")}
                                            alt="icon"
                                            className='mb-2'
                                        />
                                        <input {...getInputProps()} />
                                        {
                                            isDragActive ?
                                                <p>Drop the files here ...</p> :
                                                <p className='mb-0'>Drag and drop or browse files Exal file <br />Images (.jpg, .gif, .png, up to 10 MB, no more than 4000 px in any dimension)</p>
                                        }
                                    </div>
                                </Col>
                                <Col lg={12}>
                                    <Form.Group className="mt-4">
                                        <Button
                                            variant="primary"
                                        >
                                            Share
                                        </Button>
                                    </Form.Group>
                                </Col>
                            </Row>
                        </div>

                    </Col>
                </Row>
            </Container>
        </section>

    )
}

export default AttendeesFileUpload

