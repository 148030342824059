import { AxiosError } from "axios";
import { Formik, FormikHelpers, FormikProps } from "formik";
import { useEffect } from "react";
import { Button, Col, Container, Form, Row, Spinner } from "react-bootstrap";
import { toast, ToastOptions, TypeOptions } from "react-toastify";
import { Roles } from "../../../../constants/roles.constant";
import { UserType } from "../../../../constants/user_type.constant";
import axiosInstance from "../../../../helpers/axios/axios.config";
import {
  ICountryDetail,
  ITeamRangeDetail,
} from "../../../../interface/config/config.interface";
import { ICreateAccountRequest } from "../../../../interface/createAccount/createAccount.interface";
import "../../../../styles/header.scss";
import { CreateCustomerAccountFormValidation } from "../../../../validation/createAccount/createWorkspaceAccount.validation";
import { useConfigStore } from "../../../../zustand-store/config/config.store";
import { BsArrowLeft } from "react-icons/bs";
import { useNavigate } from "react-router-dom";

interface ICreateAccountFormParams {
  name: string;
  email: string;
  position: string;
  companyName: string;
  country: number;
  numOfPerson: number;
}

interface IForCustomerProps {}

const ForCustomer: React.FunctionComponent<IForCustomerProps> = (props) => {
  const navigate = useNavigate()
  const {
    fetchTeamRangeList,
    fetchingTeamRangeList,
    teamRangeList,
    countryList,
    fetchCountryList,
    fetchingCountryList,
  } = useConfigStore();

  useEffect(() => {
    if (teamRangeList && !teamRangeList.length) fetchTeamRangeList();
  }, [teamRangeList, fetchTeamRangeList]);

  useEffect(() => {
    if (countryList && !countryList.length) fetchCountryList();
  }, [countryList, fetchCountryList]);

  const handleSubmitForm = async (
    values: ICreateAccountFormParams,
    formikHelpers: FormikHelpers<ICreateAccountFormParams>
  ) => {
    try {
      await axiosInstance.post("/communities_inquiries", {
        communities_inquiry: {
          name: values.name,
          email: values.email,
          position: values.position,
          company_name: values.companyName,
          user_type: 'Customer',
          team_range_id: values.numOfPerson,
        },
      } as ICreateAccountRequest);
      navigate('/thankyou',{ state: { fromCreateWorkspace: true } });
      formikHelpers.resetForm();
    } catch (error) {
      if (
        error instanceof AxiosError &&
        ((error as AxiosError)?.response?.data as any)?.email
      ) {
        formikHelpers.setFieldError(
          "email",
          "Email " + ((error as AxiosError)?.response?.data as any)?.email
        );
      } else {
        toast("Something went wrong, Please try again later.", {
          type: "error" as TypeOptions,
        } as ToastOptions);
      }
    }
  };

  const initialValues: ICreateAccountFormParams = {
    name: "",
    email: "",
    position: "",
    companyName: "",
    country: 0,
    numOfPerson: 0,
  };
  const handleGoBack = () => {
    navigate("/workspace")
  };

  return (
    <section>
      <Container>
        <Row>
          <Col md={12} lg={6} className="d-flex  flex-column align-items-start justify-content-around">
          <Button variant="white" className="custom-button text-dark" onClick={handleGoBack} >
                <BsArrowLeft className="me-2"/>
                Back
              </Button>
            <div className="form-common-box">
              <h3 className="form-common-title">For Customers</h3>
              <p className="common-para mb-3">
              Help us serve you better by sharing some information about your interests and preferences.
              </p>
              <Formik
                initialValues={initialValues}
                onSubmit={handleSubmitForm}
                validationSchema={CreateCustomerAccountFormValidation}
                validateOnChange={false}
                validateOnBlur={false}
              >
                {({
                  values,
                  errors,
                  isSubmitting,
                  handleSubmit,
                  handleChange,
                  handleBlur,
                }: FormikProps<ICreateAccountFormParams>) => {
                  return (
                    <Form>
                      <Row>
                        <Col md={12} lg={6}>
                          <Form.Group
                            className="mb-4 position-relative"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label>Name</Form.Label>
                            <Form.Control
                              type="text"
                              name="name"
                              placeholder="John Doe"
                              value={values.name}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              className={`form-icon-control ${
                                errors.name ? "error" : ""
                              }`}
                            />
                            <span className="icon-profile  field-icon"></span>
                            <Form.Control.Feedback
                              type="invalid"
                              className="d-block"
                            >
                              {errors.name}
                            </Form.Control.Feedback>
                          </Form.Group>
                          <Form.Group
                            className="mb-4 position-relative"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label>Company name</Form.Label>
                            <Form.Control
                              type="text"
                              name="companyName"
                              placeholder="Google Inc."
                              value={values.companyName}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              className={`form-icon-control ${
                                errors.companyName ? "error" : ""
                              }`}
                            />
                            <span className="icon-briefcase  field-icon"></span>
                            <Form.Control.Feedback
                              type="invalid"
                              className="d-block"
                            >
                              {errors.companyName}
                            </Form.Control.Feedback>
                          </Form.Group>
                          <Form.Group
                            className="mb-4"
                            controlId="exampleForm.ControlTextarea1"
                          >
                            <Form.Label>Country</Form.Label>
                            <Form.Select
                              aria-label="Select you country"
                              className="form-control"
                              name="country"
                              value={values.country}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            >
                              {fetchingCountryList ? (
                                <option>Loading country list</option>
                              ) : (
                                [
                                  <option key="" value="">Select country</option>,
                                  ...countryList?.map(
                                    (country: ICountryDetail) => (
                                      <option
                                        key={`country-${country.id}`}
                                        value={country.id}
                                        label={`${country.emoji} ${country.name}`}
                                      />
                                    )
                                  ),
                                ]
                              )}
                            </Form.Select>
                          </Form.Group>
                        </Col>
                        <Col md={12} lg={6}>
                          <Form.Group
                            className="mb-4 position-relative"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label>Email</Form.Label>
                            <Form.Control
                              type="email"
                              name="email"
                              placeholder="John@business.pass"
                              value={values.email}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              className={`form-icon-control ${
                                errors.email ? "error" : ""
                              }`}
                            />
                            <span className="icon-main  field-icon"></span>
                            <Form.Control.Feedback
                              type="invalid"
                              className="d-block"
                            >
                              {errors.email}
                            </Form.Control.Feedback>
                          </Form.Group>
                          <Form.Group
                            className="mb-4 position-relative"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label>Position</Form.Label>
                            <Form.Control
                              type="text"
                              name="position"
                              placeholder="Public relations"
                              value={values.position}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              className={`form-icon-control ${
                                errors.position ? "error" : ""
                              }`}
                            />
                            <span className="icon-user-octagon field-icon"></span>
                            <Form.Control.Feedback
                              type="invalid"
                              className="d-block"
                            >
                              {errors.position}
                            </Form.Control.Feedback>
                          </Form.Group>
                          <Form.Group
                            className="mb-4"
                            controlId="exampleForm.ControlTextarea1"
                          >
                            <Form.Label>How many persons</Form.Label>
                            <Form.Select
                              aria-label="Default select example"
                              className="form-control"
                              value={values.numOfPerson}
                              name="numOfPerson"
                              onChange={handleChange}
                              onBlur={handleBlur}
                            >
                              <option>Select how many person</option>
                              {fetchingTeamRangeList ? (
                                <option>Loading team ranges</option>
                              ) : (
                                teamRangeList?.map(
                                  (teamRangeDetail: ITeamRangeDetail) => (
                                    <option
                                      key={`team-range-${teamRangeDetail.id}`}
                                      value={teamRangeDetail.id}
                                      label={teamRangeDetail.range}
                                    >
                                      {teamRangeDetail.range}
                                    </option>
                                  )
                                )
                              )}
                            </Form.Select>
                          </Form.Group>
                        </Col>
                        <Col lg={12}>
                          <Form.Group className="mt-2">
                            <Button
                              variant="primary"
                              className="w-100"
                              onClick={() => handleSubmit()}
                              disabled={isSubmitting}
                            >
                              {isSubmitting ? (
                                <Spinner
                                  as="span"
                                  animation="border"
                                  size="sm"
                                  role="status"
                                  aria-hidden="true"
                                />
                              ) : (
                                "Confirm"
                              )}
                            </Button>
                          </Form.Group>
                        </Col>
                      </Row>
                    </Form>
                  );
                }}
              </Formik>
            </div>
          </Col>
          <Col md={12} lg={6}>
            <img
              src={require("../../../../images/forcustomers.png")}
              alt="hero banner"
              className="forworkspace-img"
            />
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default ForCustomer;
