import { Col, Container, Nav, Row } from "react-bootstrap";
import "../../styles/header.scss";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <footer className="footer">
      <Container>
        <Row>
          <Col md={12}>
            <div className="d-flex justify-content-between footer-block">
              <div className="copy-right">
                <p className="mb-0">
                  © 2024 Businesspass. All rights reserved.
                </p>
              </div>
              <div className="footer-links">
                <Nav.Link as={Link} to="/">
                  Privacy Policy
                </Nav.Link>
                <Nav.Link as={Link} to="/">
                  Terms of Service
                </Nav.Link>
                <Nav.Link as={Link} to="/">
                  Cookies Settings
                </Nav.Link>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </footer>
  );
}

export default Footer;
