import * as React from "react";
import { Table } from "react-bootstrap";
import { toast, ToastOptions, TypeOptions } from "react-toastify";
import { useLocation } from "react-router-dom";
import { useActivateAccountStore } from "../../../../../zustand-store/activate-accounts/accountActivate.store";
import { AuthenticatedRoutesEnums } from "../../../../../constants/routes.constants";

interface IActivateAccountsListProps {}

const ActivateAccountsList: React.FunctionComponent<
  IActivateAccountsListProps
> = (props) => {
  const {
    activateAccountList,
    isFetchingActivateAccount,
    noActivateAccount,
    fetchActivateAccountError,
    fetchActivateAccountList,
  } = useActivateAccountStore();
  const location = useLocation()

  // React.useEffect(() => {
  //   fetchActivateAccountList();
  // }, [fetchActivateAccountList]);
  // React.useEffect(() => {
  //   if (fetchActivateAccountError)
  //     toast("Failed to fetch list of activate account list.", {
  //       type: "error" as TypeOptions,
  //     } as ToastOptions);
  // }, [fetchActivateAccountError]);

  React.useEffect(() => {
    if (
      location.pathname === AuthenticatedRoutesEnums.DASHBOARD_ACTIVATE_ACCOUNT
    ) {
      fetchActivateAccountList();
    }
  }, [location.pathname]);
  

  return (
    <>
      <Table hover responsive className="dashboard-table">
        <thead>
          <tr>
            <th>Name</th>
            <th>Email</th>
          </tr>
        </thead>
        <tbody>
          {isFetchingActivateAccount && (
            <tr>
              <td colSpan={2}>Loading...</td>
            </tr>
          )}
          {noActivateAccount && (
            <tr>
              <td colSpan={2}>No Activate account lists</td>
            </tr>
          )}
          {activateAccountList?.map((user) => (
            <tr key={`${user.id}-${user.email}`}>
              <td>{user.name}</td>
              <td>{user.email}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
};

export default ActivateAccountsList;
