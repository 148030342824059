import { AxiosError } from "axios";
import { Formik, FormikHelpers, FormikProps } from "formik";
import { useEffect } from "react";
import { Button, Form, Modal, Spinner } from "react-bootstrap";
import PhoneInput from "react-phone-input-2";
import { isValidPhoneNumber, parsePhoneNumber } from "react-phone-number-input";
import { toast, ToastOptions, TypeOptions } from "react-toastify";
import axiosInstance from "../../../../../../helpers/axios/axios.config";
import { ICountryDetail } from "../../../../../../interface/config/config.interface";
import { ICreateCustomerRequest } from "../../../../../../interface/customers/customer.interceptor";
import { CreateCustomerFromBusinessProfileFormValidation } from "../../../../../../validation/createAccount/createWorkspaceAccount.validation";
import { useAuthStore } from "../../../../../../zustand-store/auth/auth.store";
import { useConfigStore } from "../../../../../../zustand-store/config/config.store";
import { useCustomerStore } from "../../../../../../zustand-store/customers/customers.store";

interface IAddCustomerModalProps {
  showAddCustomerModal: boolean;
  handleAddCustomerModalClose: () => void;
}

interface IAddCustomerFormParams {
  name: string;
  email: string;
  position: string;
  phone: string;
}

const AddCustomerModal: React.FunctionComponent<IAddCustomerModalProps> = (
  props
) => {
  const { countryList, fetchCountryList } = useConfigStore();
  useEffect(() => {
    if (!countryList.length) fetchCountryList();
  }, [fetchCountryList, countryList]);

  const { fetchCustomers } = useCustomerStore();
  const { loggedInUserDetails } = useAuthStore();
  const handleSubmitForm = async (
    values: IAddCustomerFormParams,
    formikHelpers: FormikHelpers<IAddCustomerFormParams>
  ) => {
    try {
      if (!isValidPhoneNumber("+" + values.phone)) {
        formikHelpers.setFieldError("phone", "Please enter valid phone number");
        return;
      }
      const parsedPhoneNumber = parsePhoneNumber("+" + values.phone);
      const selectedCountryDetails: ICountryDetail | undefined =
        countryList.find(
          (country: ICountryDetail) =>
            country.phone_code === parsedPhoneNumber?.countryCallingCode
        );
      const payload: ICreateCustomerRequest = {
        customer: {
          name: values.name,
          email: values.email,
          country_id: selectedCountryDetails?.id,
          phone: parsedPhoneNumber?.nationalNumber,
          position: values.position,
        } as any,
      };
      await axiosInstance.post(
        `/workspaces/${loggedInUserDetails?.workspaces[0]?.id}/customers`,
        payload
      );
      toast("Customer added successfully.", {
        type: "success" as TypeOptions,
      } as ToastOptions);
      formikHelpers.resetForm();
      props.handleAddCustomerModalClose();
      fetchCustomers(loggedInUserDetails?.workspaces[0].id as any);
    } catch (error) {
      if (
        error instanceof AxiosError &&
        ((error as AxiosError)?.response?.data as any)?.error &&
        (
          ((error as AxiosError)?.response?.data as any)?.error as string[]
        )?.[0].includes("Email")
      ) {
        formikHelpers.setFieldError(
          "email",
          (
            ((error as AxiosError)?.response?.data as any)?.error as string[]
          )?.[0]
        );
      } else {
        toast("Something went wrong, Please try again later.", {
          type: "error" as TypeOptions,
        } as ToastOptions);
      }
    }
  };

  const initialValues: IAddCustomerFormParams = {
    name: "",
    email: "",
    position: "",
    phone: "41",
  };

  return (
    <Modal
      className="common-modal"
      centered
      show={props.showAddCustomerModal}
      onHide={props.handleAddCustomerModalClose}
    >
      <Modal.Header closeButton>
        <Modal.Title>Invite customer</Modal.Title>
      </Modal.Header>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmitForm}
        validationSchema={CreateCustomerFromBusinessProfileFormValidation}
        validateOnChange={false}
        validateOnBlur={false}
      >
        {({
          values,
          errors,
          isSubmitting,
          handleSubmit,
          handleChange,
          handleBlur,
          setFieldValue,
        }: FormikProps<IAddCustomerFormParams>) => {
          return (
            <>
              <Modal.Body>
                <Form>
                  <Form.Group
                    className="mb-4 position-relative"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      type="email"
                      name="email"
                      placeholder="John@business.pass"
                      value={values.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className={`form-icon-control ${
                        errors.email ? "error" : ""
                      }`}
                    />
                    <span className="icon-main  field-icon"></span>
                    <Form.Control.Feedback type="invalid" className="d-block">
                      {errors.email &&
                      ![
                        "Please enter valid email",
                        "Please enter email",
                      ].includes(errors.email)
                        ? errors.email
                        : ""}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group
                    className="mb-4 position-relative"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                      type="text"
                      name="name"
                      placeholder="John Doe"
                      value={values.name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className={`form-icon-control ${
                        errors.name ? "error" : ""
                      }`}
                    />
                    <span className="icon-profile field-icon"></span>
                  </Form.Group>
                  <Form.Group
                    className="mb-4"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>Phone</Form.Label>
                    <PhoneInput
                      enableSearch
                      value={values.phone}
                      inputProps={{
                        placeholder: "Enter phone number",
                        className: `${
                          errors.phone ? "form-control error" : "form-control "
                        }`,
                      }}
                      onChange={(phone) => setFieldValue("phone", phone || "")}
                    />
                    {errors.phone && (
                      <Form.Control.Feedback type="invalid" className="d-block">
                        {errors.phone}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>
                  <Form.Group
                    className="mb-4"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>Position</Form.Label>
                    <Form.Control
                      type="text"
                      name="position"
                      placeholder="Tech lead"
                      value={values.position}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className={`${errors.position ? "error" : ""}`}
                    />
                  </Form.Group>
                </Form>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant="primary"
                  className="w-100"
                  onClick={() => handleSubmit()}
                  disabled={isSubmitting}
                >
                  {isSubmitting ? (
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  ) : (
                    "Create customer"
                  )}
                </Button>
              </Modal.Footer>
            </>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default AddCustomerModal;
