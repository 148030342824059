import React from "react";
import { Formik, FormikProps } from "formik";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { useUserLoginStore } from "../../../zustand-store/auth/userLogin.store";
import { toast, ToastOptions, TypeOptions } from "react-toastify";
import { UnAuthenticatedRoutesEnums } from "../../../constants/routes.constants";
import axiosInstance from "../../../helpers/axios/axios.config";
import { getUserData } from "../../../helpers/localStorage.helper";

interface IUserInformationProps {}

const UserInformation: React.FunctionComponent<IUserInformationProps> = () => {
  const navigate = useNavigate();
  const { token } = useParams();
  const userData  = getUserData();

  const onSubmitUserInfo = React.useCallback(async () => {
    try {
      const response = await axiosInstance.get(`/confirm_user/${token}`);
      if (response) {
        if ((response as any)?.status === 200) {
          navigate(
            UnAuthenticatedRoutesEnums.WILL_BE_USED_FOR.replace(
              ":reset_token",
              (response as any)?.data?.token
            )
          );
        }
      }
    } catch (error) {
      toast("Failed to confirm user", {
        type: "error" as TypeOptions,
      } as ToastOptions);
    }
  }, [navigate, token]);

  return (
    <section className="pb-0 pt-0">
      <Container>
        <Row>
          <Col md={12} lg={6} className="d-flex align-items-center">
            <div className="form-common-box">
              <h3 className="form-common-title">User Information</h3>
              <p className="common-para mb-3">
                Please verify your account details for connect with us.
              </p>
              <Formik
                initialValues={{
                  name: userData.name,
                  email: userData.email,
                  phone_no: userData.phone_no,
                }}
                onSubmit={onSubmitUserInfo}
              >
                {({
                  values,
                  errors,
                  touched,
                  isSubmitting,
                  handleSubmit,
                  handleChange,
                  handleBlur,
                }: FormikProps<any>) => (
                  <Form>
                    <Row>
                      <Col md={12} lg={12}>
                        <Form.Group
                          className="mb-4 position-relative"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Label>Name</Form.Label>
                          <Form.Control
                            type="text"
                            name="name"
                            id="name"
                            placeholder="Enter your name"
                            className={`form-icon-control`}
                            disabled
                            value={values.name}
                          />
                          <span className="icon-main  field-icon"></span>
                          <Form.Control.Feedback
                            type="invalid"
                            className="d-block"
                          ></Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group
                          className="mb-4 position-relative"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Label>Email</Form.Label>
                          <Form.Control
                            type="text"
                            name="email"
                            id="email"
                            placeholder="Enter your email"
                            className={`form-icon-control`}
                            disabled
                            value={values.email}
                          />
                          <span className="icon-briefcase  field-icon"></span>
                          <Form.Control.Feedback
                            type="invalid"
                            className="d-block"
                          ></Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group
                          className="mb-4 position-relative"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Label>Phone Number</Form.Label>
                          <Form.Control
                            type="text"
                            name="phone_no"
                            id="phone_no"
                            placeholder="Enter your Phone number"
                            disabled
                            value={values.phone_no}
                          />
                          <Form.Control.Feedback
                            type="invalid"
                            className="d-block"
                          ></Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group className="mt-2">
                          <Button
                            variant="primary"
                            className="w-100"
                            onClick={() => {
                              handleSubmit();
                            }}
                          >
                            Next
                          </Button>
                        </Form.Group>
                      </Col>
                    </Row>
                  </Form>
                )}
              </Formik>
            </div>
          </Col>
          <Col md={12} lg={6}>
            <img
              src={require("../../../images/user-login-info.png")}
              alt="hero banner"
              className="forworkspace-img"
            />
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default UserInformation;
