import { useEffect, useState } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { toast, ToastOptions, TypeOptions } from "react-toastify";
import { IEmployeeDetails } from "../../../../../../interface/employees/employees.interceptor";
import { useAuthStore } from "../../../../../../zustand-store/auth/auth.store";
import { useEmployeeStore } from "../../../../../../zustand-store/employees/employees.store";

interface IEmployeeDeleteModalProps {
  showEmployeeDeleteModal: boolean;
  handleEmployeeDeleteModalClose: () => void;
  userId: number | undefined;
}

const EmployeeDeleteModal: React.FunctionComponent<
  IEmployeeDeleteModalProps
> = (props) => {
  const {
    employees,
    deleteEmployee,
    deleteEmployeeError,
    isDeleteingEmployees,
  } = useEmployeeStore();
  const { loggedInUserDetails } = useAuthStore();
  const [userDetails, setUserDetails] = useState<IEmployeeDetails | undefined>(
    undefined
  );

  useEffect(() => {
    const UserDetailsById = employees?.find(
      (user: IEmployeeDetails) => user.id === props.userId
    );
    setUserDetails(UserDetailsById);
  }, [employees, props.userId]);

  return (
    <Modal
      className="common-modal"
      centered
      show={props.showEmployeeDeleteModal}
      onHide={props.handleEmployeeDeleteModalClose}
    >
      <Modal.Header closeButton>
        <Modal.Title>Delete User</Modal.Title>
        <p className="mb-0">Are you sure you want to delete this user?</p>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col sm={5}>
            <span className="user-label">Name</span>
          </Col>
          <Col sm={7}>
            <span className="user-text">{userDetails?.name}</span>
          </Col>
        </Row>
        <Row>
          <Col sm={5}>
            <span className="user-label">Email</span>
          </Col>

          <Col sm={7}>
            <span className="user-text">{userDetails?.email}</span>
          </Col>
        </Row>
        <Row>
          <Col sm={5}>
            <span className="user-label">Position</span>
          </Col>

          <Col sm={7}>
            <span className="user-text">{userDetails?.position}</span>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="outline-primary"
          className="me-2"
          onClick={props.handleEmployeeDeleteModalClose}
        >
          Cancel
        </Button>
        <Button
          variant="danger"
          disabled={isDeleteingEmployees}
          className=""
          onClick={() => {
            if (userDetails?.id)
              deleteEmployee(
                loggedInUserDetails?.workspaces[0].id as any,
                userDetails?.id,
                () => {
                  props.handleEmployeeDeleteModalClose();
                  toast("Employee deleted successfully.", {
                    type: "success" as TypeOptions,
                  } as ToastOptions);
                }
              );
          }}
        >
          Delete User
        </Button>
        {deleteEmployeeError && <div>{deleteEmployeeError}</div>}
      </Modal.Footer>
    </Modal>
  );
};

export default EmployeeDeleteModal;
