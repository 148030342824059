import { Formik, FormikHelpers, FormikProps } from "formik";
import { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row, Spinner } from "react-bootstrap";
import { toast, ToastOptions, TypeOptions } from "react-toastify";
import "../../../styles/header.scss";
import { ForgotFormValidation } from "../../../validation/forgotPassword/forgotPassword.validation";
import { LoginFormValidation } from "../../../validation/loginForm/loginForm.validation";
import { useAuthStore } from "../../../zustand-store/auth/auth.store";
import { ActivateAccountModal } from "./activate-account";
import { ExploreMemberShipModal } from "./expore-membership";

interface ILoginForm {
  email: string;
  password: string;
  // for: "business" | "workspace";
}
interface IForgotForm {
  email: string;
}
export interface IHomeProps {}

const Home: React.FunctionComponent<IHomeProps> = (props) => {
  const { doLogin, isLoggingIn, loginError, forgotPassword } = useAuthStore();
  const [showExploreModal, setShowExploreModal] = useState(false);
  const handleExploreModalClose = () => setShowExploreModal(false);
  const handleExploreModalShow = () => setShowExploreModal(true);
  const [showAccountModal, setShowAccountModal] = useState(false);
  const handleAccountModalClose = () => setShowAccountModal(false);
  const handleAccountModalShow = () => setShowAccountModal(true);
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const [showWelcome, setShowWelcome] = useState(true);

  // forgotten password
  const handleForgotPasswordClick = () => {
    setShowForgotPassword(true);
    setShowWelcome(false);
  };
  // go back welcome page
  const handleGoBack = () => {
    setShowForgotPassword(false);
    setShowWelcome(true);
  };

  const handleSubmitForm = (
    values: ILoginForm,
    formikHelpers: FormikHelpers<ILoginForm>
  ) => {
    doLogin({
      user: {
        email: values.email,
        password: values.password,
      },
    });
  };

  const handleForgotForm = (
    values: IForgotForm,
    formikHelpers: FormikHelpers<IForgotForm>
  ) => {
    forgotPassword({
      user: {
        email: values.email,
      },
    });
  };

  useEffect(() => {
    if (loginError)
      toast(loginError, { type: "error" as TypeOptions } as ToastOptions);
  }, [loginError]);

  const initialValues: ILoginForm = {
    email: "",
    password: "",
    // for: "business",
  };

  const initialForgotValues: IForgotForm = {
    email: "",
  };

  return (
    <>
      {/* // Hero section start */}
      <Container>
        <Row>
          {showWelcome && (
            <Col md={12} lg={6}>
              <div className="left-tabs">
                <h1 className="section-title">Welcome Back</h1>
                <p className="common-para">Please enter your details</p>

                <div>
                  <Formik
                    initialValues={initialValues}
                    onSubmit={handleSubmitForm}
                    validationSchema={LoginFormValidation}
                    //  validateOnChange={false}
                    //  validateOnBlur={false}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleSubmit,
                      handleChange,
                      handleBlur,
                    }: FormikProps<ILoginForm>) => {
                      return (
                        <Form onSubmit={handleSubmit}>
                          {/* <ul className="custom-tabs list-unstyled">
                            <li
                              className={
                                values.for === "business" ? "active" : ""
                              }
                            >
                              <input
                                type="radio"
                                id="ForBusiness"
                                name="for"
                                className="radio-input"
                                value="business"
                                checked={values.for === "business"}
                                onChange={handleChange}
                              />
                              <label htmlFor="ForBusiness">For Business</label>{" "}
                            </li>
                            <li
                              className={
                                values.for === "workspace" ? "active" : ""
                              }
                            >
                              <input
                                type="radio"
                                id="ForWorkspace"
                                name="for"
                                className="radio-input"
                                value="workspace"
                                checked={values.for === "workspace"}
                                onChange={handleChange}
                              />
                              <label htmlFor="ForWorkspace">For Workspace</label>{" "}
                            </li>
                          </ul> */}
                          <Form.Group
                            className="mb-4 position-relative"
                            controlId="email"
                          >
                            <Form.Label>Email</Form.Label>
                            <Form.Control
                              type="email"
                              name="email"
                              placeholder="Email@worldpeace.com"
                              value={values.email}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              className={`form-icon-control ${
                                errors.email ? "error" : ""
                              }`}
                            />
                            <span className="icon-main field-icon"></span>

                            <Form.Control.Feedback
                              type="invalid"
                              className="d-block"
                            >
                              {errors.email && touched.email && (
                                <p>{errors.email}</p>
                              )}
                            </Form.Control.Feedback>
                          </Form.Group>
                          <Form.Group
                            className="mb-4 position-relative"
                            controlId="password"
                          >
                            <Form.Label>Password</Form.Label>
                            <Form.Control
                              type="password"
                              name="password"
                              placeholder="******"
                              value={values.password}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              className={`form-icon-control ${
                                errors.password ? "error" : ""
                              }`}
                            />
                            <span className="icon-lock field-icon"></span>
                            <Form.Control.Feedback
                              type="invalid"
                              className="d-block"
                            >
                              {errors.password && touched.password && (
                                <p>{errors.password}</p>
                              )}
                            </Form.Control.Feedback>
                          </Form.Group>
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Button
                              variant="primary"
                              className="w-100 mb-2"
                              type="submit"
                              onClick={() => handleSubmit()}
                              disabled={isLoggingIn}
                            >
                              {!isLoggingIn ? (
                                "Gate-in"
                              ) : (
                                <Spinner
                                  as="span"
                                  animation="border"
                                  size="sm"
                                  role="status"
                                  aria-hidden="true"
                                />
                              )}
                            </Button>
                            <div className="text-center mt-4">
                              <a
                                className="text-primary cursor-pointer"
                                onClick={handleForgotPasswordClick}
                              >
                                Forgot my access 🌎
                              </a>
                            </div>
                            {/* {loginError && (
                            <Form.Group>
                              <Form.Control.Feedback
                                type="invalid"
                                className="d-block"
                              >
                                {loginError}
                              </Form.Control.Feedback>
                            </Form.Group>
                          )} */}
                          </Form.Group>
                        </Form>
                      );
                    }}
                  </Formik>
                </div>
              </div>
            </Col>
          )}
          {/* Forgot Password Section */}
          {showForgotPassword && (
            <Col md={12} lg={6}>
              <div className="left-tabs">
                <h1 className="section-title">Reset Password</h1>
                <p className="common-para">Please provide your email address</p>
                <Formik
                  initialValues={initialForgotValues}
                  onSubmit={handleForgotForm}
                  validationSchema={ForgotFormValidation}
                  // validateOnChange={false}
                  // validateOnBlur={false}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleSubmit,
                    handleChange,
                    handleBlur,
                  }: FormikProps<IForgotForm>) => {
                    return (
                      <Form onSubmit={handleSubmit}>
                        <Form.Group
                          className="mb-4 position-relative"
                          controlId="email"
                        >
                          <Form.Label>Email</Form.Label>
                          <Form.Control
                            type="email"
                            placeholder="Enter your email"
                            name="email"
                            value={values.email}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className={`form-icon-control  ${
                              errors.email ? "error" : ""
                            }`}
                          />
                          <span className="icon-main field-icon"></span>
                          <Form.Control.Feedback
                            type="invalid"
                            className="d-block"
                          >
                            {errors.email && touched.email && (
                              <p>{errors.email}</p>
                            )}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Button
                            variant="primary"
                            className="mt-4 w-100 btn btn-primary"
                            type="submit"
                            onClick={() => handleSubmit()}
                          >
                            Forget Password
                          </Button>
                        </Form.Group>
                      </Form>
                    );
                  }}
                </Formik>
                <a
                  className="w-100 mt-4  text-center"
                  type="submit"
                  onClick={handleGoBack}
                  style={{ textDecoration: "none" }}
                >
                  Go Back to Gate In
                </a>
              </div>
            </Col>
          )}
          <Col md={12} lg={6}>
            <img
              src={require("../../../images/hero-banner.png")}
              alt="hero banner"
            />
          </Col>
        </Row>
      </Container>

      <section>
        <Container>
          <Row>
            <Col md={12} lg={6}>
              <img
                src={require("../../../images/work-from.png")}
                alt="hero banner"
                className="mobile-img-padding-bottom"
              />
            </Col>
            <Col md={12} lg={6}>
              <p className="common-para">Access that unlock doors</p>
              <h1 className="section-title">
                It all begin with a{" "}
                <span className="text-primary"> Businesspass </span>
              </h1>
              <p className="common-para mb-5">
                At the heart of our mission lies a single pass — a key to unlock
                business doors around the world. From the bustling streets of
                NYC to the vibrant landscapes of Saudi Arabia, our vision is to
                provide you and your business a pass around the globe. With our
                business pass, we empower you to seize opportunities, forge
                connections, and thrive in a global landscape, all with the
                simple scan of a key pass.
              </p>
              <Button
                variant="primary"
                className="me-3 mb-3"
                onClick={handleExploreModalShow}
              >
                Explore Membership
              </Button>
              <Button
                variant="outline-primary"
                className="mb-3"
                onClick={handleAccountModalShow}
              >
                Activate account
              </Button>
            </Col>
          </Row>
        </Container>
      </section>

      {/* Explore Membership */}
      <ExploreMemberShipModal
        showExploreModal={showExploreModal}
        handleExploreModalClose={handleExploreModalClose}
      />

      {/* Activate account */}
      <ActivateAccountModal
        showAccountModal={showAccountModal}
        handleAccountModalClose={handleAccountModalClose}
      />
      {/*Activate account */}
    </>
  );
};

export default Home;
