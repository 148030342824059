import { Button, Col, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { UnAuthenticatedRoutesEnums } from "../../../constants/routes.constants";
import "../../../styles/header.scss";

function ForWorkspace() {
  const navigate = useNavigate();
  return (
    <section className="pb-0 pt-0">
      <Container>
        <Row>
          <Col md={12} lg={6} className="d-flex align-items-center">
            <div className="form-common-box">
              <h3 className="form-common-title text-center">For Businesss</h3>
              <p className="common-para  text-center">
                If you want to work together with us then join us.
              </p>
              <Row>
                <Col md={12} lg={12}>
                  <div className="d-grid gap-3">
                    <Button
                      variant="outline-primary"
                      onClick={() => {
                        navigate(UnAuthenticatedRoutesEnums.CUSTOMER);
                      }}
                    >
                      For your Customers
                    </Button>
                    <Button
                      variant="primary"
                      onClick={() => {
                        navigate(UnAuthenticatedRoutesEnums.EMPLOYEE);
                      }}
                    >
                      For your Employees
                    </Button>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
          <Col md={12} lg={6}>
            <img
              src={require("../../../images/forworkspace1.png")}
              alt="hero banner"
              className="forworkspace-img"
            />
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default ForWorkspace;
