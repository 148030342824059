import { AxiosError, AxiosResponse } from "axios";
import { create } from "zustand";
import { devtools } from "zustand/middleware";
import axiosInstance from "../../helpers/axios/axios.config";
import {
  IWorkspaceList,
  IWorkspaceDetails,
  ITeamRangeList,
  ITeamRangeDetail,
  ISpaceTypeList,
  ISpaceTypeDetail,
} from "../../interface/workspace/workspace.interface";

export const useWorkspaceStore = create<IWorkspaceStore>()(
  devtools(
    (set, get) => ({
      workspaces: [],
      isFetchingWorkspaces: false,
      noWorkspacesFound: true,
      fetchWorkspaceListError: undefined,
      fetchWorkspaceList: (
        selectedCountryid?: number,
        search?: string,
        space_type?: string,
        team_range?: number,
        latitude?: string,
        longitude?: string
      ) => {
        
        const params = Object.assign(
          {},
          selectedCountryid ? { co: selectedCountryid } : {},
          search ? { se: search } : {},
          space_type ? { st: space_type } : {},
          team_range ? { tr: team_range } : {},
          latitude ? { latitude: latitude } : {},
          longitude ? { longitude: longitude } : {},
         );
        set({
          isFetchingWorkspaces: true,
          noWorkspacesFound: false,
          fetchWorkspaceListError: undefined,
        });
        axiosInstance
          .get<AxiosResponse<IWorkspaceList>>("/search", {
            params: params,
          })
          .then((response) => {
            set({
              workspaceList: response.data.workspaces,
              isFetchingWorkspaces: false,
              noWorkspacesFound: response.data.workspaces.length === 0,
              fetchWorkspaceListError: undefined,
            });
          })
          .catch((error) => {
            let message: string = "Something went wrong";
            if (error instanceof AxiosError) {
              message = ((error as AxiosError)?.response?.data as any)
                ?.message as string;
            }
            set({
              isFetchingWorkspaces: false,
              noWorkspacesFound: false,
              fetchWorkspaceListError: message,
            });
          });
      },

      isLoading: true,
      hasError: false,
      workspaceList: [],

      fetchWorkspaces: async (search?: String) => {
        const params = Object.assign(
          {},
          search ? { search: search } : {},
        );
        set({
          isLoading: true,
          noWorkspacesFound: false,
          hasError: false,
        });
        try {
          const response = await axiosInstance.get<AxiosResponse>(
            "/workspaces",{
            params: params
            });
          const { data: workspaceList, status } = response ?? {};

          if (status === 200) {
            set({
              workspaceList: workspaceList,
              isLoading: false,
              hasError: false,
            });
          }
        } catch (error) {
          let message: string = "Failed to fetch the workspaces";

          if (error instanceof AxiosError) {
            message = ((error as AxiosError)?.response?.data as any)
              ?.message as string;
          }

          set({
            workspaceList: [],
            isLoading: false,
            hasError: true,
          });
        }
      },

      teamRangeList: [],
      fetchingTeamRangeList: false,
      fetchTeamRangeList: async () => {
        try {
          const { teamRangeList } = get();
          if (!teamRangeList.length) {
            set({
              fetchingTeamRangeList: true,
            });
            const teamRangeListResponse: AxiosResponse<ITeamRangeList> =
              await axiosInstance.get("/team_ranges");
            const teamRangeList: ITeamRangeDetail[] =
              teamRangeListResponse.data.team_ranges;
            set({
              teamRangeList: teamRangeList,
              fetchingTeamRangeList: false,
            });
          }
        } catch (error) {
          set({
            fetchingTeamRangeList: false,
          });
        }
      },
      spaceTypeList: [],
      fetchingSpaceTypeList: false,
      fetchSpaceTypeList: async () => {
        try {
          const { spaceTypeList } = get();
          if (!spaceTypeList.length) {
            set({
              fetchingSpaceTypeList: true,
            });
            const spaceTypeListResponse: AxiosResponse<ISpaceTypeList> =
              await axiosInstance.get("/space_types");
            const spaceTypeList: ISpaceTypeDetail[] =
              spaceTypeListResponse.data.space_types;
            set({
              spaceTypeList: spaceTypeList,
              fetchingSpaceTypeList: false,
            });
          }
        } catch (error) {
          set({
            fetchingSpaceTypeList: false,
          });
        }
      },
    }),
    {
      name: "workspace_store",
      anonymousActionType: "workspace_actions",
    }
  )
);

export interface IWorkspaceStore {
  workspaces: IWorkspaceDetails[];
  isFetchingWorkspaces: boolean;
  noWorkspacesFound: boolean;
  fetchWorkspaceListError?: string;
  fetchWorkspaceList: (
    selectedCountryid?: number,
    search?: string,
    space_type?: string,
    team_range?: number,
    latitude?: string,
    longitude?: string
  ) => void;

  // fetch workspace list
  isLoading: boolean;
  hasError: boolean;
  workspaceList: IWorkspaceDetails[];
  fetchWorkspaces: (search?: String) => void;

  teamRangeList: ITeamRangeDetail[];
  fetchingTeamRangeList: boolean;
  fetchTeamRangeList: () => void;
  spaceTypeList: ISpaceTypeDetail[];
  fetchingSpaceTypeList: boolean;
  fetchSpaceTypeList: () => void;
}
