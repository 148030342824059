import React, { useState } from 'react'
import { Button, Col, Container, Form, Row } from 'react-bootstrap'
import PhoneInput from 'react-phone-number-input'

function AttendeesUserInfo() {
    const [value, setValue] = useState()
    return (
        <section className='flex-1'>
            <Container>
                <Row className="justify-content-md-center">
                    <Col lg={6}>
                        <div className='form-common-box'>
                            <h3 className="form-common-title text-dark">
                                Attendees user Information
                            </h3>
                            {/* <p className="common-para mb-3">
                                Lorem ipsum dolor sit amet consectetur.
                            </p> */}
                            <h5 className='fw-bold text-dark mb-3'>
                                Person 1
                            </h5>
                            <Row>
                                <Col lg={6}>
                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                        <Form.Label>Person name </Form.Label>
                                        <Form.Select aria-label="Default select example" className='form-control'>
                                            <option>Enter person name</option>
                                            <option value="1">One</option>
                                            <option value="2">Two</option>
                                            <option value="3">Three</option>
                                        </Form.Select>
                                    </Form.Group>
                                </Col>
                                <Col lg={6}>
                                    <Form.Group className="mb-3 position-relative" controlId="exampleForm.ControlInput1">
                                        <Form.Label>Email</Form.Label>
                                        <Form.Control type="text"
                                            placeholder="Enter person email"
                                            className={`form-icon-control`}
                                        />
                                        <span className="icon-main  field-icon"></span>
                                    </Form.Group>
                                </Col>
                                <Col lg={6}>
                                    <Form.Group className="mb-3 position-relative" controlId="exampleForm.ControlInput1">
                                        <Form.Label>Phone</Form.Label>
                                        <PhoneInput
                                            placeholder="Enter phone number"
                                            value={value}
                                            country="US"
                                            onChange={setValue} />
                                    </Form.Group>
                                </Col>
                                <Col lg={12}>
                                    <Form.Group className="mt-2">
                                        <Button
                                            variant="primary"
                                        >
                                            Next
                                        </Button>
                                    </Form.Group>
                                </Col>
                            </Row>
                        </div>

                    </Col>
                </Row>
            </Container>
        </section>

    )
}

export default AttendeesUserInfo