import { Container } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import Tab from "react-bootstrap/Tab";
import "../../../../styles/dashboard.scss";
// import ChangeLocation from "./change-location";
import { useState } from "react";
import { useQuery } from "../../../../hooks/useQuery";
import DeleteAccount from "../common/delete-account";
import PersonalInformation from "./tabs/personal-info";
import UpdatePassword from "../common/update-password";

interface IProfilePageProps {}

const ProfilePage: React.FunctionComponent<IProfilePageProps> = (props) => {
  let query = useQuery();
  const tab = query.get("tab");
  const [currentTab, setCurrentTab] = useState(tab || "profile");
  return (
    <>
      <section className="flex-1">
        <Container className="vertical-tabs">
          <div className="mb-4">
            <h2 className="section-sub-title">Personal Information</h2>
          </div>
          <Tab.Container
            id="left-tabs-example"
            defaultActiveKey={"profile"}
            activeKey={currentTab}
            onSelect={(key) => setCurrentTab(key || 'profile')}
          >
            <Row className="h-100">
              <Col lg={4} xl={3}>
                <Nav variant="pills" className="flex-column myprofile-tab">
                  <Nav.Item>
                    <Nav.Link eventKey="profile">
                      <span className="icon-user-profile tab-icon"></span>
                      Personal Information
                    </Nav.Link>
                  </Nav.Item>
                  {/* <Nav.Item>
                    <Nav.Link eventKey="second">
                      <span className="icon-location tab-icon"></span>
                      Change Location
                    </Nav.Link>
                  </Nav.Item> */}
                  <Nav.Item>
                    <Nav.Link eventKey="password">
                      <span className="icon-lock tab-icon"></span>
                      Change Password
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="delete">
                      <span className="icon-profile-delete tab-icon"></span>
                      Delete Account
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              </Col>
              <Col lg={8} xl={9}>
                <Tab.Content className="bg-white p-0">
                  <Tab.Pane eventKey="profile">
                    <PersonalInformation />
                  </Tab.Pane>
                  {/* <Tab.Pane eventKey="second">
                    <ChangeLocation />
                  </Tab.Pane> */}
                  <Tab.Pane eventKey="password">
                    <UpdatePassword />
                  </Tab.Pane>
                  <Tab.Pane eventKey="delete">
                    <DeleteAccount />
                  </Tab.Pane>
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
        </Container>
      </section>
    </>
  );
};

export default ProfilePage;
