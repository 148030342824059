import React, { useMemo } from "react";
import { GoogleMap, MarkerF, useLoadScript } from "@react-google-maps/api";
import { ICountryDetail } from "../../interface/config/config.interface";

interface MapContainerProps {
  searchedCountry?: ICountryDetail | null;
}

const MapComponent: React.FC<MapContainerProps> = ({ searchedCountry }) => {
  const { latitude, longitude } = searchedCountry ?? {};
  const mapKEY: string =
    // process.env.REACT_APP_GOOGLE_MAP_API_KEY ||
    "AIzaSyD5jCHZwSx0kUvWt-EQOH-zbPLENdaRKIk";
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: mapKEY,
  });

  const markerPoints = useMemo(
    () => ({ lat: Number(latitude) ?? 0, lng: Number(longitude) ?? 0 }),
    [latitude, longitude]
  );

  return (
    <div style={{ height: "500px", width: "100%" }}>
      {!isLoaded ? (
        <h1>Loading...</h1>
      ) : (
        <GoogleMap
          mapContainerClassName="map-container"
          center={markerPoints}
          zoom={4}
        >
          <MarkerF position={markerPoints} />
        </GoogleMap>
      )}
    </div>
  );
};

export default MapComponent;
