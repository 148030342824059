import { Container } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import Tab from "react-bootstrap/Tab";
import "../../../../styles/dashboard.scss";
import DeleteAccount from "../common/delete-account";
import UpdatePassword from "../common/update-password";
import Customers from "./tabs/customers";
import Employee from "./tabs/employees";
import SpaceInformation from "./tabs/space-information";
import UploadDetails from "./tabs/upload-details";
import { AuthenticatedRoutesEnums } from "../../../../constants/routes.constants";
import { Link } from "react-router-dom";
import AddEditUser from "../../super-admin/dashboard/user-list/user-information/add-or-edit-user";

interface IBusinessProfileProps {}

const BusinessProfile: React.FunctionComponent<IBusinessProfileProps> = (
  props
) => {
  return (
    <>
      <section>
        <Container className="vertical-tabs">
          <div className="mb-4">
            <h2 className="section-sub-title">Space Information</h2>
          </div>
          <Tab.Container
            id="left-tabs-example"
            defaultActiveKey="space-information"
          >
            <Row className="h-100">
              <Col lg={4} xl={3}>
                <Nav variant="pills" className="flex-column myprofile-tab">
                  <Nav.Item>
                    <Nav.Link
                      eventKey="space-information"
                      as={Link}
                      to={AuthenticatedRoutesEnums.BUSINESS_PROFILE_WORKSPACES}
                    >
                      <span className="icon-briefcase-outline tab-icon"></span>
                      Workspaces
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      eventKey="employee"
                      as={Link}
                      to={AuthenticatedRoutesEnums.BUSINESS_PROFILE_EMPLOYEES}
                    >
                      <span className="icon-users tab-icon"></span>
                      Employees
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      eventKey="customer"
                      as={Link}
                      to={AuthenticatedRoutesEnums.BUSINESS_PROFILE_CUSTOMERS}
                    >
                      <span className="icon-people tab-icon"></span>
                      Customers
                    </Nav.Link>
                  </Nav.Item>
                  {/* <Nav.Item>
                    <Nav.Link eventKey="upload-details">
                      <span className="icon-briefcase-outline tab-icon"></span>
                      Upload details
                    </Nav.Link>
                  </Nav.Item> */}
                  {/* <Nav.Item>
                    <Nav.Link eventKey="fourth">
                      <span className="icon-location tab-icon"></span>
                      Change Location
                    </Nav.Link>
                  </Nav.Item> */}
                  <Nav.Item>
                    <Nav.Link
                      eventKey="change-password"
                      as={Link}
                      to={
                        AuthenticatedRoutesEnums.BUSINESS_PROFILE_UPDATE_PASSWORD
                      }
                    >
                      <span className="icon-lock tab-icon"></span>
                      Change Password
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      eventKey="delete"
                      as={Link}
                      to={
                        AuthenticatedRoutesEnums.BUSINESS_PROFILE_DELETE_ACCOUNT
                      }
                    >
                      <span className="icon-profile-delete tab-icon"></span>
                      Delete Account
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              </Col>
              <Col lg={8} xl={9}>
                <Tab.Content className="bg-white p-0">
                  <Tab.Pane eventKey="space-information">
                    <SpaceInformation />
                  </Tab.Pane>
                  <Tab.Pane eventKey="employee">
                    <Employee />
                  </Tab.Pane>
                  <Tab.Pane eventKey="customer">
                    <Customers />
                  </Tab.Pane>
                  {/* <Tab.Pane eventKey="fourth">
                    <ChangeLocation />
                  </Tab.Pane> */}
                  <Tab.Pane eventKey="change-password">
                    <UpdatePassword />
                  </Tab.Pane>
                  <Tab.Pane eventKey="delete">
                    <DeleteAccount />
                  </Tab.Pane>
                  <Tab.Pane eventKey="upload-details">
                    <UploadDetails />
                  </Tab.Pane>
                  <Tab.Pane eventKey="add-or-edit-user">
                    <AddEditUser />
                  </Tab.Pane>
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
        </Container>
      </section>
    </>
  );
};

export default BusinessProfile;
