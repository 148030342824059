import { useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";

import * as React from "react";
import { useNavigate } from "react-router-dom";
import { UnAuthenticatedRoutesEnums } from "../../../../constants/routes.constants";
import { useAuthStore } from "../../../../zustand-store/auth/auth.store";

interface IDeleteAccountProps {}

const DeleteAccount: React.FunctionComponent<IDeleteAccountProps> = (props) => {
  const { deleteAccount, isDeletingAccount, profileUpdateError } =
    useAuthStore();
  const navigate = useNavigate();
  const [showDeleteAccountModal, setShowDeleteAccountModal] =
    useState<boolean>(false);
  const handleDeleteUserModalClose = () => setShowDeleteAccountModal(false);
  const handleDeleteUserModalShow = () => setShowDeleteAccountModal(true);
  return (
    <div className="form-common-box rounded-0">
      <h2 className="myprofile-title text-dark">
        <span className="icon-profile-delete pe-2"></span>
        Delete Account
      </h2>
      <p className="common-para mb-3">
        Submit a request to delete your account. To confirm you are the true
        owner of this account, we may contact you at .
      </p>
      <p className="common-para fw-medium mb-3">
        About account deletion requests:
      </p>

      <ul>
        <li>
          <p className="common-para mb-3">
            By deleting your account, you will no longer have access to WeWork.
            If you want to use WeWork in the future, you'll need to set up a new
            account.
          </p>
        </li>
        <li>
          <p className="common-para mb-0">
            If you would prefer to close your account temporarily, deactivating
            your WeWork account is a better option. You can find this under
            Membership in Account.
          </p>
        </li>
      </ul>
      <Row>
        <Col md={12}>
          <Form.Group className="mt-2 float-end">
            <Button
              variant="danger"
              className="text-white"
              onClick={() => handleDeleteUserModalShow()}
            >
              Delete account
            </Button>
          </Form.Group>
        </Col>
      </Row>
      <Modal
        className="common-modal"
        centered
        show={showDeleteAccountModal}
        onHide={handleDeleteUserModalClose}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Are you sure you want to delete your account?
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="mb-0">
            Deleting your account wil remove all of your information from
            database. This cannot be undone.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="danger"
            disabled={isDeletingAccount}
            className="text-white"
            onClick={() => {
              deleteAccount(navigate(UnAuthenticatedRoutesEnums.HOME));
            }}
          >
            Delete User
          </Button>
          {profileUpdateError && <div>{profileUpdateError}</div>}
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default DeleteAccount;
