import { AxiosError, AxiosResponse } from "axios";
import { toast, ToastOptions, TypeOptions } from "react-toastify";
import { create } from "zustand";
import { devtools } from "zustand/middleware";
import axiosInstance from "../../helpers/axios/axios.config";
import { IEmployeeDetails } from "../../interface/employees/employees.interceptor";

export const useEmployeeStore = create<IEmployeeStore>()(
  devtools(
    (set, get) => ({
      employees: [],
      isFetchingEmployees: false,
      noEmployeesFound: true,
      fetchEmployeeError: undefined,
      isDeleteingEmployees: false,
      deleteEmployeeError: undefined,
      fetchEmployee: async (id: number) => {
        try {
          set({
            isFetchingEmployees: true,
            noEmployeesFound: false,
            fetchEmployeeError: undefined,
          });
          const employeesListResponse: AxiosResponse<{
            users: IEmployeeDetails[];
          }> = await axiosInstance.get(`/workspaces/${id}/employees`);
          set({
            employees: employeesListResponse.data.users,
            isFetchingEmployees: false,
            noEmployeesFound: employeesListResponse.data.users.length === 0,
            fetchEmployeeError: undefined,
          });
        } catch (error) {
          let message: string = "Something went wrong";
          if (error instanceof AxiosError) {
            message = ((error as AxiosError)?.response?.data as any)
              ?.message as string;
          }
          set({
            isFetchingEmployees: false,
            noEmployeesFound: false,
            fetchEmployeeError: message,
          });
        }
      },
      deleteEmployee: async (
        workspacesId: number,
        userId: number,
        handleDeleteUserModalClose?: any
      ) => {
        try {
          set({
            isDeleteingEmployees: true,
            deleteEmployeeError: undefined,
          });
          await axiosInstance.delete(
            `/workspaces/${workspacesId}/employees/${userId}`
          );
          const { employees } = get();
          const updatedEmployeeList = employees.filter(
            (user: IEmployeeDetails) => user.id !== userId
          );
          set({
            employees: updatedEmployeeList,
            noEmployeesFound: updatedEmployeeList.length === 0,
            isDeleteingEmployees: false,
            deleteEmployeeError: undefined,
          });
          handleDeleteUserModalClose && handleDeleteUserModalClose();
        } catch (error) {
          let message: string = "Something went wrong";
          if (error instanceof AxiosError) {
            message = ((error as AxiosError)?.response?.data as any)
              ?.message as string;
          }
          set({
            isDeleteingEmployees: false,
            deleteEmployeeError: message,
          });
          toast("Failed to delete employee, please try after some time", {
            type: "error" as TypeOptions,
          } as ToastOptions);
        }
      },
    }),
    {
      name: "employees_store",
      anonymousActionType: "employees_actions",
    }
  )
);

export interface IEmployeeStore {
  employees: IEmployeeDetails[];
  isFetchingEmployees: boolean;
  fetchEmployeeError?: string;
  isDeleteingEmployees: boolean;
  deleteEmployeeError?: string;
  noEmployeesFound: boolean;
  fetchEmployee: (id: number) => void;
  deleteEmployee: (
    workspacesId: number,
    userId: number,
    handleDeleteUserModalClose?: any
  ) => void;
}
